import React from 'react';
import {observable, action, makeObservable} from 'mobx';

class Device {
  settings = {
    'here': {},
    'geocoder': {}
  }
  serviceLocations = []
  origin = undefined
  destination = undefined
  tenant = undefined

  constructor() {
    makeObservable(this, {
      'settings': observable,
      'serviceLocations': observable,
      'origin': observable,
      'destination': observable,
      'tenant': observable,

      'setSettings': action.bound,
      'setServiceLocations': action.bound,
      'setOrigin': action.bound,
      'setDestination': action.bound,
      'setTenant': action.bound,
    });
  }

  setSettings(settings){
    this.settings = settings;
  }

  setServiceLocations(serviceLocations){
    this.serviceLocations = serviceLocations;
  }

  setOrigin(origin){
    this.origin = origin;
  }

  setDestination(destination){
    this.destination = destination;
  }

  setTenant(Api, Config){
    const self = this
      , {tenant, 'api_drt': apiDrt} = Config;

    Api.fetch(`${apiDrt}/settings` , {method: 'GET'}, response => {
      if(!response.err){
        self.tenant = {
          'name': tenant,
          ...response
        };

        self.settings = {
          ...self.settings,
          'here': response.here,
          'geocoder': response.geocoder,
          'booking': response.booking,
          'user': response.user,
        }
      }
    });
  }
}

// Instantiate the counter store.
export const device = new Device();
// Create a React Context with the counter store instance.
export const DeviceContext = React.createContext(device);
export const useDevice = () => React.useContext(DeviceContext);
