// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'general': {
        'name': 'Name',
        'surname': 'Surname',
        'email': 'Email',
        'password': 'Password',
        'phone': 'Phone'
    },
    'signin': {
        'title': 'Sign in',
        'subtitle': 'Enter your credentials to continue',
        'signInDone': 'Login successful',
        'forgotPassword': {
            'title': 'Forgot Password',
            'message': 'Enter the email associated with the account to recover the password',
            'recovery': 'We have sent you an email to recover your password. Check your inbox.'
        },
        'notActive': 'You need to confirm your account to continue. Check your email.',
        'password': 'Username e/o Password not valid',
        'register': 'Don\'t have the credentials?'
    },
    'register': {
        'title': 'Register',
        'subtitle': 'Create your account',
        'confirmEmail': 'Confirm Email',
        'confirmPassword': 'Confirm Password',
        'confirmPhone': 'Confirm Phone'
    },
    'continue': 'Continue without account',
    'confirmEmail': 'We have sent you an email. Confirm it to continue.'
}
