import React from 'react';

export const Means = ({name, color = "#fff"}) => {
  const originalWidth = 35
    , originalHeight = 24;

  return (
    <svg viewBox={`0 0 ${originalWidth} ${originalHeight}`} preserveAspectRatio="xMinYMin meet">
      {
        name === 'walk' &&
          <>
            <path d="M 9.894531 14.066406 C 9.714844 14.367188 9.652344 14.730469 9.722656 15.082031 C 9.730469 15.109375 10.054688 18.242188 3.875 20.988281 C 3.144531 21.3125 2.816406 22.167969 3.140625 22.902344 C 3.378906 23.441406 3.910156 23.761719 4.464844 23.761719 C 4.660156 23.761719 4.859375 23.722656 5.050781 23.636719 C 10.089844 21.398438 11.808594 18.675781 12.363281 16.789062 C 14.121094 17.59375 16.765625 19.320312 17.222656 22.515625 C 17.335938 23.304688 18.070312 23.855469 18.863281 23.746094 C 19.652344 23.628906 20.203125 22.898438 20.089844 22.105469 C 19.445312 17.585938 15.929688 15.242188 13.597656 14.167969 C 13.597656 14.164062 13.59375 14.15625 13.589844 14.152344 C 13.285156 13.386719 13.117188 12.738281 13.035156 12.207031 C 14.980469 13.191406 17.519531 13.664062 20.179688 12.332031 C 20.894531 11.976562 21.183594 11.105469 20.828125 10.390625 C 20.46875 9.675781 19.597656 9.382812 18.882812 9.742188 C 15.339844 11.515625 12.363281 8.152344 12.242188 8.011719 C 11.910156 7.628906 11.40625 7.441406 10.902344 7.523438 C 8.746094 7.882812 4.683594 9.6875 4.679688 13.964844 C 4.679688 14.761719 5.328125 15.414062 6.128906 15.414062 C 6.929688 15.414062 7.578125 14.765625 7.578125 13.964844 C 7.578125 12.480469 8.546875 11.582031 9.503906 11.058594 C 9.46875 11.890625 9.5625 12.890625 9.894531 14.066406 Z M 15.085938 3.164062 C 15.085938 4.777344 13.777344 6.085938 12.164062 6.085938 C 10.550781 6.085938 9.238281 4.777344 9.238281 3.164062 C 9.242188 1.546875 10.550781 0.238281 12.164062 0.238281 C 13.777344 0.238281 15.085938 1.546875 15.085938 3.164062 Z M 15.085938 3.164062 " fill={color} />
          </>
      }

      {
        name === 'bus' &&
          <>
            <path d="M 32.348 0 L 2.726 0 C 1.221 0.002 0.002 1.235 0 2.757 L 0 17.457 C 0 18.98 1.22 20.214 2.726 20.214 L 2.764 20.214 C 3.08 17.877 5.055 16.134 7.387 16.134 C 9.72 16.134 11.694 17.877 12.01 20.214 L 23.097 20.214 C 23.408 17.873 25.383 16.126 27.718 16.126 C 30.053 16.126 32.028 17.873 32.339 20.214 C 33.063 20.215 33.756 19.925 34.268 19.407 C 34.779 18.89 35.066 18.188 35.065 17.457 L 35.065 2.757 C 35.065 1.238 33.85 0.005 32.348 0 Z M 4.271 20.847 C 4.271 19.106 5.667 17.694 7.389 17.694 C 9.11 17.694 10.506 19.106 10.506 20.847 C 10.506 22.588 9.11 24 7.389 24 C 5.667 24 4.271 22.588 4.271 20.847 Z M 24.601 20.847 C 24.601 19.106 25.997 17.694 27.718 17.694 C 29.44 17.694 30.836 19.106 30.836 20.847 C 30.836 22.588 29.44 24 27.718 24 C 25.997 24 24.601 22.588 24.601 20.847 Z M 10.826 9 C 10.826 9.828 10.162 10.5 9.343 10.5 L 4.206 10.5 C 3.387 10.5 2.723 9.828 2.723 9 L 2.723 3.798 C 2.723 2.97 3.387 2.298 4.206 2.298 L 9.337 2.298 C 10.156 2.298 10.82 2.97 10.82 3.798 Z M 21.593 9 C 21.593 9.828 20.929 10.5 20.11 10.5 L 14.973 10.5 C 14.154 10.5 13.49 9.828 13.49 9 L 13.49 3.798 C 13.49 2.97 14.154 2.298 14.973 2.298 L 20.104 2.298 C 20.923 2.298 21.587 2.97 21.587 3.798 Z M 32.39 9 C 32.39 9.828 31.726 10.5 30.907 10.5 L 25.769 10.5 C 24.95 10.5 24.286 9.828 24.286 9 L 24.286 3.798 C 24.286 2.97 24.95 2.298 25.769 2.298 L 30.901 2.298 C 31.72 2.298 32.384 2.97 32.384 3.798 Z" fill={color} />
          </>
      }

      {
        name === 'train' &&
          <>
            <path d="M 35.024 17.943 C 33.449 9.939 23.322 0 10.156 0 L 0 0 L 0 8.544 L 13.003 8.544 L 13.003 12.933 L 0 12.933 L 0 21.519 C 0.105 22.738 0.999 23.738 2.188 23.967 C 3.377 24.195 4.571 23.596 5.111 22.5 L 8.619 22.5 C 9.166 23.585 10.355 24.173 11.537 23.944 C 12.719 23.714 13.608 22.722 13.718 21.51 L 16.248 21.51 C 16.352 22.729 17.244 23.729 18.432 23.959 C 19.62 24.189 20.814 23.593 21.356 22.5 L 24.856 22.5 C 25.4 23.587 26.589 24.179 27.772 23.951 C 28.955 23.724 29.846 22.732 29.958 21.519 L 31.625 21.519 C 32.485 21.518 33.32 21.225 33.997 20.688 C 34.823 20.041 35.219 18.98 35.024 17.943 Z M 8.474 22.161 L 5.256 22.161 C 5.328 21.954 5.375 21.738 5.395 21.519 L 8.335 21.519 C 8.355 21.738 8.402 21.954 8.474 22.161 Z M 15.569 13.131 L 15.569 8.544 C 22.984 8.544 25.853 14.427 25.853 14.427 L 19.903 14.427 C 18.304 12.807 15.569 13.131 15.569 13.131 Z M 24.711 22.161 L 21.492 22.161 C 21.565 21.954 21.611 21.738 21.632 21.519 L 24.571 21.519 C 24.592 21.738 24.638 21.954 24.711 22.161 Z" fill={color} />
          </>
      }
    </svg>
  )
}
