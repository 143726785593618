import React from 'react';

export const Utils = ({
  name,
  color = "#fff",
  originalWidth = 30,
  originalHeight = 40
}) => {
  return (
    <svg viewBox={`0 0 ${originalWidth} ${originalHeight}`} preserveAspectRatio="xMinYMin meet">
      {
        name === 'travel' &&
          <>
            <path d="M 18.077 29.654 L 17.8 29.546" fill="transparent" stroke-width="0.7692307692307693" stroke="rgb(0,0,0)" stroke-linecap="round" stroke-miterlimit="7.6923076923076925" stroke-dasharray="" />
            <path d="M 16.4 29.038 L 10.946 27.038 C 2.823 24.062 24.492 22.015 15.9 18.862 L 9.523 16.338" fill="transparent" stroke-width="0.7692307692307693" stroke="rgb(0,0,0)" stroke-linecap="round" stroke-miterlimit="7.6923076923076925" stroke-dasharray="0.97,1.94" />
            <path d="M 8.823 16.054 L 8.469 15.915" fill="transparent" stroke-width="0.7692307692307693" stroke="rgb(0,0,0)" stroke-linecap="round" stroke-miterlimit="7.6923076923076925" stroke-dasharray="" />
            <path d="M 19.231 31.223 C 17.531 28.8 15.131 24.938 15.131 22.523 C 15.131 19.337 17.714 16.754 20.9 16.754 C 24.086 16.754 26.669 19.337 26.669 22.523 C 26.669 24.938 24.269 28.8 22.569 31.223 C 22.263 31.662 21.796 31.961 21.269 32.055 C 20.742 32.149 20.2 32.029 19.762 31.723 C 19.556 31.589 19.377 31.42 19.231 31.223 Z M 20.9 25.385 C 22.489 25.385 23.777 24.097 23.777 22.508 C 23.777 20.919 22.489 19.631 20.9 19.631 C 19.311 19.631 18.023 20.919 18.023 22.508 C 18.023 24.097 19.311 25.385 20.9 25.385 Z" fill="rgb(255,255,255)" stroke="rgb(0,0,0)" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="" />
            <path d="M 5.754 15.208 C 4.854 13.923 3.577 11.869 3.577 10.592 C 3.577 8.893 4.955 7.515 6.654 7.515 C 8.353 7.515 9.731 8.893 9.731 10.592 C 9.731 11.869 8.454 13.923 7.554 15.208 C 7.215 15.692 6.549 15.812 6.062 15.477 C 5.943 15.407 5.839 15.316 5.754 15.208 Z M 6.631 12.131 C 7.48 12.131 8.169 11.442 8.169 10.592 C 8.169 9.743 7.48 9.054 6.631 9.054 C 5.781 9.054 5.092 9.743 5.092 10.592 C 5.101 11.436 5.787 12.115 6.631 12.115 Z" fill="rgb(255,255,255)" stroke-width="0.75" stroke="rgb(0,0,0)" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="" />
          </>
      }
      {
        name === 'arrowUpDown' &&
          <>
            <path fill={color} d="M7.17506 4.38604C7.31242 4.2134 7.50559 4.08739 7.72717 4.03547 7.90499 3.9883 8.09354 3.98818 8.27142 4.0351 8.49399 4.08686 8.688 4.21336 8.82573 4.38679L11.7014 7.14355C12.0995 7.52519 12.0995 8.14394 11.7014 8.52557L11.669 8.55667C11.2709 8.9383 10.6254 8.9383 10.2273 8.55667L9.04243 7.42076 9.04243 14.9999C9.04243 15.5522 8.59597 15.9999 8.04523 15.9999H7.95496C7.40422 15.9999 6.95776 15.5522 6.95776 14.9999L6.95776 7.42058 5.77266 8.55667C5.37456 8.9383 4.72911 8.9383 4.33101 8.55667L4.29857 8.52557C3.90048 8.14394 3.90048 7.52519 4.29857 7.14355L7.17506 4.38604zM16.8249 19.6139C16.6876 19.7865 16.4944 19.9126 16.2728 19.9645 16.095 20.0116 15.9065 20.0118 15.7286 19.9648 15.506 19.9131 15.312 19.7866 15.1743 19.6131L12.2986 16.8564C11.9005 16.4748 11.9005 15.856 12.2986 15.4744L12.331 15.4433C12.7291 15.0616 13.3746 15.0616 13.7727 15.4433L14.9576 16.5792V9C14.9576 8.44772 15.404 8 15.9548 8H16.045C16.5958 8 17.0422 8.44772 17.0422 9L17.0422 16.5794 18.2273 15.4433C18.6254 15.0616 19.2709 15.0616 19.669 15.4433L19.7014 15.4744C20.0995 15.856 20.0995 16.4748 19.7014 16.8564L16.8249 19.6139z"/>
          </>
      }
    </svg>
  )
}
