// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'home': {
        'title': 'Hello {{username}}!',
        'subtitle': 'Choose where to travel:',
        'find': 'Find again',

        'card': {
            'select': 'Select',
            'info': 'Info'
        }
    },
    'data': {
        'title': 'Are you looking in {{place}}',
        'leaveNow': 'Leave as soon as possible',
        'select': {
            'date': 'Select date',
            'time': 'Select time',
            'departure': 'Select origin',
            'arrival': 'Select destination',
            'noResults': 'No results'
        },
        'passengers': {
            'title': 'Passengers',
            'childs': 'Each traveler has the opportunity to allow a child under one meter in height to travel with him for free.',
            'disabled': 'Indicates the number of passengers with mobility difficulties who need a vehicle with a platform.',
            'total': 'The maximum number is {{limit}} travelers for a single solution'
        }
    },
    'journey': {
        'duration': 'Durations',
        'code': {
            'title': 'Booking Code',
            'message': 'Show the QR code to the driver when you board'
        },
        'delete': {
            'title': 'Cancel Suggestion',
            'message': 'Are you sure you want to cancel your booking?',
            'error': 'The selected trip cannot be canceled. Check the trip cancellation policy on the detail page.',
            'info': 'A trip can be canceled up to {{time}} before departure.',
            'info2': 'In case of prepayment, you will only be refunded if you cancel a trip before {{time}} before departure.'
        }
    },
    'result': {
        'ops': 'Ops...',
        'noresult': 'Attention - We have not found a timely solution as per your request',
        'alternatives': 'Our alternatives:'
    },
    'cart': {
        'voucher': {
            'add': 'Add Promo Code',
            'info': 'If you have a public transport pass, enter the dedicated promo code',
            'ok': 'Voucher applied successfully',
            'error': 'Voucher not valid or already applied to this cart',
            'notset': 'Has the promotional code field been changed? Are you sure you want to proceed without adding it?'
        },
        'data': {
            'reference': {
                'empty': 'Complete the data of the reference passenger',
                'add': {
                    'name': 'Enter the name of the reference passenger',
                    'phone': 'Enter the telephone of the relevant passenger'
                }
            },
            'passengers': {
                'empty': 'Complete the data of the other passengers',
                'title': 'Other Passengers'

            }
        }
    },
    'payment': {
        'continue': 'Proceed with payment',
        'cancel': 'You have canceled the payment',
        'onboard': {
            'default': 'Pay on board',
            'surcharge': 'Pay on board (+ {{surcharge}} {{currency}})',
            'conditions': '(Check the conditions on '
        },
        'purchaseOk': {
            'title': 'Congratulations',
            'message': 'You have purchased your travel document.'
        }
    },
    'booking': {
        'details': 'Booking details',
        'notPaid': 'To Pay',
        'paynow': {
            'action': 'Pay now',
            'message': 'The ticket has not yet been paid. You can pay it now, or pay it on board.'
        }
    }
}
