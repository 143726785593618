const languageCultures = {
  'it': 'it-IT',
  'en': 'en-GB',
  'de': 'de-DE',
  'fr': 'fr-FR',
  'es': 'es-ES'
};

export{
  languageCultures
}