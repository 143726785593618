import React, { useState, useEffect } from 'react'
import {
  Link,
  useLocation
} from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faTimes,
  faFont,
  faSearchMinus,
  faSearchPlus
} from '@fortawesome/free-solid-svg-icons'

const {Logos} = require('../assets/logos')

const Navbar = (props) => {
  const location = useLocation()
  const Logo = Logos?.moevesWhite?.Logo
  const [fontSize, setFontSize] = useState(1)

  useEffect(() => {
    if (location && props.onToggle) {
      props.onToggle(false)
    }
  }, [location])

  useEffect(() => {
    document.body.classList.remove(`font-size-1x`)
    document.body.classList.remove(`font-size-2x`)
    document.body.classList.remove(`font-size-3x`)
    document.body.classList.add(`font-size-${fontSize}x`)
  }, [fontSize])

  const onToggle = () => {
    if (props.onToggle) {
      props.onToggle(!props.toggle)
    }
  }

  const increaseFontSize = () => {
    if (fontSize < 3) {
      setFontSize(fontSize + 1)
    }
  }

  const decreaseFontSize = () => {
    if (fontSize > 1) {
      setFontSize(fontSize - 1)
    }
  }

  return (
    <div className="c-navbar">
      <div className="c-navbar__item text-left">
        <button className="c-navbar__toggle" onClick={onToggle}>
          <FontAwesomeIcon icon={props.toggle ? faTimes : faBars} />
        </button>
      </div>

      <div className="c-navbar__item text-center">
        <Link to="/" className="c-navbar__logo">
          <Logo />
        </Link>
      </div>

      <div className="c-navbar__item text-right">
        <div className="c-navbar__utils">
          <FontAwesomeIcon icon={faFont} />
          <Button
            variant="link"
            onClick={decreaseFontSize}
          >
            <FontAwesomeIcon icon={faSearchMinus} />
          </Button>
          <Button
            variant="link"
            onClick={increaseFontSize}
          >
            <FontAwesomeIcon icon={faSearchPlus} />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Navbar;
