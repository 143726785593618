const {'utilities': utilitiesComponents, staticUtilities} = require('./utilities')
  , {'Settings': SettingsComponent} = require('./settings')
  // , {'Ticket': TicketComponent} = require('./ticket')
  // , navigation = require('./navigation')
  // , {search} = require('./search')
  // , otp = require('./otp')

export const Library = (deps) => ({
  'utilities': utilitiesComponents(deps),
  // navigation
})

export const LibraryStaticUtils = ({
  // navigation,
  // search,
  // otp,
  ...staticUtilities
})
