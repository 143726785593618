export const theme = ({light, dark}) => ({
  'mainColor': {
    [light]: '#ffa500',
    [dark]: '#ffa500'
  },
  'secondColor': {
    [light]: '#ff5e00',
    [dark]: '#ff5e00'
  },
  'backgroundColor': {
    [light]: '#fff',
    [dark]: '#fff'
  },

  'listItem': {
    'containerStyle': {
      'backgroundColor': {
        [light]: '#fff',
        [dark]: '#fff'
      }
    },
    'textStyle': {
      'color': {
        [light]: {
          [light]: '#ffa500',
          [dark]: '#ffa500'
        },
        [dark]: {
          [light]: '#ffa500',
          [dark]: '#ffa500'
        }
      }
    }
  },
  'colors': {
    'success': {
      [light]: '#66cdaa',
      [dark]: '#66cdaa'
    },
    'error': {
      [light]: '#f6546a',
      [dark]: '#f6546a'
    }
  }
})
