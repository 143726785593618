import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { registerLocale } from  "react-datepicker";
import { it, enGB } from 'date-fns/locale';
// eslint-disable-next-line no-unused-vars
import moment from 'moment';

import IT from './IT';
import EN from './EN';

registerLocale('it', it)
registerLocale('en', enGB)
registerLocale('it-IT', it)
registerLocale('en-GB', enGB)
registerLocale('en-US', enGB)

const defaultResources = {
  'fallbackLng': 'it',
  'debug': true,
  'resources': {
    'it': IT,
    'en': EN
  },
  'returnObjects': true,
}

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(defaultResources);

export const setLanguage = (language, remoteResources) => {
  console.log('i18n', language, remoteResources)

  i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(defaultResources);
}

export const i18n = i18next;
