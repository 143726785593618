import React, {useState, useEffect} from 'react'
import moment from 'moment';
import { ProgressBar } from 'react-bootstrap'

const RouteProgressBar = ({ route }) => {
  const [progress, setProgress] = useState(0);

  const updateProgress = () => {
    const {createDate, meta} = route
    const {expiresDate} = meta
    const start = moment(createDate).unix()
    const end = moment(expiresDate).unix()
    const maxValue = end - start
    const value = end - moment().unix()
    const result = 1 - ((value * 100) / maxValue) / 100;

    setProgress(result > 0 ? result * 100 : 0)
  }

  useEffect(() => {
    if (route && route.createDate && route.meta) {
      const interval = setInterval(updateProgress, 1000);
      updateProgress();
      return () => clearInterval(interval);
    }
  }, [route]);

  return (
    <div className="c-routeProgressBar">
      <ProgressBar
        variant="secondary"
        now={progress}
      />
    </div>
  )
}

export default RouteProgressBar
