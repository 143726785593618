import React, {useState, useContext, useEffect, useMemo} from 'react'
import { observer } from 'mobx-react-lite'
import {
  useParams,
  useHistory,
  useLocation,
  generatePath,
  Prompt
} from "react-router-dom"
import {
  Button,
  Container,
  Card,
  Form,
  InputGroup,
  FormControl,
  Modal
} from "react-bootstrap"
import { AlertContext } from '../context/alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'
import ReactCodeInput from 'react-verification-code-input';

import RouteDetailsBox from '../components/RouteDetailsBox'
import RouteProgressBar from '../components/RouteProgressBar'

import IconLock from '../assets/lock.png'

const CELL_COUNT = 4

export const Cart = observer(({
  componentId,
  contexts,
  components,
  utils,
  config
}) => {
  const {AuthContext} = contexts
  const {useTheme, screen} = config
  const {loading, Alert} = components
  const {navigation, translate, Api, libs} = utils
  const {icons} = libs

  const {theme} = useTheme()
  const location = useLocation()
  const history = useHistory()
  const alert = useContext(AlertContext)


  const {
    language,
    user,
    currentTravelResponse,
    setCurrentTravelResponse,
    verifyPhoneNumber
  } = useContext(AuthContext)

  const {'travel': travelResponse, cart} = currentTravelResponse

  const [route, setRoute] = useState(null)
  const [otherPassengers, setOtherPassengers] = useState({})
  const [updateUserName, setUpdateUserName] = useState(false)
  const [updateUserPhone, setUpdateUserPhone] = useState(false)
  const [voucher, setVoucher] = useState('')
  const [passengersNumber, setPassengersNumber] = useState('')
  const [referencePassenger, setReferencePassenger] = useState({
    name: '',
    phone: ''
  })

  const [verifyPhoneModal, setVerifyPhoneModal] = useState(false)
  const [verifyPhoneValue, setVerifyPhoneValue] = useState('')
  const [journeyId, setJourneyId] = useState('')
  const [travelId, setTravelId] = useState('')
  const [deleteCurrentTravel, setDeleteCurrentTravel] = useState(false)

  const [theArea, setTheArea] = useState(null)

  useEffect(() => {
    if (location.state) {
      setJourneyId(location.state.journeyId)
      setTravelId(location.state.travelId)
      setTheArea(JSON.parse(location.state.area))
    } else {
      history.replace('/')
    }
  },[location])

  useEffect(() => {
    if (deleteCurrentTravel) {
      history.replace('/')
      setDeleteCurrentTravel(false)
    }
  }, [deleteCurrentTravel])

  useEffect(() => {
    if (journeyId && travelResponse && travelResponse.journeys) {
      setRoute(travelResponse.journeys.find(j => j.id = journeyId))
    }
  },[travelResponse, journeyId])

  useEffect(() => {
    if (user && user.profile) {
      setReferencePassenger({
        'name': `${user.profile.name} ${user.profile.surname}`,
        'phone': user.profile.cellphone
      })
    }
  }, [user])

  useEffect(() => {
    if (route && route.meta) {
      let count = 0

      route.meta.passengers.forEach(p => count += p.value);

      setPassengersNumber(count)
    }
  }, [route])

  const addVoucher = async() => {
    if(setVoucher !== ''){
      loading.set(true);
      const {cartId} = cart
        , response = await Api.fetch(
            Api.clean(Api.urls?.drt?.cart?.voucher?.url),
            {
              'method': 'POST',
              'body': {
                cartId,
                'voucherCode': voucher,
              }
            });

      loading.set(false);

      if(response.err){
        alert.error(translate('travel.cart.voucher.error'), {
          title: translate('general.advise'),
          closeCopy: translate('general.ok')
        });
        setVoucher('');
        return;
      }

      const voucherTranslation = response.voucherDescription[language] ?? response.voucherDescription.it
      const {'description': voucherDescription} = voucherTranslation;

      setCurrentTravelResponse({
        ...currentTravelResponse,
        'cart': {
          ...cart,
          'discount': {
            ...response,
            voucherDescription
          }
        }
      })

      alert.success(translate('travel.cart.voucher.ok'), {
        title: translate('general.advise'),
        closeCopy: translate('general.ok')
      });
      setVoucher('');
    }
  }

  const submitUpdateCart = async(theOtherPassengers) => {
    loading.set(true);

    const {cartId} = cart
    const response = await Api.fetch(
      Api.clean(Api.urls?.drt?.cart?.update?.url),
      {
        'method': 'POST',
        'body': {
          cartId,
          referencePassenger,
          'otherPassengers': theOtherPassengers
        }
      });

    if(response.err){

      if(response.err === true && response.errMsg && response.errMsg?.message === 'phone.blocked'){
        verifyPhoneNumber(Api, translate)
          .then(result => {
            loading.set(false);
            setVerifyPhoneModal(result)
          })
        }
      else {
        loading.set(false);
        alert.error(translate('errors.general'), {
          title: translate('general.advise'),
          closeCopy: translate('general.ok')
        });
      }

      return;
    }

    history.push('/payment', {
      journeyId,
      'travelId': travelResponse.id,
      'area': JSON.stringify(theArea)
    })
  }

  const updateCart = async() => {
    const theOtherPassengers = [];

    for(const [, value] of Object.entries(otherPassengers)){
      if(value !== ""){
        theOtherPassengers.push(value);
      }
    }

    if(referencePassenger.name === '' || referencePassenger.phone === ''){
      alert.error(translate('travel.cart.data.reference.empty'), {
        title: translate('general.advise'),
        closeCopy: translate('general.ok')
      });
      return;
    }

    if(passengersNumber - 1 !== theOtherPassengers.length){
      alert.error(translate('travel.cart.data.passengers.empty'), {
        title: translate('general.advise'),
        closeCopy: translate('general.ok')
      });
      return;
    }

    if(voucher !== ''){
      alert.error(translate('travel.cart.data.voucher.notset'), {
        title: translate('general.advise'),
        closeCopy: translate('general.cancel'),
        actions: [
          {
            copy: translate('general.ok'),
            onClick: () => submitUpdateCart(theOtherPassengers),
          },
        ],
      });
      return;
    }
    submitUpdateCart(theOtherPassengers);
  }

  const onCancelClick = () => {
    setDeleteCurrentTravel(true)
  }

  const sendVerificationCode = () => verifyPhoneNumber(Api, translate)

  const editProfile = () => history.push('/profile')

  const verifyPhone = async() => {
    loading.set(true);

    const response = await Api.fetch(
      Api.clean(Api?.urls?.drt?.users?.phone?.code?.url),
      {
        'method': 'POST',
        'body': {
          'code': verifyPhoneValue
        }
      });

    loading.set(false);

    if(response.err){
      setVerifyPhoneValue('');

      if(response.errMsg?.message === 'users.phone.alreadyVerified'){
        navigation.dismissModal('verifyPhoneModal');
        return;
      }

      alert.error(translate('profile.phone.error'), {
        title: translate('profile.phone.title'),
        closeCopy: translate('general.ok')
      });
    }
    else {
      setVerifyPhoneModal(false)
    }
  }

  const maskPhoneNumber = useMemo(() => {
    let mask = ''

    if (user && user.profile) {
      mask = '*******' + user?.profile?.cellphone.substring(user?.profile?.cellphone?.length - 3)
    }

    return mask
  }, [user])

  const timeClearance = theArea?.properties?.confs?.travelsSearchConfiguration?.timeClearance

  return (
    <div className="p-cart">
      <Container className="is-small">
        <RouteDetailsBox
          screen={screen}
          theme={theme}
          route={route}
          hideAction={true}
          translate={translate}
          icons={icons}
          discount={cart.discount}
          timeClearance={timeClearance}
        />

        <RouteProgressBar
          theme={theme}
          screen={screen}
          route={route}
        />
        <hr />

        <Card className="mb-3">
          <Card.Header>Passeggeri</Card.Header>
          <Card.Body>
            {
              updateUserName &&
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>1</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    placeholder={referencePassenger.name || translate('travel.cart.data.reference.add.name')}
                    onChange={e => setReferencePassenger({
                      ...referencePassenger,
                      'name': e.target.value
                    })}
                  />
                </InputGroup>
            }

            {
              !updateUserName &&
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>1</InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="text"
                    defaultValue={referencePassenger.name}
                    disabled
                  />
                  <InputGroup.Append>
                  <Button
                      variant="outline-dark"
                      onClick={() => setUpdateUserName(true)}
                    >
                      {translate('general.edit')}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
            }

            {
              updateUserPhone &&
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faPhone} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="text"
                    placeholder={referencePassenger.phone || translate('travel.cart.data.reference.add.phone')}
                    onChange={e => setReferencePassenger({
                      ...referencePassenger,
                      'phone': e.target.value
                    })}
                  />
                </InputGroup>
            }

            {
              !updateUserPhone &&
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faPhone} />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    type="text"
                    defaultValue={referencePassenger.phone}
                    disabled
                  />
                  <InputGroup.Append>
                  <Button
                      variant="outline-dark"
                      onClick={() => setUpdateUserPhone(true)}
                    >
                      {translate('general.edit')}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
            }

            {
              passengersNumber > 1 &&
                <>
                  <hr/>
                  <p>{translate('travel.cart.data.passengers.title')}</p>
                  {
                    [...Array(passengersNumber - 1)].map((p, i) => (
                      <InputGroup className="mb-3" key={`passenger-${i}`}>
                        <InputGroup.Prepend>
                          <InputGroup.Text>{i + 2}</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                          type="text"
                          placeholder={translate('general.name')}
                          value={otherPassengers[i] || ''}
                          onChange={e => setOtherPassengers({
                            ...otherPassengers,
                            [i]: e.target.value
                          })}
                        />
                      </InputGroup>
                    ))
                  }
                </>
            }
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Header>
            {translate('travel.cart.voucher.add')}
          </Card.Header>
          <Card.Body>
            <InputGroup className="mb-2">
              <FormControl
                type="text"
                value={voucher}
                onChange={e => setVoucher(e.target.value.toUpperCase())}
              />
              <InputGroup.Append>
              <Button
                  variant="outline-dark"
                  onClick={addVoucher}
                >
                  {translate('general.add')}
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <Form.Text className="text-muted">
              {translate('travel.cart.voucher.info')}
            </Form.Text>
          </Card.Body>
        </Card>

        <hr />
        <RouteProgressBar
          theme={theme}
          screen={screen}
          route={route}
        />
        <hr />

        <div className="text-center">
          <Button
            onClick={updateCart}
            className="has-min-width mb-3"
          >
            {translate('general.next')}
          </Button>
          <br/>
          <Button
            variant="secondary"
            onClick={onCancelClick}
            className="has-min-width"
          >
              {translate('general.cancel')}
          </Button>
        </div>

      </Container>

      <Modal
        show={verifyPhoneModal}
        onHide={() => setVerifyPhoneModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{translate('profile.phone.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="mb-3">
              <img src={IconLock} alt="lock" width="100" />
            </div>
            <p>{translate('profile.phone.overview', {'phone': maskPhoneNumber})}</p>
          </div>

          <div className="d-flex align-items-center justify-content-center mb-4">
            <ReactCodeInput
              className="c-verify-numbers"
              fields={CELL_COUNT}
              type="number"
              fieldWidth={60}
              fieldHeight={60}
              onChange={setVerifyPhoneValue}
            />
          </div>

          <div className="text-center">
            <Button
              onClick={verifyPhone}
              disabled={verifyPhoneValue?.length < CELL_COUNT}
              className="mb-2 has-min-width"
            >
              {translate('general.verify')}
            </Button>
            <br/>

            <Button
              variant="link"
              onClick={sendVerificationCode}
            >
              {translate("profile.phone.sendagain")}
            </Button>

            <p>{translate('general.or')}</p>

            <Button
              variant="link"
              onClick={editProfile}
            >
              {translate("profile.phone.edit")}
            </Button>
          </div>

        </Modal.Body>
      </Modal>

      <Prompt
        when={deleteCurrentTravel}
        message={JSON.stringify({
          text: translate('travel.journey.delete.title') + '\n' + translate('travel.journey.delete.message'),
          travelId: travelResponse.id
        })}
      />
    </div>
  );
})
