import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {
  Button,
  Card,
  Row,
  Col
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import RouteBoxHeader from './RouteBoxHeader'
import RouteProgressBar from './RouteProgressBar'

const RouteDetailsBox = ({
  route,
  onClick,
  onCancelClick,
  hideAction = false,
  translate,
  discount,
  isMapVisible = false,
  timeClearance = 0
}) => {

  let startTime = moment(route?.meta?.startDate).format('kk:mm')
    , endTime = moment(route?.meta?.endDate).format('kk:mm')

  if(timeClearance > 0){
    startTime = `${moment(route?.meta?.startDate).add(-timeClearance, 'm').format('kk:mm')} - ${moment(route?.meta?.startDate).add(timeClearance, 'm').format('kk:mm')}`;
    endTime = `${moment(route?.meta?.endDate).add(-timeClearance, 'm').format('kk:mm')} - ${moment(route?.meta?.endDate).add(timeClearance, 'm').format('kk:mm')}`;
  }

  return (
    <div className="c-routeBox is-detail">
      <Card>
        <Card.Header>
          <RouteBoxHeader
            className="c-routeBox__header"
            route={route}
            translate={translate}
            discount={discount}
          />
        </Card.Header>

        <Card.Body className="c-routeBox__body">
          <Row className="mb-3">
            <Col xs={7} className="text-left">
              <div className="d-flex">
                {
                  isMapVisible &&
                    <span className="mr-1 text-primary" style={{ lineHeight: 1 }}>
                      <FontAwesomeIcon
                        icon={faCircle}
                        size="xs"
                      />
                    </span>
                }
                <small>{route?.meta?.departureDescription}</small>
              </div>
            </Col>
            <Col xs={5} className="text-right">
            <small>{startTime}</small>
            </Col>
          </Row>
          <Row>
            <Col xs={7} className="text-left">
              <div className="d-flex">
                {
                  isMapVisible &&
                    <span className="mr-1 text-secondary" style={{ lineHeight: 1 }}>
                      <FontAwesomeIcon
                        icon={faCircle}
                        size="xs"
                      />
                    </span>
                }
                <small>{route?.meta?.arrivalDescription}</small>
              </div>
            </Col>
            <Col xs={5} className="text-right">
              <small>{endTime}</small>
            </Col>
          </Row>
          <br />
          <small style={{fontWeight: 'bold'}}>{translate('general.points.notice')}</small>
          <br />
          <br />
          <small>{translate('general.time.notice')}</small>
        </Card.Body>

        {
          !hideAction &&
            <Card.Footer className="c-routeBox__footer">
              <RouteProgressBar route={route} />
            </Card.Footer>
        }
      </Card>

      {
        !hideAction &&
          <div className="text-center mt-4">
            <Button
              variant="primary"
              block
              onClick={onClick}
            >
              {translate('general.booking')}
            </Button>

            <Button
              variant="secondary"
              block
              onClick={onCancelClick}
            >
              {translate('general.cancel')}
            </Button>
          </div>
      }
    </div>
  )
}

export default RouteDetailsBox
