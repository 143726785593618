import React, {useContext, useState, useEffect} from 'react';
import moment from 'moment';
import { observer } from 'mobx-react-lite'
import { AlertContext } from '../context/alert'
import {
  useHistory,
  useLocation,
  useParams,
  generatePath
} from 'react-router-dom'
import {
  Button,
  Modal
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import QRCode from "react-qr-code";
import RouteDetailsBox from '../components/RouteDetailsBox'
import RouteMap from '../components/RouteMap'

const DEFAULT_DELETE_POLICY = {
  'delete': {
    'value': 0,
    'unit': 'min',
    'unitMoment': 'm'
  },
  'refund': {
    'value': 0,
    'unit': 'min',
    'unitMoment': 'm'
  }
}

export const TicketDetails = observer(({
  componentId,
  contexts,
  components,
  utils,
  config,
  journeyId
}) => {
  const {AuthContext, DeviceContext} = contexts
  const {screen} = config
  const {navigation, Api, translate, libs} = utils
  const {icons, device} = libs
  const {loading} = components

  const {isLoggedIn, currentTravelResponse, setCurrentTravelResponse, tickets} = useContext(AuthContext)
  const {settings, tenant} = useContext(DeviceContext)

  const history = useHistory()
  const location = useLocation()
  const params = useParams()
  const alert = useContext(AlertContext)

  const [theArea, setTheArea] = useState(null)

  const [deletePolicy, setDeletePolicy] = useState(DEFAULT_DELETE_POLICY)
  const [purchased, setPurchased] = useState(null)
  const [expired, setExpired] = useState(null)

  const [route, setRoute] = useState(null)
  const [expiresDate, setExpiresDate] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [currency, setCurrency] = useState(null)
  const [shape, setShape] = useState([])
  const [markers, setMarkers] = useState([])
  const [ticketStatus, setTicketStatus] = useState(null)
  const [canDelete, setCanDelete] = useState(null)
  const [qrmodalVisible, setQrmodalVisible] = useState(false)
  const [layoutDeletePolicy, setLayoutDeletePolicy] = useState({})
  const [stripePopup, setStripePopup] = useState(null)
  const [stripePopupResult, setStripePopupResult] = useState(null)

  useEffect(() => {
    if (location.state) {
      const { ticket, purchased, expired } = location.state

      if(ticket != null && purchased) {
        if(expired){
          setRoute(ticket)
        }
        else {
          const t = tickets.find(t => t.id === ticket)
          if (t) {
            setRoute(t)
          } else {
            history.replace('/my-tickets')
          }
        }
      }

      setPurchased(purchased)
      setExpired(expired)
    } else {
      history.replace('/my-tickets')
    }
  }, [location])

  useEffect(() => {
    if (route) {

      console.log(route);

      const {refunded} = route;
      const {expiresDate, startDate, currency} = route.meta
      const shape = JSON.parse(route.geometry)
      const [departureLon, departureLat] = JSON.parse(route.departure ?? route.userDeparture).coordinates
      const [arrivalLon, arrivalLat] = JSON.parse(route.arrival ?? route.userArrival).coordinates

        , [userDepartureLon, userDepartureLat] = JSON.parse(route.userDeparture ?? route.departure).coordinates
        , [userArrivalLon, userArrivalLat] = JSON.parse(route.userArrival ?? route.arrival).coordinates

        , markers = [
            {'latitude': userDepartureLat, 'longitude': userDepartureLon},
            {'latitude': departureLat, 'longitude': departureLon},
            {'latitude': arrivalLat, 'longitude': arrivalLon},
            {'latitude': userArrivalLat, 'longitude': userArrivalLon}
          ]

      setExpiresDate(expiresDate)
      setStartDate(startDate)
      setCurrency(currency)
      setShape(shape)
      setMarkers(markers)

      setDeletePolicy(route?.area?.properties?.confs?.travelDeleteConfigurations ?? {
        'deleteLimitTimeBeforeStart': {
          'value': 0,
          'unit': 'min',
          'unitMoment': 'm'
        },
        'paymentRefundLimitTimeBeforeStart': {
          'value': 0,
          'unit': 'min',
          'unitMoment': 'm'
        }
      })

      if (refunded) {
        setTicketStatus(translate('general.canceled'))
      } else if (expired){
        setTicketStatus(translate('general.expired'))
      } else if (!route.alreadyPaid){
        setTicketStatus(translate('travel.booking.notPaid'))
      }
    }
  }, [route])

  useEffect(() => {
    if (tenant && tenant.areas) {
      setTheArea(tenant.areas.features[0])
    }
  }, [tenant])

  useEffect(() => {
    if (deletePolicy) {
      setLayoutDeletePolicy({
        'delete': `${deletePolicy?.deleteLimitTimeBeforeStart?.value ?? 0} ${deletePolicy?.deleteLimitTimeBeforeStart?.unit ?? 'min'}`,
        'refund': `${deletePolicy?.paymentRefundLimitTimeBeforeStart?.value ?? 0} ${deletePolicy?.paymentRefundLimitTimeBeforeStart?.unit ?? 'min'}`,
      })

      if (startDate) {
        setCanDelete(moment().isBefore(moment(startDate).add(-deletePolicy?.deleteLimitTimeBeforeStart?.value, deletePolicy?.deleteLimitTimeBeforeStart?.unitMoment)))
      }
    }
  }, [deletePolicy, startDate])

  useEffect(() => {
    if (purchased !== undefined && purchased !== true && expiresDate){
      const interval = setInterval(() => {
        const now = moment()
        const endMoment = moment(expiresDate);

        if(now.isAfter(endMoment)){
          clearInterval(interval);

          alert.error(translate(`errors.timeExpired`), {
            title: translate('general.advise'),
            closeCopy: translate('general.ok'),
            onClose: () => history.replace('/')
          });
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [purchased, expiresDate]);

  useEffect(() => {
    if (stripePopupResult && stripePopup) {
      stripePopup.close()
    }
  }, [stripePopupResult])

  useEffect(() => {
    const postMessageHandler = e => {
      if (e.type === 'message' && e.origin === process.env.REACT_APP_API_DRT) {
        switch (e.data.result) {
          case 'ok':
            alert.success(translate('travel.payment.purchaseOk.message'), {
              title: translate('travel.payment.purchaseOk.title'),
              closeCopy: translate('general.ok'),
              onClose: () => {
                history.replace('/my-tickets')
              }
            })
            break
          case 'ko':
            alert.error(translate('errors.payment'), {
              title: translate('general.advise'),
              closeCopy: translate('general.ok'),
              onClose: () => {
                history.replace('/')
              }
            })
            break
          case 'cancel':
          default:
            alert.error(translate('travel.payment.cancel'), {
              title: translate('general.advise'),
              closeCopy: translate('general.ok'),
              onClose: () => {
                history.replace('/')
              }
            })
        }

        setStripePopupResult(e.data.result)
      }
    }

    window.addEventListener('message', postMessageHandler)

    return () => window.removeEventListener('message', postMessageHandler)
  }, [])

  const showStripePopup = url => {
    const stripePopupWidth = window.innerWidth - 200
    const stripePopupHeight = window.innerHeight - 200
    const stripePopupTop = Math.round((window.innerHeight - stripePopupHeight) / 2)
    const stripePopupLeft = Math.round((window.innerWidth - stripePopupWidth) / 2)

    const stripePopupSize = `width=${stripePopupWidth},height=${stripePopupHeight}`
    const stripePopupPosition = `top=${stripePopupTop},left=${stripePopupLeft}`
    const stripePopup = window.open(url, 'StripePaymentWindow', `${stripePopupSize},${stripePopupPosition}`)

    setStripePopup(stripePopup)
  }

  const ratingTicket = () => history.push(generatePath('/rating/:id', {
    id: route.id
  }))

  const bookingRequest = async() => {
    if(isLoggedIn === true){
      const response = await Api.fetch(
        Api.clean(Api?.urls?.drt?.cart?.add?.url),
        {
          'method': 'POST',
          'body': {
            'journeyId': route.id,
            startDate
          }
        })

      if(response.err){
        alert.error(translate('errors.general'), {
          title: translate('general.advise'),
          closeCopy: translate('general.ok')
        });
        return;
      }

      if(currentTravelResponse?.cart?.cartId !== response.cartId){
        setCurrentTravelResponse({
          ...currentTravelResponse,
          'cart': response
        })
      }

      history.push('/cart', { journeyId })
    }
    else {
      history.replace('/login')
    }
  }

  const deleteTicket = async() => {
    alert.error(translate('travel.journey.delete.message'), {
      title: translate('general.advise'),
      closeCopy: translate('general.no'),
      actions: [
        {
          copy: translate('general.yes'),
          onClick: async () => {
            const response = await Api.fetch(
              Api.clean(Api?.urls?.drt?.bookings?.url),
              {
                'method': 'POST',
                'body': {
                  'ticketId': route.id
                }
              }
            );

            console.log(response)

            if(response.err){
              let message = translate('errors.general');

              if(response?.errMsg?.message === 'ticket.cannot.refund'){
                message = translate('travel.journey.delete.error')
              }

              alert.error(message, {
                title: translate('general.advise'),
                closeCopy: translate('general.ok')
              });
              return;
            } else {
              history.push('/my-tickets')
            }
          },
        },
      ]
    });
  }

  const paymentHandler = async paymentGateway => {
    if (paymentGateway === 'Stripe' && stripePopup) {
      if (stripePopup.closed) {
        setStripePopup(null)
      } else {
        stripePopup.focus()
        return
      }
    }

    loading.set(true);

    const {cartId} = route
    const response = await Api.fetch(
      Api.clean(Api?.urls?.drt?.payments?.url),
      {
        'method': 'POST',
        'headers': {
          'platform': 'web'
        },
        'body': {
          cartId,
          currency,
          paymentGateway
        }
      }
    )

    loading.set(false);

    if(response.err){
      alert.error(translate('errors.payment'), {
        title: translate('general.advise'),
        closeCopy: translate('general.ok')
      })
      return;
    }

    const {url} = response;

    // Case payment with stripe
    showStripePopup(url)
  }

  const timeClearance = route?.area?.properties?.confs?.travelsSearchConfiguration?.timeClearance

  return (
    <div className="p-ticketDetail">
      {
        route &&
          <>
            <div className="p-ticketDetail__sidebar">
              <div className="text-center">
                <Button
                  className="mb-4"
                  variant="outline-dark"
                  onClick={() => setQrmodalVisible(true)}
                >
                  <span className="d-flex align-items-center">
                    <FontAwesomeIcon
                      size="2x"
                      icon={faQrcode}
                      className="mr-2"
                    />
                    {translate('travel.journey.code.title')}
                  </span>
                </Button>
                {
                  ticketStatus &&
                    <h4 className="mb-4 text-secondary">{ticketStatus}</h4>
                }
              </div>

              <RouteDetailsBox
                isMapVisible={true}
                screen={screen}
                route={route}
                onClick={bookingRequest}
                translate={translate}
                icons={icons}
                hideAction={purchased}
                discount={route.cartMeta.discount}
                timeClearance={timeClearance}
              />

              {
                !route.alreadyPaid && !expired && !route.refunded &&
                  <>
                    <p>{translate('travel.booking.paynow.message')}</p>
                    <Button
                      block
                      variant="primary"
                      onClick={() => paymentHandler('Stripe')}
                    >
                      {translate('travel.booking.paynow.action')}
                    </Button>
                  </>
              }

              {
                !route.refunded &&
                  <div className="mt-3">
                    {
                      deletePolicy?.deleteLimitTimeBeforeStart?.value &&
                        <>
                          <p>
                            <small>{translate('travel.journey.delete.info', {'time': layoutDeletePolicy.delete})}</small>
                          </p>
                          {
                            deletePolicy?.deleteLimitTimeBeforeStart?.value < deletePolicy?.paymentRefundLimitTimeBeforeStart?.value &&
                              <p>{translate('travel.journey.delete.info2', {'time': layoutDeletePolicy.refund})}</p>
                          }
                        </>
                    }

                    <Button
                      block
                      disabled={!canDelete}
                      variant={canDelete ? "secondary" : 'dark'}
                      onClick={deleteTicket}
                    >
                      {translate('travel.journey.delete.title')}
                    </Button>

                    {/* <Button
                      block
                      variant="secondary"
                      onClick={ratingTicket}
                    >
                      Rating
                    </Button> */}
                  </div>
              }
            </div>
            <div className="p-ticketDetail__map">
              <RouteMap
                theArea={theArea}
                shape={shape.geometries}
                markers={markers}
                isTicketDetail={true}
              />
            </div>
            <Modal
              show={qrmodalVisible}
              onHide={() => setQrmodalVisible(!qrmodalVisible)}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{translate('travel.journey.code.title')}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                <p>{translate('travel.journey.code.message')}</p>
                <div className="mb-4">
                  <QRCode
                    size={200}
                    value={route.code}
                  />
                </div>
                <Button
                  variant="primary"
                  onClick={() => setQrmodalVisible(!qrmodalVisible)}
                >
                  {translate('general.close')}
                </Button>
              </Modal.Body>
            </Modal>
          </>
      }
    </div>
  );
})
