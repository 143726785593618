import login from './login';
import profile from './profile';
import errors from './errors';
import tncs from './tncs';
import travel from './travel';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'translation': {
        'general': {
            'ok': 'Ok',
            'yes': 'Si',
            'no': 'No',
            'edit': 'Modifica',
            'next': 'Procedi',
            'back': 'Indietro',
            'continue': 'Continua',
            'confirm': 'Conferma',
            'booking': 'Prenota',
            'verify': 'Verifica',
            'share': 'Condividi',
            'notShare': 'Non condividere',
            'or': 'oppure',
            'add': 'Aggiungi',
            'addNow': 'Aggiungi adesso',
            'later': 'Lo farò in seguito',
            'cancel': 'Annulla',
            'delete': 'Elimina',
            'close': 'Chiudi',
            'nextTime': 'Chiedimelo la prossima volta',
            'search': 'Cerca',
            'preferred': 'Imposta come opzioni predefinite',
            'notNow': 'Non adesso',
            'settings': 'Impostazioni',
            'send': 'Invia',
            'save': 'Salva',
            'advise': 'Avviso',
            'date': 'Data',
            'time': 'Orario',
            'arrival': 'Arrivo',
            'departure': 'Partenza',
            'add': 'Aggiungi',
            'name': 'Nome',
            'passengers': {
                'adults': 'Adulti',
                'childs': 'Bambini',
                'disabled': 'Disabili con ridotte o impedite capacità motorie'
            },
            'time': {
                'time': 'Orario',
                'today': 'Oggi',
                'tomorrow': 'Domani',
                'yesterday': 'Ieri',
                'pass': '{{days}} giorni fa',
                'future': 'Fra {{days}} giorni',
                'notice': 'Orario presunto calcolato in condizioni di traffico normali'
            },
            'points': {
                'notice': 'Attenzione, i punti di partenza e/o arrivo della soluzione trovata, potrebbero differire da quelli ricercati'
            },
            'fleets': {
                'door_to_door': 'Porta a Porta',
                'point_to_point': 'Point to Point',
                'ecommerce': 'Ecommerce'
            },
            'units': {
                'minutes': 'min'
            },
            'canceled': 'Annullato',
            'expired': 'Scaduto',
            'position': 'Posizione attuale',
            'backToHome': 'Torna alla Home',
            'version': 'Versione {{versionNumber}}'
        },

        'validation': {
            'methods': {
                'qr': 'QR Code',
                'nfc': 'NFC',
                'code': 'Codice'
            }
        },

        'languages': {
            'it': 'Italiano',
            'en': 'English'
        },

        'menu': {
            'settings' : 'Impostazioni',
            'logout' : 'Logout',
            'login' : 'Accedi',
            'travel' : 'Prenota Corsa',
            'mytickets' : 'le mie corse',
            'info' : 'Info utili',
            'support' : 'Assistenza',
            'help' : 'Come funziona',
            'terms' : 'Termini e condizioni',
            'privacy' : 'Privacy Policy'
        },

        'toBar': {
            'travel': 'Il tuo Viaggio',
            'login': 'Accedi',
            'register': 'Registrati',
            'cart': 'Riepilogo',
            'payment': 'Pagamento',
            'userdata': 'Dati utente',
            'tncs': 'Termini e condizioni',
            'mytickets': 'le mie corse'
        },
        'map': {
            'release': 'Rilascia la mappa per ottenere le informazioni...'
        },

        login,
        profile,
        errors,
        tncs,

        travel
    }
}
