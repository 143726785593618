const moeves = require('./moeves')
const moevesWhite = require('./moevesWhite')
const grandabus = require('./grandabus')

export const Logos = {
  moeves,
  'asgard': moeves,
  grandabus,
  moevesWhite
}
