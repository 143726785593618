import axios from 'axios';

const querySerialize = (obj) => {
    let str = '?' + Object.keys(obj).reduce(function(a, k){
        a.push(k + '=' + encodeURIComponent(obj[k]));
        return a;
    }, []).join('&');
    return str;
  }


export const googleMaps = ({contexts, Api, createPlaceObj, manageACallback}) => {

  return {
    'parsePlace': item => createPlaceObj(item.id, item.title, item.position[0], item.position[1], item),
    'reverseGeocode': async(lat, lng, geocoderConfig, callback) => {
      const {auth} = contexts
      , {user} = auth
      , {language = 'it'} = user
      , {urls, config} = geocoderConfig

      try {
        const params = {
              'key': config.apiKey,
              language,
              'latlng': `${lat},${lng}`
            }
          , response = await axios.get(`${urls.reverse}${querySerialize(params)}`, {
              'method': 'GET',
              'headers': {
                'Accept': '*/*',
                'Content-Type': 'application/json'
              }
            })
          , item = response?.data?.results?.find(({types}) => types?.includes('street_address')) ?? response?.data?.results[0];

        if (item) {
          let found = false
            , myPlace = undefined;

          const {formatted_address, place_id} = item;
          if (!found) {
            found = true;

            myPlace = {
              'placeId': place_id,
              'address': formatted_address,
              'details': '',
              'latitude': lat,
              'longitude': lng,
              'fullAddress': formatted_address
            };
          }
          return manageACallback(myPlace, callback);
        }
      }
      catch (e){
        console.log(e)
      }

    },
    'search': async({text, bbox, center, geocoderConfig, /*getUserLocation*/}, callback) => {
      const {auth} = contexts
      , {user} = auth
      , {language = 'it'} = user
      , {urls, config} = geocoderConfig;

      try{
        const params = {
          'key': config.apiKey,
          'language': language,
          'input': text,
          'types': 'address'
        }

        if(center?.coordinates?.length > 0){
          const [centerLongitude, centerLatitude] = center.coordinates;
          params[`location`] = `${centerLatitude},${centerLongitude}`;
          params[`radius`] = config.radius || 30000;
          params[`strictbounds`] = true;
        }

        const response = await axios.get(`${urls.autocomplete}${querySerialize(params)}`, {'method': 'GET'})
          , [item] = response?.data?.predictions

        let myPlace = [];

        if (item) {
          let found = false;

          const {'place_id': placeId} = item
            , detailsParams = {
                'place_id': placeId,
                'fields': 'formatted_address,geometry',
                'key': config.apiKey,
                language
              }
            , detailRes = await axios.get(`${urls.search}${querySerialize(detailsParams)}`, {
                'method': 'GET',
                headers: {}
              })
            , {geometry, formatted_address} = detailRes?.data?.result;

          if (!found) {
            found = true;
            myPlace.push({
              'placeId': placeId,
              'address': formatted_address,
              'details': '',
              'latitude': geometry.location.lat,
              'longitude': geometry.location.lng,
              'fullAddress': formatted_address
            });
          }

          return manageACallback(myPlace, callback);

        };

      }catch (e){
        console.log(e)
      }
    }
  }
}
