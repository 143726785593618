import React, {useState, useContext, useEffect} from 'react'
import moment from 'moment';
import { observer } from 'mobx-react-lite'
import {
  Button,
  Form
} from 'react-bootstrap'

const isSelected = ({acceptDate, updateDate}) => {
  if(acceptDate && updateDate){
    if(moment(acceptDate).isAfter(moment(updateDate))){
      return true;
    }
  }
  return false;
}

const remapTncs = (tncs, language) => tncs.map(t => ({
  ...t,
  'selected': isSelected(t),
  'template': t[language] || t.en,
}))


const TncView = ({tncFk, selected, template, updateTncDatas}) => {
  const {name, description, link} = template;

  return <div className="mb-3">
    <a href={link} target="_blank" rel="noreferrer" className="d-block">
      {name}
    </a>
    <p>{description}</p>

    <Form.Check
      id={`${name}-accept`}
      type="checkbox"
      label='Accetto'
      checked={selected}
      onChange={() => updateTncDatas(tncFk)}
    />

    <Form.Check
      id={`${name}-not-accept`}
      type="checkbox"
      label='Non Accetto'
      checked={!selected}
      onChange={() => updateTncDatas(tncFk)}
    />
  </div>
}

const TermsConditions = observer(({
  contexts,
  components,
  utils,
  tncs = [],
  disableButton = false,
  setExternalTncs = () => {}
}) => {
  const {AuthContext} = contexts
  const {loading} = components
  const {translate, Api} = utils
  const {isLoggedIn, language, validateTncs} = useContext(AuthContext)
  const [tncsDatas, setTncDatas] = useState(remapTncs(tncs, language))

  const updateTncDatas = (tncFk) => {
    const theTncDatas = [...tncsDatas];
    for(const t of theTncDatas){
      if(t.tncFk === tncFk){
        t.selected = !t.selected;
      }
    }
    setTncDatas(theTncDatas);
    setExternalTncs(theTncDatas);
  }

  const acceptTncs = () => {
    const acceptedTncs = [];

    let allBlockedChecked = true;

    for(const {tncFk, blocked, selected} of tncsDatas){
      if(blocked === true && selected === false){
        allBlockedChecked = false;
      }

      if(selected === true){
        acceptedTncs.push(tncFk);
      }
    }

    if(!allBlockedChecked){
      alert(translate('tncs.title') + ' ' + translate('tncs.check.message'));
      return;
    }
    else if(tncsDatas.length === 0){
      return;
    }

    loading.set(true);

    Api.fetch(
      Api.clean(Api?.urls?.drt?.users?.tncs.url),
      {
        'method': 'POST',
        'body': acceptedTncs
      },
      res => {
        loading.set(false);

        if(res.err){
          alert(translate('tncs.title') + ' ' + translate('tncs.check.message'));
        } else {
          validateTncs()
        }
      }
    );
  }

  useEffect(() => {
    if(tncsDatas.length === 0 && isLoggedIn){
      try {
        loading.set(true);
        Api.fetch(
          Api.clean(Api?.urls?.drt?.users?.tncs?.url),
          {'method': 'GET'},
          res => {
            if(res.err){
              console.log(`Get tncs throw an error: ${res.err.message}`);
            }
            else {
              setTncDatas(remapTncs(res, language))
            }
            loading.set(false);
          }
        );
      }
      catch(e){
        console.log(`Get tncs throw an error: ${e.message}`);
      }

    }
  }, [tncsDatas])

  return (
    <>
      <p className="text-muted">{translate('tncs.overview')}</p>

      {
        tncsDatas.map(item => <TncView key={item.tncFk} {...item} updateTncDatas={updateTncDatas}  /> )
      }

      {
        !disableButton &&
          <div className="text-center">
            <Button onClick={acceptTncs}>
              {translate('general.continue')}
            </Button>
          </div>
      }
    </>
  )
});

export default TermsConditions
