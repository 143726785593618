import React, { useState, useEffect, useContext } from 'react'
import {
  useHistory,
  generatePath
} from "react-router-dom"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faRoute } from '@fortawesome/free-solid-svg-icons'

import {
  Badge,
  Button,
  Card,
  Row,
  Col,
  Container
} from 'react-bootstrap'

import { observer } from 'mobx-react-lite'

const { v4: uuidv4 } = require('uuid')
const {Logos} = require('../assets/logos')

export const Home = observer(({
  contexts,
  utils,
  components,
  config,
}) => {
  const history = useHistory()

  const {AuthContext, DeviceContext} = contexts
  const {Config, environment} = config
  const {loading} = components
  const {Api, translate} = utils
  const {user, language} = useContext(AuthContext)
  const {tenant, setTenant} = useContext(DeviceContext)

  const findAreas = () => {
    loading.set(true);
    setTenant(Api, Config);
    loading.set(false);
  }

  const goToInfo = (url, event) => {
    event.stopPropagation()
    window.open(url, '_blank');
  }


  const goToTravel = aArea => {
    if(aArea.properties?.confs?.link != null){
      window.open(aArea.properties.confs.link, '_blank');
    }
    else {
      history.push(generatePath('/area/:areaId', {
        areaId: aArea.properties.id
      }))
    }
  }

  const createSelectorBox = aArea => {
    const { translations, fleets = []} = aArea?.properties
    const translatedData = translations[language] || translations.it
    const {name, description, url} = translatedData
    const Logo = Logos?.grandabus?.Logo

    return (
      <Card onClick={() => goToTravel(aArea)}>
        <Card.Header>
          <div className="d-flex justify-content-between align-items-center">
            <strong>{name}</strong>
            <div className="text-secondary">
              {translate('travel.home.card.select')}
              <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <Card.Text>{description}</Card.Text>
          {
            fleets?.map(s => <div key={uuidv4()}>
              <Badge variant="info">
                <FontAwesomeIcon icon={faRoute} className="mr-2"/>
                {translate(`general.fleets.${s}`)}
              </Badge>
            </div>)
          }
          {
            url &&
              <Button variant="link" onClick={event => goToInfo(url, event)}>
                {translate('travel.home.card.info')}
              </Button>
          }
        </Card.Body>
        {/* <Card.Footer className="text-muted">
          {Logo ? <Logo width={100} /> : <></>}
        </Card.Footer> */}
      </Card>
    )
  }

  return (
    <section className="p-home">
      <Container>
        <div className="text-center">
          {
            environment !== 'prod' &&
              <small className="text-danger">{`${environment} env for ${tenant?.name}`}</small>
          }

          <h4>{translate('travel.home.title', {'username': user?.profile?.name})}</h4>

          {
            tenant?.areas?.features?.length > 0 &&
              <h5>{translate('travel.home.subtitle')}</h5>
          }

          <br />

          {
            tenant?.areas?.features?.length === 0 &&
              <>
                <p className="text-danger">{translate('errors.noAreas')}</p>
                <Button onClick={findAreas} disabled={loading.isEnable}>
                  {translate(loading.isEnable ? 'Loading...' : 'travel.home.find')}
                </Button>
              </>
          }

          <Row>
            {
              tenant?.areas?.features?.map(aArea => {
                return (
                  <Col lg="4" key={aArea?.properties.id}>
                    {createSelectorBox(aArea)}
                  </Col>
                )
              })
            }
          </Row>
        </div>
      </Container>
    </section>
  )
})
