import {
  createContext,
  useState
} from 'react'
import {
  Button,
  Modal
} from 'react-bootstrap'

export const AlertContext = createContext()

export const AlertProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(null)
  const [text, setText] = useState('')
  const [title, setTitle] = useState('')
  const [closeCopy, setCloseCopy] = useState(null)
  const [onCloseCallback, setOnCloseCallback] = useState(null)
  const [actions, setActions] = useState([])
  const [variant, setVariant] = useState('success')

  const hide = () => {
    if (onCloseCallback) {
      onCloseCallback()
    }

    setShowModal(false)
  }

  const show = (text, options) => {
    if (showModal) {
      setShowModal(false)
    }

    setText(text)
    setTitle(options?.title || '')
    setCloseCopy(options?.closeCopy || null)
    setOnCloseCallback(options?.onClose || null)
    setActions(options?.actions || [])
    setShowModal(true)
  }

  const success = (text, options) => {
    setVariant('success')
    show(text, options)
  }

  const error = (text, options) => {
    setVariant('danger')
    show(text, options)
  }

  return (
    <AlertContext.Provider value={{
      success,
      error
    }}>
      {children}

      <Modal
        show={showModal}
        onHide={hide}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {text}
        </Modal.Body>
        <Modal.Footer>
          {
            closeCopy &&
              <Button
                variant="dark"
                onClick={hide}
              >
                {closeCopy}
              </Button>
          }

          {
            actions.map(item => {
              return <Button
                variant="primary"
                onClick={item.onClick}
              >
                {item.copy}
              </Button>
            })
          }
        </Modal.Footer>
      </Modal>

      {/* <Alert
        id="main-alert"
        show={showModal}
        variant={variant}
        onClose={() => hide()}
        dismissible
      >
        <small className="font-weight-bold">{text}</small>
      </Alert> */}
    </AlertContext.Provider>
  )
}
