import React, { useMemo, useRef } from 'react'
import ReactDOMServer from "react-dom/server"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { Marker } from 'react-leaflet'
import { divIcon } from "leaflet"

const DraggableMarker = ({
  position,
  color,
  onDragStart,
  onDragEnd,
  draggable = true
}) => {
  const markerRef = useRef(null)

  const eventHandlers = useMemo(
    () => ({
      dragstart() {
        const marker = markerRef.current
        if (marker != null && onDragStart) {
          const latLng = marker.getLatLng()
          onDragStart({
            latitude: latLng.lat,
            longitude: latLng.lng
          })
        }
      },
      dragend() {
        const marker = markerRef.current
        if (marker != null && onDragEnd) {
          const latLng = marker.getLatLng()
          onDragEnd({
            latitude: latLng.lat,
            longitude: latLng.lng
          })
        }
      },
    }),
    [],
  )

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={position}
      ref={markerRef}
      icon={(() => divIcon({
        iconSize: [30,40],
        iconAnchor: [15, 30],
        html: ReactDOMServer.renderToStaticMarkup(
          <div className={`c-draggableMarker text-${color || 'dark'}`}>
            <FontAwesomeIcon icon={faMapMarkerAlt} />
          </div>
        )
      }))()}
    />
  )
}

export default DraggableMarker
