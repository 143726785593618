import React from 'react';
import { observer } from 'mobx-react-lite'
import { useHistory } from "react-router-dom"

import FormForgotPassword from '../components/FormForgotPassword'

export const ForgotPassword = observer(({
	utils
}) => {
  const history = useHistory()

	return (
    <div className="p-forgotPassword">
      <FormForgotPassword
        utils={utils}
        onComplete={() => history.push('/login')}
      />
    </div>
	)
})
