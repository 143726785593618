import React from 'react'
import { Spinner } from 'react-bootstrap'

const Loading = () => {
  return (
    <div className="c-loader">
      <Spinner
        variant="primary"
        animation="border"
        size="large"
      />
    </div>
  )
}

export default Loading
