import React from 'react'
import { useHistory } from "react-router-dom"
import { observer } from 'mobx-react-lite'

import FormLogin from '../components/FormLogin'

export const Login = observer(({
  contexts,
  utils
}) => {
  const history = useHistory()

  return (
    <div className="p-login">
      <FormLogin
        contexts={contexts}
        utils={utils}
        onLogin={() => history.push('/')}
        onForgotPasswordButtonClick={() => history.push('/forgot-password')}
        onSignUpButtonClick={() => history.push('/register')}
      />
    </div>
  )
})
