import React from 'react';

export const Logo = () => {
  const originalWidth = 841
    , originalHeight = 400
    , st0 = '#00A3A1'
    , st1 = '#EC6608'
    , st2 = '#86888C'
    , st3 = '#CBCCD0'

  return (
    <svg viewBox={`0 0 ${originalWidth} ${originalHeight}`} preserveAspectRatio="xMinYMin meet">
      <g>
      <path className="st0" fill={st0} d="M341.99,152.85c-6.08,0-11.03,4.95-11.03,11.03c0,6.08,4.95,11.04,11.03,11.04c6.08,0,11.03-4.95,11.03-11.04
          C353.02,157.8,348.07,152.85,341.99,152.85"/>
        <path className="st1" fill={st1} d="M382.2,152.85c-6.08,0-11.03,4.95-11.03,11.03c0,6.08,4.95,11.04,11.03,11.04c6.08,0,11.03-4.95,11.03-11.04
          C393.23,157.8,388.28,152.85,382.2,152.85"/>
        <path className="st2" fill={st2} d="M218.56,190.72l-0.11-0.01c-3.7-0.15-3.98-0.15-4.09-0.14c-1.04,0.04-2.64,0.15-2.68,0.15
          c-16.01,1.37-28.77,8.47-36.4,20.17c-7.64-11.69-20.4-18.8-36.51-20.17c-3.71-0.15-3.98-0.15-4.09-0.14
          c-1.04,0.04-2.64,0.15-2.68,0.15c-27.28,2.33-44.22,20.97-44.22,48.65v63.15h15.55v-62.93c0-20.77,12.02-33.36,32.06-33.68
          c19.95,0.37,31.65,12.2,32.11,32.36c0,0.13-0.01,0.26-0.02,0.38c-0.01,0.24-0.03,0.48-0.03,0.72v60.58l0.09,2.57h15.55v-63.15
          c0-0.25-0.01-0.49-0.03-0.72c-0.01-0.13-0.01-0.25-0.02-0.28c0.47-20.32,12.17-32.15,32.02-32.47
          c20.44,0.38,32.16,12.66,32.16,33.68v62.93h15.55v-63.15C262.79,211.69,245.84,193.05,218.56,190.72"/>
        <path className="st3" fill={st3} d="M384.31,187.25c-31.85,0-56.8,25.88-56.8,58.92c0,33.98,24.78,58.65,58.92,58.65
          c19.25,0,35.84-7.48,46.71-21.07l1.53-1.91l-10.72-9.48l-1.69,2.08c-8.6,10.6-20.65,15.98-35.83,15.98
          c-24.58,0-41.23-15.44-43.06-39.57h96.38l0.04-2.53c0.29-18.13-5.63-34.29-16.68-45.52C413.1,192.62,399.68,187.25,384.31,187.25
          M343.87,237.24c3.58-21.89,19.19-35.87,40.44-35.87c25.95,0,37.3,18.04,39.5,35.87H343.87z"/>
        <path className="st2" fill={st2} d="M343.3,187.25c-32.49,0-58.92,26.31-58.92,58.65c0,32.48,26.43,58.91,58.92,58.91
          c15.83,0,30.58-6.16,41.55-17.34c10.86-11.07,16.84-25.84,16.84-41.58C401.69,213.01,376.04,187.25,343.3,187.25 M385.97,245.9
          c0,25.8-17.94,44.52-42.67,44.52c-25.03,0-43.2-18.72-43.2-44.52c0-25.64,18.17-44.25,43.2-44.25
          C368.42,201.64,385.97,219.84,385.97,245.9"/>
        <path className="st2" fill={st2} d="M539.94,189.63h-1.38c-2.7,0-5.14,1.6-6.21,4.09l-33.81,78.38c-0.25,0.56-0.5,1.16-0.75,1.78
          c-0.38,0.96-1.2,1.07-1.54,1.07h-0.01c-0.33,0-1.14-0.11-1.52-1.05c-0.25-0.63-0.51-1.23-0.76-1.78l-34.61-78.45
          c-1.08-2.45-3.51-4.03-6.19-4.03h-1.88c-2.31,0-4.43,1.16-5.68,3.1s-1.43,4.35-0.48,6.45l46.41,102.69
          c0.81,1.79,2.61,2.95,4.57,2.95c1.99,0,3.79-1.17,4.59-2.99l45.42-102.7c0.93-2.1,0.74-4.5-0.52-6.43
          C544.36,190.78,542.24,189.63,539.94,189.63"/>
        <path className="st2" fill={st2} d="M608.25,187.25c-31.85,0-56.8,25.88-56.8,58.92c0,33.98,24.78,58.65,58.92,58.65
          c19.25,0,35.84-7.48,46.71-21.07l1.53-1.91l-10.72-9.48l-1.69,2.08c-8.6,10.6-20.65,15.98-35.83,15.98
          c-24.58,0-41.23-15.44-43.06-39.57h96.38l0.04-2.53c0.29-18.13-5.63-34.29-16.68-45.52C637.03,192.62,623.61,187.25,608.25,187.25
          M567.81,237.24c3.58-21.89,19.19-35.87,40.44-35.87c25.95,0,37.3,18.04,39.5,35.87H567.81z"/>
        <path className="st2" fill={st2} d="M725.86,236.56c-21.34-6.4-25.94-10.01-25.94-20.29c0-9.05,7.82-14.89,19.91-14.89
          c13.01,0,20.35,8.07,24.22,14.84l1.3,2.28l12.32-7.39l-1.2-2.17c-7.97-14.4-20.29-21.7-36.64-21.7c-19.86,0-34.84,12.7-34.84,29.55
          c0,21.43,17.38,28.26,35.14,33.4c19.47,6.05,28.08,9.27,28.08,22.15c0,10.97-9.58,18.06-24.41,18.06
          c-15.34,0-25.82-6.61-32.05-20.2l-1.06-2.3l-13.62,6.01l0.97,2.33c7.48,17.88,24.59,28.56,45.76,28.56
          c24.22,0,39.87-12.85,39.87-32.73C763.67,249.67,744.99,242.24,725.86,236.56"/>
      </g>
      <g>
        <path className="st0" fill={st0} d="M219.16,371.57c0.87,1.34,1.21,2.61,1.21,3.75c0,1.94-1.47,3.42-3.89,3.42c-2.08,0-3.42-0.94-4.62-3.22
          l-7.51-12.8h-6.77v11.8c0,2.41-1.74,4.15-4.22,4.15c-2.41,0-4.16-1.74-4.16-4.15v-35.06c0-2.34,1.61-3.95,3.89-3.95h11.39
          c10.12,0,16.89,5.36,16.89,13.54c0,5.7-3.22,10.05-8.65,12.2L219.16,371.57z M205.15,355.28c4.42,0,7.31-2.48,7.31-6.23
          c0-3.69-2.88-6.1-7.31-6.1h-7.64v12.33H205.15z"/>
        <path className="st0" fill={st0} d="M254.29,335.51h20.44c2.35,0,3.95,1.61,3.95,3.95c0,2.35-1.61,3.89-3.95,3.89h-15.95v9.52h13.27
          c2.35,0,3.95,1.61,3.95,3.96c0,2.28-1.61,3.95-3.95,3.95h-13.27v9.79h15.95c2.35,0,3.95,1.61,3.95,4.02c0,2.28-1.61,3.89-3.95,3.89
          h-20.44c-2.28,0-3.89-1.68-3.89-3.95v-35.06C250.41,337.12,252.01,335.51,254.29,335.51z"/>
        <path className="st0" fill={st0} d="M340.9,378.68c-2.41,0.07-4.09-1.34-5.16-4.15l-2.28-5.7h-19.1l-2.35,5.77c-1.07,2.75-2.21,4.15-4.83,4.09
          c-3.08,0-4.56-2.61-3.08-6.17l13.34-31.84c1.61-3.89,3.28-5.43,6.7-5.43s5.09,1.54,6.7,5.43l13.27,31.7
          C345.66,376.06,344.05,378.68,340.9,378.68z M330.45,361.38l-6.57-16.36l-6.57,16.36H330.45z"/>
        <path className="st0" fill={st0} d="M376.84,335.44c2.48,0,4.22,1.74,4.22,4.22v30.9h16.56c2.35,0,3.96,1.61,3.96,4.02c0,2.28-1.61,3.89-3.96,3.89
          h-21.05c-2.28,0-3.89-1.68-3.89-3.95v-34.85C372.69,337.19,374.43,335.44,376.84,335.44z"/>
        <path className="st0" fill={st0} d="M479.75,374.52c0,2.41-1.74,4.15-4.22,4.15c-2.48,0-4.22-1.74-4.22-4.15v-31.17h-8.85
          c-2.28,0-3.89-1.54-3.89-3.96c0-2.28,1.61-3.89,3.89-3.89h26.14c2.28,0,3.89,1.61,3.89,3.95c0,2.35-1.61,3.89-3.89,3.89h-8.85
          V374.52z"/>
        <path className="st0" fill={st0} d="M522.79,335.31c2.48,0,4.22,1.74,4.22,4.15v35.06c0,2.41-1.74,4.15-4.22,4.15c-2.41,0-4.16-1.74-4.16-4.15
          v-35.06C518.63,337.05,520.38,335.31,522.79,335.31z"/>
        <path className="st0" fill={st0} d="M602.9,340.2v34.32c0,2.41-1.74,4.15-4.22,4.15c-2.41,0-4.16-1.74-4.16-4.15v-25.4l-9.99,15.95
          c-1.27,2.01-2.55,2.68-4.42,2.68c-1.81,0-3.15-0.67-4.43-2.68l-9.92-15.82v25.27c0,2.41-1.74,4.15-4.22,4.15
          c-2.41,0-4.16-1.74-4.16-4.15v-33.71c0-3.42,2.01-5.57,5.57-5.57c3.15,0,4.76,1.68,6.7,4.76l10.99,17.96l11.87-19.24
          c1.34-2.14,2.75-3.49,5.56-3.49C601.15,335.24,602.9,337.32,602.9,340.2z"/>
        <path className="st0" fill={st0} d="M637.16,335.51h20.44c2.35,0,3.96,1.61,3.96,3.95c0,2.35-1.61,3.89-3.96,3.89h-15.95v9.52h13.27
          c2.35,0,3.95,1.61,3.95,3.96c0,2.28-1.61,3.95-3.95,3.95h-13.27v9.79h15.95c2.35,0,3.96,1.61,3.96,4.02c0,2.28-1.61,3.89-3.96,3.89
          h-20.44c-2.28,0-3.89-1.68-3.89-3.95v-35.06C633.27,337.12,634.88,335.51,637.16,335.51z"/>
      </g>
    </svg>
  )
}
