import React, {useState, useContext, createContext} from 'react';

const osTheme = process.env.REACT_APP_THEME
  , {'theme': asgard} = require('./asgard')
  , {'theme': moeves} = require('./moeves')
  , themes = {
      'light': 'light',
      'dark': 'dark'
    }
  , {light, dark} = themes
  , appThemes = {
      'asgard': asgard(themes),
      'moeves': moeves(themes)
    }
  , getTheme = mode => {
      if(![light, dark].includes(mode)){
        mode = light
      }

      const theme = {};
      for (let key in appThemes[process.env.REACT_APP_TENANT]) {
        theme[key] = appThemes[process.env.REACT_APP_TENANT][key][mode];
      }
      return theme;
    };

export const ManageThemeContext = createContext({
  'mode': osTheme,
  'osTheme': 'light',
  'theme': getTheme(osTheme),
  'toggle': () => {},
});

export const useTheme = () => useContext(ManageThemeContext);

export const ThemeManagerController = ({userTheme, children}) => {
  const [mode, updateMode] = useState(userTheme || osTheme);

  const toggleTheme = async () => {
    mode === light ? updateMode(dark) : updateMode(light);
  };

  return (
    <ManageThemeContext.Provider
      value={{
        mode,
        'theme': getTheme(mode),
        'osTheme': 'light',
        'toggle': toggleTheme,
      }}>
      {children}
    </ManageThemeContext.Provider>
  );
};

export default ThemeManagerController;

export const staticTheme = getTheme(osTheme)
