import login from './login';
import profile from './profile';
import errors from './errors';
import tncs from './tncs';
import travel from './travel';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'translation': {
        'general': {
            'ok': 'Ok',
            'yes': 'Yes',
            'no': 'No',
            'edit': 'Edit',
            'next': 'Next',
            'back': 'Back',
            'continue': 'Continue',
            'confirm': 'Confirm',
            'booking': 'Book',
            'verify': 'Verify',
            'share': 'Share',
            'notShare': 'Not Share',
            'or': 'or',
            'add': 'Add',
            'addNow': 'Add now',
            'later': 'I\'ll do it later',
            'cancel': 'Cancel',
            'delete': 'Delete',
            'close': 'Close',
            'nextTime': 'Ask me next time',
            'search': 'Search',
            'preferred': 'Set as default options',
            'notNow': 'Not now',
            'settings': 'Settings',
            'send': 'Send',
            'save': 'Save',
            'advise': 'Advise',
            'date': 'Date',
            'time': 'Time',
            'arrival': 'Arrival',
            'departure': 'Departure',
            'name': 'Name',
            'passengers': {
                'adults': 'Adults',
                'childs': 'Childs',
                'disabled': 'Disabled people with reduced or impaired motor skills'
            },
            'time': {
                'time': 'Time',
                'today': 'Today',
                'tomorrow': 'Tomorrow',
                'yesterday': 'Yesterday',
                'pass': '{{days}} days ago',
                'future': 'In {{days}} days',
                'notice': 'Estimated time calculated in normal traffic conditions'
            },
            'points': {
                'notice': 'Attention, the starting and / or arrival points of the solution found may differ from those sought'
            },
            'fleets': {
                'door_to_door': 'Door to Door',
                'point_to_point': 'Point to Point',
                'ecommerce': 'Ecommerce'
            },
            'units': {
                'minutes': 'min'
            },
            'canceled': 'Canceled',
            'expired': 'Expired',
            'position': 'Current Position',
            'backToHome': 'Back to Home',
            'version': 'Version {{versionNumber}}'
        },

        'validation': {
            'methods': {
                'qr': 'QR Code',
                'nfc': 'NFC',
                'code': 'Code'
            }
        },

        'languages': {
            'it': 'Italiano',
            'en': 'English'
        },

        'menu': {
            'settings' : 'Settings',
            'logout' : 'Logout',
            'login' : 'Login',
            'travel' : 'Book travel',
            'mytickets' : 'My travels',
            'info' : 'Info',
            'support' : 'Help',
            'help' : 'How does it work',
            'terms' : 'Terms and Conditions',
            'privacy' : 'Privacy Policy'
        },

        'toBar': {
            'travel': 'Your Journey',
            'login': 'Sign in',
            'register': 'Sign up',
            'cart': 'Overview',
            'payment': 'payment',
            'userdata': 'User data',
            'tncs': 'Terms and Conditions',
            'mytickets': 'My Journeys'
        },

        login,
        profile,
        errors,
        tncs,

        travel
    }
}
