const {'here': hereUtils} = require('./here')
, {'tomtom': tomtomUtils} = require('./tomtom')
, {'geocodeEarth': geocodeUtils} = require('./geocode-earth')
, {'googleMaps': googleMapsUtils} = require('./google-maps')
 , createPlaceObj = (id, name, lat, lng, extra = {}) => {
    const cleanName = name.replace(/<\/?[^>]+(>|$)/g, '')
      , latitude = parseFloat(lat)
      , longitude = parseFloat(lng)
      , {
        municipality = cleanName,
        fullAddress = cleanName,
        address = cleanName,
        placeId = '',
        locality = '',
        postalCode = '',
        route = '',
        streetNumber = '',
        province = '',
        region = '',
        country = '',
        altitude = -1,
        altitudeAccuracy = -1,
        accuracy = -1,
        heading = -1,
        speed = -1
      } = extra

    return {
      'type': 'Location',
      'itemId': id,
      'geometry': {
        'type': 'Point',
        'coordinates': [longitude, latitude]
      },
      'properties': {
        'name': cleanName,
        placeId,
        locality,
        route,
        streetNumber,
        municipality,
        fullAddress,
        address,
        postalCode,
        province,
        region,
        country,
        altitude,
        altitudeAccuracy,
        latitude,
        accuracy,
        longitude,
        heading,
        speed,
      }
    };
  };

export const Geolocation = (deps) => {
  const {manageACallback, contexts} = deps
    , {device} = contexts
    , geocoders = {
        'here': hereUtils({...deps, createPlaceObj}),
        'tomtom': tomtomUtils({...deps, createPlaceObj}),
        'geocode-earth': geocodeUtils({...deps, createPlaceObj}),
        'google-maps': googleMapsUtils({...deps, createPlaceObj})
      }
    , getGeocoderFromArea = areaId => {
        const theArea = device.tenant.areas.features.find(a => a?.properties?.id === areaId)
          , {'geocoder': geocoderConfig} = theArea?.properties?.confs
          , geocoder = geocoders[geocoderConfig?.provider ?? 'here'];

        return {
          geocoder,
          'config': geocoderConfig
        }
      }

  const search = (areaId, args, callback) => {
      const {geocoder, 'config': geocoderConfig} = getGeocoderFromArea(areaId);
      geocoder.search({...args, 'getUserLocation': get, geocoderConfig}, callback)
    }
  , reverseGeocode = (areaId, lat, lng, callback) => {
      const {geocoder, 'config': geocoderConfig} = getGeocoderFromArea(areaId);
      geocoder.reverseGeocode(lat, lng, geocoderConfig, callback)}
  , get = async (callback) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          const {'latitude': lat, 'longitude': lng} = position.coords
          , result = await reverseGeocode(lat, lng);
          return manageACallback(result, callback)
        }, error => {
          manageACallback(undefined, callback)
        });
      } else {
        manageACallback(undefined, callback)
      }
    }

  return {
    get,
    search,
    reverseGeocode
  }
}
