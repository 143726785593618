import React from 'react';
import { observer } from 'mobx-react-lite'
import { useHistory } from "react-router-dom"

import FormRegister from '../components/FormRegister'

export const Register = observer((deps) => {
  const {
    components,
    contexts,
    utils
  } = deps
  const history = useHistory()

  return (
    <div className="p-register">
      <FormRegister
        components={components}
        contexts={contexts}
        utils={utils}
        onComplete={() => history.push('/login')}
      />
    </div>
  )
})
