import React, {useState, useContext, useEffect} from 'react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import {
  useParams,
  useHistory,
  useLocation,
  generatePath
} from "react-router-dom"
import {
  Button,
  ButtonGroup,
  Container
} from "react-bootstrap"
import { AlertContext } from '../context/alert'
import RouteBox from '../components/RouteBox'

const { v4: uuidv4 } = require('uuid');

const ACTIVE = 'active'
const PASSED = 'passed'
const CANCELED = 'canceled'

const tabs = [ACTIVE, PASSED, CANCELED]

const midnight = date => moment(date).hours(0).minutes(0).seconds(0).milliseconds(0)

const timeCategory = (date, translate) => {
  const midnightToday = midnight(new Date())
  const midnightTicket = midnight(date)
  const diff = midnightTicket.diff(midnightToday, 'days')

  if(diff === 0){
    return translate('general.time.today');
  }

  if(diff === 1){
    return translate('general.time.tomorrow');
  }

  if(diff === -1){
    return translate('general.time.yesterday');
  }

  if(diff < -1){
    return translate('general.time.pass', {'days': diff * -1})
  }

  return translate('general.time.future', {'days': diff})
}

export const Tickets = observer(({
  componentId,
  contexts,
  components,
  utils,
  config
}) => {
  const alert = useContext(AlertContext)
  const history = useHistory()

  const {AuthContext} = contexts
  const {loading} = components
  const {translate, Api, libs} = utils
  const {icons} = libs

  const {tickets, language, user} = useContext(AuthContext)
  const [expiredTickets, setExpiredTickets] = useState([])
  const [refundedTickets, setRefundedTickets] = useState([])
  const [currentTab, setCurrentTab] = useState(0)
  const [theCurrentTicketsArray, setTheCurrentTicketsArray] = useState([])
  const [theTickets, setTheTickets] = useState([])

  useEffect(() => {
    loading.set(true);

    const ticketsCollection = tabs[currentTab] === ACTIVE ? tickets.filter(t => t.refunded === false)
      : tabs[currentTab] === CANCELED ? refundedTickets
      : expiredTickets

    setTheCurrentTicketsArray(ticketsCollection);
    loading.set(false);
  }, [user, tickets, tickets.length, currentTab, expiredTickets, refundedTickets])

  useEffect(() => {
    if(tabs[currentTab] === PASSED || tabs[currentTab] === CANCELED ){
      getTickets()
    }

  }, [currentTab])

  useEffect(() => {
    const theTickets = {}

    theCurrentTicketsArray.forEach(t => {
      const time = timeCategory(t.startDate, translate);

      console.log(time)

      if(!theTickets[time]){
        theTickets[time] = [];
      }

      theTickets[time].push(t);
    })

    setTheTickets(theTickets)
  }, [theCurrentTicketsArray])

  useEffect(() => {
    getTickets()
  }, [])

  const getTickets = async() => {
    loading.set(true);

    const params = {};

    if(tabs[currentTab] === PASSED){
      params.expired = true;
    }

    if(tabs[currentTab] === CANCELED){
      params.refunded = true;
    }


    const response = await Api.fetch(
      Api.clean(Api?.urls?.drt?.bookings?.url),
      {
        'method': 'GET',
        params
      });

    console.log('getTickets', params, response, tabs[currentTab])

    loading.set(false);

    if(!response.err){
      const theTicketsToReturn = response.sort((a,b) => {
        const AendDate = new Date(a.endDate).getTime()
        const BendDate = new Date(b.endDate).getTime()

        if (AendDate < BendDate) {
          return 1;
        }
        if (AendDate > BendDate) {
          return -1;
        }
        // a deve essere uguale a b
        return 0;
      })

      if(tabs[currentTab] === PASSED){
        setExpiredTickets(theTicketsToReturn)
      }

      if(tabs[currentTab] === CANCELED){
        setRefundedTickets(theTicketsToReturn)
      }
    }
  }

  const selectRoute = theRoute => history.push(generatePath('/my-tickets/:id', { id: theRoute.id }), {
    'ticket': tabs[currentTab] === PASSED || tabs[currentTab] === CANCELED ? theRoute : theRoute.id,
    'purchased': true,
    'expired': tabs[currentTab] === PASSED || tabs[currentTab] === CANCELED
  })

  return (
    <div className="p-tickets">
      <Container className="is-small">
        <ButtonGroup
          className="mb-5"
          style={{ display: 'flex' }}
        >
          {
            tabs.map((o, index) => (
              <Button
                key={`tabs.${o}`}
                variant="primary"
                active={currentTab === index}
                onClick={() => setCurrentTab(index)}
              >
                {translate(`profile.mytickets.tabs.${o}`)}
              </Button>
            ))
          }
        </ButtonGroup>

        {
          theCurrentTicketsArray.length > 0 &&
            Object.entries(theTickets).map(
              ([key, value]) => <div key={uuidv4()} className="mb-5">
                <h5 type="subtitle">{key}</h5>
                {
                  value.map(r =>
                    <RouteBox
                      key={uuidv4()}
                      icons={icons}
                      translate={translate}
                      onClick={() => selectRoute(r)}
                      route={r}
                      discount={{
                        'amount': r.amount,
                        'voucherDescription': r.cartMeta?.discounts[0]?.description[language]?.description ??
                            r.cartMeta?.discounts[0]?.description?.it?.description
                      }}
                      timeClearance={r?.area?.properties?.confs?.travelsSearchConfiguration?.timeClearance}
                  />)
                }
              </div>
            )
        }

        {
          theCurrentTicketsArray.length === 0 &&
            <p className="text-center">{translate('profile.mytickets.empty')}</p>
        }
      </Container>
    </div>
  );
})
