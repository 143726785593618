import React, { useState, useEffect } from 'react'
import moment from 'moment'
import {
  Badge,
  Row,
  Col
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faRoute } from '@fortawesome/free-solid-svg-icons'

const { v4: uuidv4 } = require('uuid');

const RouteBoxHeader = ({
  className,
  route,
  translate,
  select = false,
  discount
}) => {
  const [referencePassenger, setReferencePassenger] = useState(null)
  const [travelDate, setTravelDate] = useState(null)
  const [fleetName, setFleetName] = useState('')
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('')
  const [passengers, setPassengers] = useState([])

  useEffect(() => {
    if (route) {
      const {fleetName, meta, cartMeta} = route
      const {amount, currency, passengers = [], startDate} = meta
      const travelDate = moment(startDate).format('DD/MM/YYYY')

      let reference = '';
      if(cartMeta?.referencePassenger){
        reference = <>{`${cartMeta?.referencePassenger?.name}`}<br/><small className="text-muted">({`${cartMeta?.referencePassenger?.phone}`})</small></>;
      }

      setReferencePassenger(reference)
      setTravelDate(travelDate)
      setFleetName(fleetName)
      setAmount(amount)
      setCurrency(currency)
      setPassengers(passengers)
    }
  }, [route])

  const parseAmount = a => (a/100).toFixed(2)

  return (
    <div className={className}>
      <Row>
        <Col xs={6}>
          <div className="text-left">
            <Badge variant="info">
              <FontAwesomeIcon icon={faRoute} className="mr-2" />
              {translate(`general.fleets.${fleetName}`)}
            </Badge>
          </div>
        </Col>
        <Col xs={6}>
          <div className="text-right">
            {
              select === true ? <span className="text-secondary">
                {translate('travel.booking.details')}
                <FontAwesomeIcon icon={faChevronRight} className="ml-2" />
              </span> : <>
                <small className="text-muted">{travelDate}</small>
              </>
            }
            {
              passengers.map(
                ({id, value, 'amount': passengerAmount}) => <div key={uuidv4()}>
                  <small className="text-muted">
                    {`${value} ${translate(`general.passengers.${id}`)} x ${currency} ${parseAmount(passengerAmount)}`}
                  </small>
              </div>)
            }
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <div className="text-left">
            {
              discount?.voucherDescription &&
                <p>{discount?.voucherDescription}</p>
            }
            {
              referencePassenger &&
                <p>{referencePassenger}</p>
            }
          </div>
        </Col>
        <Col xs={6}>
          <div className="text-right">
            {
              discount && discount.amount !== amount ? <>
                <div><del>{`${parseAmount(amount)} ${currency}`}</del></div>
                <div className="text-secondary">{`${parseAmount(discount.amount)} ${currency}`}</div>
              </> : <>
                <div className="text-secondary">{`${parseAmount(amount)} ${currency}`}</div>
              </>
            }
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default RouteBoxHeader
