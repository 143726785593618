import React from 'react'
import moment from 'moment'
import {
  Card,
  Row,
  Col
} from 'react-bootstrap'

import RouteBoxHeader from './RouteBoxHeader'

const RouteBox = ({
  route,
  onClick,
  translate,
  discount,
  timeClearance
}) => {
  const {meta} = route
  const {startDate, endDate, departureDescription, arrivalDescription, duration, stops } = meta
  const durationInMinutes = (duration / 1000 / 60).toFixed(0)


  let startTime = moment(startDate).format('kk:mm')
    , endTime = moment(endDate).format('kk:mm')

  if(timeClearance > 0){
    startTime = `${moment(startDate).add(-timeClearance, 'm').format('kk:mm')} - ${moment(startDate).add(timeClearance, 'm').format('kk:mm')}`;
    endTime = `${moment(endDate).add(-timeClearance, 'm').format('kk:mm')} - ${moment(endDate).add(timeClearance, 'm').format('kk:mm')}`;
  }

  return (
    <div className="c-routeBox" onClick={onClick}>
      <Card>
        <Card.Header>
          <RouteBoxHeader
            className="c-routeBox__header"
            route={route}
            select={true}
            translate={translate}
            discount={discount}
          />
        </Card.Header>
        <Card.Body className="c-routeBox__body">
          <Row>
            <Col xs={6} className="text-left">
              <p className="text-primary mb-1">{startTime}</p>
              <small>{departureDescription}</small>
            </Col>
            <Col xs={6} className="text-right">
              <p className="text-primary mb-1">{endTime}</p>
              <small>{arrivalDescription}</small>
            </Col>
          </Row>
          <br />
          <small>{translate('general.time.notice')}</small>
        </Card.Body>
        <Card.Footer className="c-routeBox__footer">
          <span>{translate('travel.journey.duration')}: {durationInMinutes} {translate('general.units.minutes')}</span>
          <span>{moment(startDate).format('DD/MM/YYYY')}</span>
        </Card.Footer>
      </Card>
    </div>
  )
}

export default RouteBox
