// counter.store.js
import React from 'react';
import detectBrowserLanguage from 'detect-browser-language'
import {observable, action, makeObservable, runInAction} from 'mobx';
import { persistence, clearPersist, StorageAdapter } from 'mobx-persist-store';
import Mqtt from 'mqtt/dist/mqtt';
import moment from 'moment';
import {momentLocale} from '../i18n/moment-locale'

// const navigation = require('../lib/navigation');

const {LibraryStaticUtils} = require('../lib')
  , {log} = LibraryStaticUtils;

const readStore = async (name) => {
  return new Promise((resolve) => {
    try {
      const data = window.localStorage.getItem(name);
      resolve(data);
    }
    catch (e){
      log("readStore throw an error", e);
      resolve();
      return undefined;
    }
  });
}

function writeStore(name, content) {
  return new Promise((resolve) => {
    try {
      window.localStorage.setItem(name, content);
      resolve();
    }
    catch (e){
      log("writeStore throw an error", e);
      resolve();
    }
  });
}
class Auth {
  isLoggedIn = false
  token = undefined
  mqttData = undefined
  mqttDataAnonymous = undefined
  user = {}
  language = 'it'
  currentTravelResponse = undefined
  tickets = []
  firebaseNotificationToken = undefined
  mqttClient = undefined
  needTncsCheck = false

  constructor() {
    makeObservable(this, {
      'isLoggedIn': observable,
      'token': observable,
      'mqttData': observable,
      'mqttDataAnonymous': observable,
      'user': observable,
      'language': observable,
      'currentTravelResponse': observable,
      'tickets': observable,
      'firebaseNotificationToken': observable,
      'needTncsCheck': observable,

      'anonymousLogin': action.bound,
      'login': action.bound,
      'logout': action.bound,
      'setUser': action.bound,
      'setLanguage': action.bound,
      'setCurrentTravelResponse': action.bound,
      'manageTncs': action.bound,
      'manageABooking': action.bound,
      'mqttInit': action.bound,
      'setFirebaseNotificationToken': action.bound,
      'validateTncs': action.bound,
    });

    log("mqttlog XXX", this.mqttClient, this.mqttData)

    const browserLang = detectBrowserLanguage()
    this.language = browserLang.split('-')[0]

    moment.locale(this.language, momentLocale[this.language]);
  }

  anonymousLogin(Api) {
    console.log('anonymousLogin attempt');
    const self = this
      , data = {};

    Api.fetch(
      Api.clean(Api.urls?.drt?.users?.anonymous?.url),
      {
        'method': 'POST',
        'body': { data }
      }, (res) => {
        console.log('anonymous_login', res);
        const {token, mqttClientIdentifier, email} = res;

        runInAction(() => {
          if(token){
            self.isLoggedIn = false;
            self.needTncsCheck = false;
            self.token = token;
            self.anonymousMqttData = {mqttClientIdentifier, email};

            self.mqttInit(Api);
          }
          else {
            setTimeout(() => {
              self.logout(Api);
            }, 1000)
          }
        })
      });
  }

  login(Api, data, type, callback) {
    console.log('login attempt');
    const self = this;

    Api.fetch(
      Api.clean(Api.urls?.drt?.users?.signin?.url),
      {
        'method': 'POST',
        'body': { data, type }
      }, (res) => {
      console.log(`login_${type}`, res);
      const {token, mqttClientIdentifier, email} = res;

      runInAction(() => {
        if(token){
          self.isLoggedIn = true;
          self.token = token;
          self.mqttData = {mqttClientIdentifier, email};

          self.mqttInit(Api);

          if(callback){
            callback(true, res)
          }
        }
        else {
          self.logout(Api);

          if(callback){
            callback(false, res)
          }
        }
      })
    });
  }

  logout(Api, callback) {
    console.log('logout attempt');
    Api.fetch(Api.urls?.drt?.users?.signout.url, {'method': 'PUT'}, async (res) => {
      runInAction(() => {
        this.isLoggedIn = false;
        this.needTncsCheck = false;
        this.token = undefined;
        this.mqttData = undefined;
        this.tickets = [];
        this.user = undefined;

        if(this.mqttClient){
          this.mqttClient.end();
          this.mqttClient = undefined;
        }
      });

      clearPersist(this)

      if(callback){
        callback(true)
      }
    })
  }

  setUser(user) {
    this.user = user;
  }

  setLanguage(language) {
    this.language = language;
    moment.locale(language, momentLocale[language]);
  }

  setCurrentTravelResponse(travel) {
    console.log('set currentTravelResponse', travel)
    this.currentTravelResponse = travel;
  }

  setFirebaseNotificationToken(token){
    this.firebaseNotificationToken = token;
  }

  manageTncs(tncs = []){
    this.needTncsCheck = tncs.length > 0

    // if(tncs.length > 0){
    //   const {staticTheme} = require('../theme');
    //   navigation.dismissModal('tncModal');
    //   navigation.modal(staticTheme, 'Tncs', {'blocked': true, 'setId': 'tncModal', 'passProps': {tncs}});
    // }
  }

  validateTncs() {
    const self = this;
    runInAction(() => {
      self.needTncsCheck = false
    })
  }

  verifyPhoneNumber(Api, translate, alreadyOpen = false){
    const sendVerificationCode = async() => {
      const response = await Api.fetch(
        Api.clean(Api?.urls?.drt?.users?.phone?.code?.url), {
          'method': 'GET',
        }
      );

      if(response.errMsg?.message === 'users.phone.alreadyVerified'){
        // navigation.dismissModal('verifyPhoneModal');
        return false;
      }

      if(!alreadyOpen){
        // const {staticTheme} = require('../theme');
        // navigation.dismissModal('verifyPhoneModal');
        // navigation.modal(staticTheme, 'VerifyPhone', {translate, 'setId': 'verifyPhoneModal'});
      }

      return true;
    }

    return sendVerificationCode();
  }

  manageABooking(type, ticket){
    const self = this
      , Types = {
        'GET': 0,
        'UPDATE': 1,
        'DELETE': 2,
      };

    const index = self.tickets.findIndex((i) => i.id === ticket.id);
    console.log("manageABooking", type, ticket.id, index);

    switch (type) {
      case Types.GET:
      case Types.UPDATE:
        if(index !== -1){
          self.tickets[index] = ticket;
        }
        else {
          self.tickets.push(ticket);
        }
        break;
      case Types.DELETE:
        if(index !== -1){
          self.tickets.splice(index, 1);
        }
        break;
    }

    self.tickets.sort((a,b) => {
      const Adate = new Date(a.startDate).getTime()
        , Bdate = new Date(b.startDate).getTime();

      if (Adate < Bdate) {
        return -1;
      }
      if (Adate > Bdate) {
        return 1;
      }
      return 0;
    })

    console.log("end manageABooking");
  }

  mqttInit(Api) {
    console.log("mqtt.init")
    const self = this;

    let theMqttData = this.mqttData;
    let theMqttUniqueId = Math.random().toString(16).substr(2, 8)

    if (!self.mqttClient && theMqttData) {
      console.log("mqtt.start")
			const {mqttClientIdentifier, email} = theMqttData
      const tenant = process.env.REACT_APP_TENANT
      const mqttUrl = process.env.REACT_APP_MQTT
        , clientId = `${tenant}_0.0_${mqttClientIdentifier}_${theMqttUniqueId}`

      self.mqttClient = Mqtt.connect(mqttUrl, {
        clientId,
        'username': email,
        'password': self.token,
      });

      self.mqttClient.on('connect', () => {
        console.log('mqttlog.connect');


        self.mqttClient.subscribe(`${mqttClientIdentifier}/users/+`, 2);
        self.mqttClient.subscribe(`${mqttClientIdentifier}/tncs`, 2);
        self.mqttClient.subscribe(`${mqttClientIdentifier}/travels/+`, 2);
        self.mqttClient.subscribe(`${mqttClientIdentifier}/bookings/+`, 2);
      });

      self.mqttClient.on('error', msg => {
        console.log('mqttlog.error', msg);

        const {code} = msg;

        if(code === 5){ //Not authorized
          self.logout(Api);
        }
      });

      self.mqttClient.on('disconnect', msg => {
        console.log('mqttlog.disconnect', msg);
        runInAction(() => {
          self.tickets = [];
          self.user = {};
        });
      });

      self.mqttClient.on('reconnect', msg => {
        console.log('mqttlog.reconnect', msg);
      });

      self.mqttClient.on('close', msg => {
        console.log('mqttlog.close', msg);
      });

      self.mqttClient.on('offline', msg => {
        console.log('mqttlog.offline', msg);
      });

      self.mqttClient.on('message', (topic, message) => {
        console.log('mqttlog.message');
        const jsonData = JSON.parse(message.toString('utf-8'));
        console.log('mqttlog', topic, jsonData);

				if(topic.includes(`${mqttClientIdentifier}/users/`)){
          const {profile = {}}  = jsonData.data
            , {lang} = profile;

          self.setLanguage(lang);
          self.setUser({ profile });
        }
        else if (topic.includes(`${mqttClientIdentifier}/tncs`)) {
          const {'data': tncs}  = jsonData;
          self.manageTncs(tncs);
        }
        else if (topic.includes(`${mqttClientIdentifier}/travels`)) {
          const {type, 'data': aTravel}  = jsonData;
          self.setCurrentTravelResponse({
            'travel': {
              ...aTravel,
              'responseStatus': type === 0
            }
          });
        }
        else if (topic.includes(`${mqttClientIdentifier}/bookings`)) {
          const {type, 'data': aBooking}  = jsonData;
          self.manageABooking(type, aBooking);
        }
      });
    }
  }
}

// Instantiate the counter store.
export const auth = persistence({
  name: 'authStore',
  properties: ['isLoggedIn', 'token', 'mqttData', 'mqttDataAnonymous', 'currentTravelResponse', 'needTncsCheck'],
  adapter: new StorageAdapter({
    read: readStore,
    write: writeStore,
  }),
  reactionOptions: {
    // optional
    delay: 200,
  },
})(new Auth());

// Create a React Context with the counter store instance.
export const AuthContext = React.createContext(auth);
export const useAuth = () => React.useContext(AuthContext);
