import React from 'react'
import { useHistory } from "react-router-dom"
import {useTranslation} from 'react-i18next'

import {
  Button,
  Container
} from 'react-bootstrap'

export const Error404 = () => {
  const history = useHistory()
  const {'t': translate} = useTranslation()

  return (
    <section className="p-404">
      <Container>
        <div className="text-center">
          <br/>
          <h2>{translate('errors.pageNotFound')}</h2>
          <br/>
          <Button onClick={() => history.replace('/')}>
            {translate('general.backToHome')}
          </Button>
        </div>
      </Container>
    </section>
  )
}
