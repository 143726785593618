import React, {useState, useEffect} from 'react'
import {
  Button,
  Modal
} from 'react-bootstrap'

import FormLogin from './FormLogin'
import FormForgotPassword from './FormForgotPassword'
import FormRegister from './FormRegister'

const ModalAuth = ({
  show,
  contexts,
  components,
  utils,
  onClose,
  onLogin
}) => {
  const [view, setView] = useState('login')

  return (
    <Modal
      show={show}
      onHide={onClose}
      size="lg"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        {
          view === 'login' &&
            <FormLogin
              contexts={contexts}
              components={components}
              utils={utils}
              onLogin={onLogin}
              onForgotPasswordButtonClick={() => setView('forgot-password')}
              onSignUpButtonClick={() => setView('register')}
            />
        }

        {
          view === 'forgot-password' &&
            <FormForgotPassword
              utils={utils}
              onComplete={() => setView('login')}
              onBackLogin={() => setView('login')}
            />
        }

        {
          view === 'register' &&
            <FormRegister
              components={components}
              contexts={contexts}
              utils={utils}
              onComplete={() => setView('login')}
              onBackLogin={() => setView('login')}
            />
        }
      </Modal.Body>
    </Modal>
  )
}

export default ModalAuth
