import axios from 'axios';

const querySerialize = (obj) => {
    let str = '?' + Object.keys(obj).reduce(function(a, k){
        a.push(k + '=' + encodeURIComponent(obj[k]));
        return a;
    }, []).join('&');
    return str;
  }
, removeHtmlRegex = text => text.replace(/(<([^>]+)>)/ig, ' ').replace(/\s\s+/g, ' ')
, returnedObject = obj => {

}

export const here = ({contexts, Api, createPlaceObj, manageACallback}) => {

  return {
    'parsePlace': item => createPlaceObj(item.id, item.title, item.position[0], item.position[1], item),
    'reverseGeocode': async(lat, lng, geocoderConfig, callback) => {
      const {urls, config} = geocoderConfig

        try {
          const params = {
                'at':`${lat},${lng}`,
                'apiKey': config.apiKey,
                'jsonattributes': 1,
                'gen': 9,
                'level': 'city'
              }
            , response = await axios.get(`${urls.reverse}${querySerialize(params)}`, {
                'method': 'GET',
                'headers': {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                }
              });

          const [item] = response?.data?.items;

          if (item) {
            let found = false
              , myPlace = undefined;

            // result.forEach((item) => {
              const {address, id, title} = item;
              if (!found) {
              found = true;

              const {city, district, label, postalCode
                , street, houseNumber = '', county, state
                , country} = address
                , details = `${postalCode} ${city} ${state}`

              myPlace = {
                'placeId': id,
                'address': `${street} ${houseNumber}`,
                details,
                'latitude': lat,
                'longitude': lng,
                'fullAddress': `${street} ${houseNumber}, ${details}`
              };
            }
          // });
          return manageACallback(myPlace, callback);
        }
        }
        catch (e){
          console.log(e)
        }

    },
    'search': async({text, bbox, center, geocoderConfig, /*getUserLocation*/}, callback) => {
      const {auth} = contexts
        , {user} = auth
        , {language = 'it'} = user
        , {urls, 'config': hereConfig} = geocoderConfig
        , {appId, appCode} = hereConfig

        // getUserLocation(async loc => {
          const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Accept-Language': `${language},${language};q=0.9,en-US;q=0.8,en;q=0.7`, //Language format IT, EN
          }

          if(center?.coordinates?.length > 0){
            const [centerLongitude, centerLatitude] = center.coordinates
            headers.Geolocation = `geo:${centerLatitude},${centerLongitude}`
          }

          const params = {
            'app_id': appId,
            'app_code': appCode,
            'prettry': true,
            'recd': true,
            'result_types': 'address',
            'q': text
          }

          if(bbox != null && bbox.length === 4){
            params.in = `${bbox.join(',')}`
          } 
          


          console.log(headers, params);

          const response = await fetch(`${urls.autosuggest}${querySerialize(params)}`, {
              'method': 'GET',
              headers
            })

          const json = await response.json()
          , results = json?.results?.map(item => {
              const {id, title, vicinity, highlightedTitle, highlightedVicinity, position} = item
                , theTitle = removeHtmlRegex(title)
                , theVicinity = removeHtmlRegex(vicinity)
                , theHighlightedTitle = removeHtmlRegex(highlightedTitle)
                , theHighlightedVicinity = removeHtmlRegex(highlightedVicinity)
                , [latitude, longitude] = position

              return {
                'placeId': id,
                'address': theHighlightedTitle,
                'details': theVicinity,
                'fullAddress': `${theTitle}, ${theVicinity}`,
                latitude,
                longitude
              };
          })

          return manageACallback(results, callback);
      // });
    },
    'parseCategory': (category) => {
      var icon = 'store';
      if (!category || category.indexOf('shop') !== -1) {
        return icon;
      }

      if (category.indexOf('sports') !== -1) {
        return 'basketball-ball';
      }

      if (category.indexOf('education') !== -1) {
        return 'school';
      }

      if (category.indexOf('hospital') !== -1) {
        return 'hospital';
      }

      switch (category) {
        case 'landmark-attraction': icon = 'chess-rook'; break;
        case 'bar-pub': icon = 'gas-pump'; break;
        case 'theatre-music-culture': icon = 'theater-masks'; break;
        case 'coffee-tea': icon = 'coffee'; break;
        case 'hotel':
        case 'accommodation': icon = 'hotel'; break;
        case 'wine-and-liquor': icon = 'wine-glass'; break;
        case 'food-drink':
        case 'restaurant': icon = 'utensils'; break;
        case 'public-transport': icon = 'bus'; break;
        case 'railway-station': icon = 'train'; break;
        case 'street-square': icon = 'road'; break;
        case 'building': icon = 'building'; break;
        case 'government-community-facility':
        case 'museum':
        case 'religious-place':
        case 'sights-museums': icon = 'university'; break;
        case 'city-town-village': icon = 'city'; break;
        case 'airport': icon = 'plane-arrival'; break;
        default: icon = 'map-marker';
      }

      return icon;
    },
  }
}
