const {log} = require('./log')
const {device} = require('./device')
const {manageACallback} = require('./callback')
const {time} = require('./time')
const icons = require('./icons')
const {Geolocation} = require('./geolocation')

export const utilities = (deps) => {
	const theDeps = {
			...deps,
			log,
			manageACallback
		}

	return {
		log,
		manageACallback,
		device,
		time,
		icons,
		'geolocation': Geolocation(theDeps)
	}
}

export const staticUtilities = {
	log,
	manageACallback,
	device,
	time,
	icons
}
