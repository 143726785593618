import React, {useState, useEffect, useContext} from 'react'

import { useHistory } from 'react-router-dom'

import {
  Button,
  Container,
  Form
} from 'react-bootstrap'

import { observer } from 'mobx-react-lite'
import { AlertContext } from '../context/alert'

export const ProfileUserData = observer(({
  componentId,
  contexts,
  components,
  utils,
  config,
  insideModal
}) => {
  const history = useHistory()
  const alert = useContext(AlertContext)

  const {AuthContext, DeviceContext} = contexts
  const {loading} = components
  const {translate, Api} = utils

  const {isLoggedIn, user, 'setLanguage':setUserLanguage, setUser} = useContext(AuthContext)
	const {'settings': settingsConfig} = useContext(DeviceContext)
  const {profile} = user

    // , {showActionSheetWithOptions} = useActionSheet()
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
    //const [language, setLanguage] = useState('it')

  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [number, setNumber] = useState('')
  const [city, setCity] = useState('')
  const [country, setProvince] = useState('')
  const [zipcode, setZipcode] = useState('')


  const updateData = () => {
    const data = {
      name,
      surname,
    };

    if (address) {
      data.address = address;
    }
    if (phone) {
      data.cellphone = phone;
    }
    if (number) {
      data.number = number;
    }
    if (city) {
      data.city = city;
    }
    if (country) {
      data.country = country;
    }
    if (zipcode) {
      data.zipcode = zipcode;
    }

    if(settingsConfig?.user?.phone != null && !(new RegExp(settingsConfig?.user?.phone)).test(phone.replace(/\s/g, ''))){
      loading.set(false)

      alert.error(translate('errors.phone'), {
        title: translate('errors.title'),
        closeCopy: translate('general.ok')
      })

      return
    }

    save(data);
  }

  const save = (data) => {
    loading.set(true);

    Api.fetch(
      Api.clean(Api?.urls?.drt?.users?.update?.url),
      {
        'method': 'POST',
        'body': data
      }, (res) => {
      loading.set(false);
      if (res.err) {
        console.log(res.err);

        alert.error(translate('errors.general'), {
          title: translate('errors.title'),
          closeCopy: translate('general.ok')
        })
      } else {
        history.push('/')
      }
    });
  };

  useEffect(() => {
    if (profile) {
      setName(profile.name || '')
      setSurname(profile.surname || '')
      // setLanguage(profile.lang || 'it')
      setPhone(profile.cellphone || '')
      setAddress(profile.address || '')
      setNumber(profile.number || '')
      setCity(profile.city || '')
      setProvince(profile.country || '')
      setZipcode(profile.zipcode || '')
    }
  },[profile])

  return (
    <div className="p-profile-user-data">
      <Container className="is-small">
        <p className="text-primary">{translate('profile.data.userData')}</p>
        <Form>
          <Form.Group controlId="formGroupName">
            <Form.Label>{translate('profile.data.name')}</Form.Label>
            <Form.Control
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formGroupSurnname">
            <Form.Label>{translate('profile.data.surname')}</Form.Label>
            <Form.Control
              type="text"
              value={surname}
              onChange={e => setSurname(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="formGroupPhone">
            <Form.Label>{translate('profile.data.phone')}</Form.Label>
            <Form.Control
              type="text"
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
          </Form.Group>

          <p className="text-primary">{translate('profile.data.address')}</p>

          <Form.Group controlId="formGroupAddress">
            <Form.Label>{translate('profile.data.address')}</Form.Label>
            <Form.Control
              type="text"
              value={address}
              onChange={e => setAddress(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formGroupAddressNumber">
            <Form.Label>{translate('profile.data.number')}</Form.Label>
            <Form.Control
              type="text"
              value={number}
              onChange={e => setNumber(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formGroupCity">
            <Form.Label>{translate('profile.data.city')}</Form.Label>
            <Form.Control
              type="text"
              value={city}
              onChange={e => setCity(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formGroupCountry">
            <Form.Label>{translate('profile.data.country')}</Form.Label>
            <Form.Control
              type="text"
              value={country}
              onChange={e => setProvince(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="formGroupZipCode">
            <Form.Label>{translate('profile.data.zipcode')}</Form.Label>
            <Form.Control
              type="text"
              value={zipcode}
              onChange={e => setZipcode(e.target.value)}
            />
          </Form.Group>

          <div className="text-center">
            <Button onClick={updateData} className="has-min-width">
              {translate('general.save')}
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
});
