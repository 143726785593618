// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'title': 'Errore',
    'general': 'Si è verificato un errore durante la procedura',
    'signin': {
        'password': 'Username e/o password errati'
    },
    'signup': {
        'data': 'Controlla i campi inseriti'
    },
    'payment': 'Si è verificato un errore durante il pagamento',
    'forgotPassword': 'Elaborazione non eseguita. Verificare correttezza dei dati inseriti',
    'noRides': 'Non sono state trovate corse con i parametri selezionati',
    'password': [
        'La password deve contenere almeno 6 caratteri.',
        'La password non può contenere sequenze di tre o più caratteri ripetuti.',
        'La password deve contenere almeno una lettera maiuscola.',
        'La password deve contenere almeno un numero.',
        'La password deve contenere almeno un carattere speciale.'
    ],
    'noAreas': 'Qualcosa è andato storto, non abbiamo trovato nessun\'area di servizio attiva',
    'noTravels': 'Nessuna soluzione trovata. Modifica i parametri e riprova.',
    'timeExpired': 'Il tempo limite per acquistare il titolo di viaggio è scaduto. Effettua una nuova ricerca.',
    'areaPointsOutbounds': 'Il punto di origine e/o destinatione scelto non si trova all\'interno dell\'area selezionata. Controlla i campi e riprova.',
    'notReachableByRoad': 'Il punto di origine e/o destinazione scelto è troppo lontano da una strada. Controlla i campi e riprova.',
    'travelPassengersInvalid': 'Il numero di passeggeri selezionato è superiore al limite.',
    'phone': "Il numero di telefono deve essere nel formato:\n +391234567890",
    'pageNotFound': "Oops! Pagina non trovata"
}
