import axios from 'axios';
import { languageCultures } from './languageCultures';

const querySerialize = (obj) => {
    let str = '?' + Object.keys(obj).reduce(function(a, k){
        a.push(k + '=' + encodeURIComponent(obj[k]));
        return a;
    }, []).join('&');
    return str;
  }


export const geocodeEarth = ({contexts, Api, createPlaceObj, manageACallback}) => {

  return {
    'parsePlace': item => createPlaceObj(item.id, item.title, item.position[0], item.position[1], item),
    'reverseGeocode': async(lat, lng, geocoderConfig, callback) => {
      const {auth} = contexts
      , {user} = auth
      , {language = 'it'} = user
      , {urls, config} = geocoderConfig
      try {
        const params = {
              api_key: config.apiKey,
              language: languageCultures[language] || 'it-IT',
              'point.lat': lat,
              'point.lon': lng,
              layers: 'address,street'
            }
          , response = await axios.get(`${urls.reverse}${querySerialize(params)}`, {
              'method': 'GET',
              'headers': {
                'Accept': '*/*',
                'Content-Type': 'application/json'
              }
            });

        const [item] = response?.data?.features;

        if (item) {
          let found = false
            , myPlace = undefined;

          // result.forEach((item) => {
            const {properties} = item;
            if (!found) {
            found = true;

            const {id,label} = properties;

            myPlace = {
              'placeId': id,
              'address': label,
              'details': '',
              'latitude': lat,
              'longitude': lng,
              'fullAddress': label
            };
          }
        // });
        return manageACallback(myPlace, callback);
      }
      }
      catch (e){
        console.log(e)
      }

    },
    'search': async({text, bbox, center, geocoderConfig, /*getUserLocation*/}, callback) => {
      const {auth} = contexts
      , {user} = auth
      , {language = 'it'} = user
      , {urls, config} = geocoderConfig;

      const headers = {
        'Accept': '*/*',
        'Content-Type': 'application/json'
      }

      const params = {
        api_key: config.apiKey,
        language,
        text,
        layers: 'address,street'
      }

      if(center?.coordinates?.length > 0){
        const [centerLongitude, centerLatitude] = center.coordinates;
        params[`focus.point.lat`] = centerLatitude;
        params[`focus.point.lon`] = centerLongitude;
      }

      if(bbox != null && bbox.length === 4){

        params[`boundary.rect.min_lon`] = bbox[0];
        params[`boundary.rect.min_lat`] = bbox[1];
        params[`boundary.rect.max_lon`] = bbox[2];
        params[`boundary.rect.max_lat`] = bbox[3];
        
      } 

      const response = await fetch(`${urls.autocomplete}${querySerialize(params)}`, {
          'method': 'GET',
          headers
        })

      const json = await response.json()
      , results = json?.features?.map(item => {
          const {properties, geometry} = item;
          const {id, label} = properties

          return {
            'placeId': id,
            'address': label,
            'details': '',
            'fullAddress': label,
            'latitude': geometry.coordinates[1],
            'longitude': geometry.coordinates[0]
          };
      });

      return manageACallback(results, callback);
    },
    'parseCategory': (category) => {
      var icon = 'store';
      if (!category || category.indexOf('shop') !== -1) {
        return icon;
      }

      if (category.indexOf('sports') !== -1) {
        return 'basketball-ball';
      }

      if (category.indexOf('education') !== -1) {
        return 'school';
      }

      if (category.indexOf('hospital') !== -1) {
        return 'hospital';
      }

      switch (category) {
        case 'landmark-attraction': icon = 'chess-rook'; break;
        case 'bar-pub': icon = 'gas-pump'; break;
        case 'theatre-music-culture': icon = 'theater-masks'; break;
        case 'coffee-tea': icon = 'coffee'; break;
        case 'hotel':
        case 'accommodation': icon = 'hotel'; break;
        case 'wine-and-liquor': icon = 'wine-glass'; break;
        case 'food-drink':
        case 'restaurant': icon = 'utensils'; break;
        case 'public-transport': icon = 'bus'; break;
        case 'railway-station': icon = 'train'; break;
        case 'street-square': icon = 'road'; break;
        case 'building': icon = 'building'; break;
        case 'government-community-facility':
        case 'museum':
        case 'religious-place':
        case 'sights-museums': icon = 'university'; break;
        case 'city-town-village': icon = 'city'; break;
        case 'airport': icon = 'plane-arrival'; break;
        default: icon = 'map-marker';
      }

      return icon;
    },
  }
}
