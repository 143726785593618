// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'general': {
        'name': 'Nome',
        'surname': 'Cognome',
        'email': 'Email',
        'password': 'Password',
        'phone': 'Telefono'
    },
    'signin': {
        'title': 'Accedi',
        'subtitle': 'Inserisci le tue credenziali per continuare',
        'signInDone': 'Login eseguito con successo',
        'forgotPassword': {
            'title': 'Password dimenticata',
            'message': 'Inserisci l\'email associata all\'account per recuperare la password',
            'recovery': 'Ti abbiamo inviato un\'email per recuperare la password. Controlla la tua casella di posta.'
        },
        'notActive': 'Devi confermare il tuo account per proseguire. Controlla la tua mail.',
        'password': 'Username e/o Password non validi',
        'register': 'Non hai le credenziali?'
    },
    'register': {
        'title': 'Registrati',
        'subtitle': 'Crea il tuo account',
        'confirmEmail': 'Conferma Email',
        'confirmPassword': 'Conferma Password',
        'confirmPhone': 'Conferma Telefono'
    },
    'continue': 'Continua senza account',
    'confirmEmail': 'Ti abbiamo inviato una mail. Confermala per continuare.'
}
