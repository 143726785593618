const delay = ms => new Promise(res => setTimeout(res, ms))
  , MAXCOUNTER = 2;

export const Settings = ({utilities, Api, contexts}) => {
  const {log} = utilities
    , url = Api.urls.tenants.settings.get

  return {
    'get':  async (multicheck) => {
      const {device} = contexts;
      let json = await Api.fetch(url, 'GET')
      let counter = 0;

      if(!json && multicheck){
        while (!json && counter < MAXCOUNTER){
          log('init_app', `Init Settings await 5s, try ${counter} of ${MAXCOUNTER}`);
          await delay(5000);
          json = await Api.fetch(url, 'GET');
          counter++;
        }
      }

      log('init_app', 'Init settings load successfull');
      device.setSettings(json);
      return {'settings': json};
    }
  }
}
