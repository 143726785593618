import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import {
  Form,
  Row,
  Col,
  Button,
  OverlayTrigger,
  InputGroup,
  FormControl
} from 'react-bootstrap'

const QuantityPicker = ({
  currentValue,
  showInfo,
  onChange,
  maxValue,
  label,
}) => {
  const decrease = () => {
    if(onChange && currentValue > 0) {
      onChange(currentValue - 1)
    }
  }

  const increase = () => {
    if(onChange && currentValue + 1 <= maxValue){
      onChange(currentValue + 1)
    }
  }

  return (
    <div className="c-quantityPicker">
      <Form.Group as={Row}>
        <Form.Label column>
          {label}{' '}
          {
            showInfo &&
              <OverlayTrigger
                trigger={['hover', 'focus']}
                placement="top"
                overlay={showInfo()}
              >
                <FontAwesomeIcon icon={faInfoCircle} />
              </OverlayTrigger>
          }
        </Form.Label>
        <Col>
          <InputGroup>
            <FormControl
              value={currentValue}
              readOnly
            />
            <InputGroup.Append>
              <Button variant="outline-dark" onClick={decrease}>-</Button>
              <Button variant="outline-dark" onClick={increase}>+</Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Form.Group>

    </div>
  )
}

export default QuantityPicker
