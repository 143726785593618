import React, {useContext, useEffect, useState} from 'react'

import { observer } from 'mobx-react-lite'
import { AlertContext } from '../context/alert'

import {
  useHistory,
  useLocation,
  Prompt
} from 'react-router-dom'

import {
  Button,
  Container
} from 'react-bootstrap'

import RouteDetailsBox from '../components/RouteDetailsBox'
import RouteProgressBar from '../components/RouteProgressBar'

export const Payment = observer(({
  contexts,
  components,
  utils,
  config
}) => {
  const alert = useContext(AlertContext)
  const location = useLocation()
  const history = useHistory()

  const {AuthContext} = contexts
  const {useTheme, screen} = config
  const {loading} = components
  const {translate, Api, libs} = utils
  const {icons} = libs
  const {theme} = useTheme()

  const {currentTravelResponse = {}, setCurrentTravelResponse} = useContext(AuthContext)
  const {'travel': travelResponse, cart} = currentTravelResponse

  const [journeyId, setJourneyId] = useState('')
  const [travelId, setTravelId] = useState('')
  const [route, setRoute] = useState(null)
  const [discount, setDiscount] = useState(null)
  const [currency, setCurrency] = useState(null)
  const [surcharge, setSurcharge] = useState(null)
  const [deleteCurrentTravel, setDeleteCurrentTravel] = useState(false)
  const [stripePopup, setStripePopup] = useState(null)
  const [stripePopupResult, setStripePopupResult] = useState(null)

  const [theArea, setTheArea] = useState(null)

  useEffect(() => {
    if (location.state) {
      setJourneyId(location.state.journeyId)
      setTravelId(location.state.travelId)
      setTheArea(JSON.parse(location.state.area))
    } else {
      history.replace('/')
    }
  },[location])

  useEffect(() => {
    if (deleteCurrentTravel) {
      history.replace('/')
      setDeleteCurrentTravel(false)
    }
  }, [deleteCurrentTravel])

  useEffect(() => {
    if (journeyId && travelResponse && travelResponse.journeys) {
      setRoute(travelResponse.journeys.find(j => j.id = journeyId))
    }
  }, [journeyId, travelResponse])

  useEffect(() => {
    if (cart) {
      const {discount, cash, currency} = cart
      const {surcharge = 0} = cash

      setDiscount(discount)
      setCurrency(currency)
      setSurcharge(surcharge)
    }
  }, [cart])

  useEffect(() => {
    if (stripePopupResult && stripePopup) {
      stripePopup.close()
    }
  }, [stripePopupResult])

  useEffect(() => {
    const postMessageHandler = e => {
      if (e.type === 'message' && e.origin === process.env.REACT_APP_API_DRT) {
        switch (e.data.result) {
          case 'ok':
            alert.success(translate('travel.payment.purchaseOk.message'), {
              title: translate('travel.payment.purchaseOk.title'),
              closeCopy: translate('general.ok'),
              onClose: () => {
                history.replace('/my-tickets')
                setCurrentTravelResponse(undefined);
              }
            })
            break
          case 'ko':
            alert.error(translate('errors.payment'), {
              title: translate('general.advise'),
              closeCopy: translate('general.ok'),
              onClose: () => {
                history.replace('/')
              }
            })
            break
          case 'cancel':
          default:
            alert.error(translate('travel.payment.cancel'), {
              title: translate('general.advise'),
              closeCopy: translate('general.ok'),
              onClose: () => {
                history.replace('/')
              }
            })
        }

        setStripePopupResult(e.data.result)
      }
    }

    window.addEventListener('message', postMessageHandler)

    return () => window.removeEventListener('message', postMessageHandler)
  }, [])

  const showStripePopup = url => {
    const stripePopupWidth = window.innerWidth - 200
    const stripePopupHeight = window.innerHeight - 200
    const stripePopupTop = Math.round((window.innerHeight - stripePopupHeight) / 2)
    const stripePopupLeft = Math.round((window.innerWidth - stripePopupWidth) / 2)

    const stripePopupSize = `width=${stripePopupWidth},height=${stripePopupHeight}`
    const stripePopupPosition = `top=${stripePopupTop},left=${stripePopupLeft}`
    const stripePopup = window.open(url, 'StripePaymentWindow', `${stripePopupSize},${stripePopupPosition}`)

    setStripePopup(stripePopup)
  }

  const paymentHandler = async paymentGateway => {
    if (paymentGateway === 'Stripe' && stripePopup) {
      if (stripePopup.closed) {
        setStripePopup(null)
      } else {
        stripePopup.focus()
        return
      }
    }

    loading.set(true);
    const {cartId, currency} = cart
    const response = await Api.fetch(
      Api.clean(Api?.urls?.drt?.payments?.url),
      {
        'method': 'POST',
        'headers': {
          'platform': 'web'
        },
        'body': {
          cartId,
          currency,
          paymentGateway
        }
      }
    )

    loading.set(false);

    if(response.err){
      console.log(response);

      alert.error(translate('errors.payment'), {
        title: translate('general.advise'),
        closeCopy: translate('general.ok')
      })
      return;
    }

    const {url} = response;

    // Case payment on board
    if(url === 'already-authorized'){
      alert.success(translate('travel.payment.purchaseOk.message'), {
        title: translate('travel.payment.purchaseOk.title'),
        closeCopy: translate('general.ok')
      })

      history.replace('/my-tickets')
      setCurrentTravelResponse(undefined);

      return;
    }

    // Case payment with stripe
    showStripePopup(url)
  }

  const onCancelClick = () => {
    setDeleteCurrentTravel(true)
  }

  const timeClearance = theArea?.properties?.confs?.travelsSearchConfiguration?.timeClearance

  return (
    <div className="p-payment">
      {
        route &&
          <Container className="is-small">
            <RouteDetailsBox
              screen={screen}
              theme={theme}
              route={route}
              hideAction={true}
              translate={translate}
              icons={icons}
              discount={discount}
              timeClearance={timeClearance}
            />

            <RouteProgressBar
              theme={theme}
              screen={screen}
              route={route}
            />

            <hr />

            <div className="text-center">
              <Button
                className="has-min-width mb-3"
                onClick={() => paymentHandler('Stripe')}
              >
                {translate('travel.payment.continue')}
              </Button>

              <p>{translate('general.or')}</p>

              <Button
                variant="link"
                onClick={() => paymentHandler('Cash')}
              >
                {
                  translate(`travel.payment.onboard.${surcharge > 0 ? 'surcharge': 'default'}`,
                    {'surcharge': Number(surcharge) / 100, currency})
                }
              </Button>

              <p className="small">{translate('travel.payment.onboard.conditions')}<a href="https://moeves.it/" target="_blank" rel="noreferrer">www.moeves.it</a>)</p>

              <p>{translate('general.or')}</p>

              <Button
                variant="secondary"
                className="has-min-width"
                onClick={onCancelClick}
              >
                {translate('general.cancel')}
              </Button>
            </div>
          </Container>
      }

      <Prompt
        when={deleteCurrentTravel}
        message={JSON.stringify({
          text: translate('travel.journey.delete.title') + '\n' + translate('travel.journey.delete.message'),
          travelId: travelResponse.id
        })}
      />
    </div>
  );
})
