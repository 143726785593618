import React, { useState, useContext } from 'react'
import {
  Button,
  Container,
  Form
} from 'react-bootstrap'
import { AlertContext } from '../context/alert'

const FormLogin = ({
  contexts,
  utils,
  onLogin,
  onForgotPasswordButtonClick,
  onSignUpButtonClick
}) => {
  const alert = useContext(AlertContext)

  const {AuthContext} = contexts
  const {translate, Api} = utils
  const {login} = useContext(AuthContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const signIn = () => {
    login(Api, {
      'email': email.toLowerCase(),
      password,
      'lang': 'it',
    }, 'password', (res) => {
      if (res === false) {
        alert.error(translate('errors.signin.password'), {
          title: translate('errors.title'),
          closeCopy: translate('general.ok')
        });
      } else {
        // history.push('/')
        onLogin()
      }
    });
  }

  const signUp = () => {
    // history.push('register')
    onSignUpButtonClick()
  }

  const forgotPassword = () => {
    // history.push('forgot-password')
    onForgotPasswordButtonClick()
  }

  return (
    <Container className="is-small">
      <h4>{translate('login.signin.subtitle')}</h4>

      <Form>
        <Form.Group controlId="formGroupEmail">
          <Form.Label>{translate('login.general.email')}</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupPassword">
          <Form.Label>{translate('login.general.password')}</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>

        <div className="text-center">
          <Button onClick={signIn} className="has-min-width">
            {translate('login.signin.title')}
          </Button>
          <br />
          <Button variant="link" size="sm" onClick={forgotPassword}>
            {`${translate('login.signin.forgotPassword.title')}?`}
          </Button>
        </div>

        <hr/>

        <div className="text-center">
          <p>{translate('login.signin.register')}</p>
          <Button onClick={signUp} className="has-min-width">
            {translate('login.register.title')}
          </Button>
        </div>
      </Form>
    </Container>
  )
}

export default FormLogin
