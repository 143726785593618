import React from 'react'
import { observer } from 'mobx-react-lite'
import {
  Link,
  useHistory
} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  useDevice,
  useAuth
} from '../context'

const Sidebar = observer(({ api }) => {
  const {'t': translate} = useTranslation()
  const history = useHistory();
  const device = useDevice()
  const auth = useAuth()

  const logout = () => {
    auth.logout(api, () => {
      history.replace('/')
    })
  }

  return (
    <div className="c-sidebar">
      {
        auth?.isLoggedIn &&
          <div className="c-sidebar__header">
            <h5 className="text-primary">
              {
                translate('travel.home.title', {
                  username: auth?.user?.profile?.name
                }
              )}
            </h5>
          </div>
      }

      <nav className="c-sidebar__nav">
        <ul className="c-sidebar__navPrimary">
          {
            auth.isLoggedIn &&
              <li>
                <Link to="/profile">{translate('menu.settings')}</Link>
              </li>
          }
          {
            !auth.isLoggedIn &&
              <li>
                <Link to="/login">{translate('menu.login')}</Link>
              </li>
          }
          <li>
            <Link to="/">{translate('menu.travel')}</Link>
          </li>
          {
            auth.isLoggedIn &&
              <>
                <li>
                  <Link to="/my-tickets">{translate('menu.mytickets')}</Link>
                </li>
                <li>
                  <button type="button" onClick={logout}>
                    {translate('menu.logout')}
                  </button>
                </li>
              </>
          }
        </ul>
        <ul className="c-sidebar__navSecondary">
          <li>
            <a
              href="https://moeves.it/info-utili/"
              target="_blank"
              rel="noreferrer"
            >
              {translate('menu.info')}
            </a>
          </li>
          <li>
            <a
              href="https://moeves.it/assistenza/"
              target="_blank"
              rel="noreferrer"
            >
              {translate('menu.support')}
            </a>
          </li>
          <li>
            <a
              href="https://moeves.it/come-funziona/"
              target="_blank"
              rel="noreferrer"
            >
              {translate('menu.help')}
            </a>
          </li>
        </ul>
      </nav>
      <span className="c-sidebar__version">{translate('general.version', {'versionNumber': process.env.REACT_APP_VERSION})}</span>
      <span className="c-sidebar__credits">Powered by OpenMove</span>
    </div>
  )
})

export default Sidebar;
