import React, { useState, useEffect, useContext} from 'react';
import {
  Button,
  Container,
  Form,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { AlertContext } from '../context/alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import TermsConditions from './TermsConditions'

const FormRegister = ({
  components,
  contexts,
  utils,
  onComplete,
  onBackLogin
}) => {
  const alert = useContext(AlertContext)

  const {AuthContext, DeviceContext} = contexts
  const {loading} = components
  const {translate, Api} = utils
  const {language} = useContext(AuthContext)
  const {'settings': settingsConfig} = useContext(DeviceContext)

  const [formName, setFormName] = useState("")
  const [formSurname, setFormSurname] = useState("")
  const [formEmail, setFormEmail] = useState("")
  const [formCheckEmail, setFormCheckEmail] = useState("")
  const [formPassword, setFormPassword] = useState("")
  const [formCheckPassword, setFormCheckPassword] = useState("")
  const [formPhone, setFormPhone] = useState("")
  const [formCheckPhone, setFormCheckPhone] = useState("")
  const [tncs, setTncs] = useState([])

  const [disabledButton, setDisabledButton] = useState(false)

  useEffect(() => {
    if(tncs.length === 0){
      Api.fetch(
        Api.clean(Api?.urls?.drt?.tncs?.url),
        {
          'method': 'GET'
        },
        res => {console.log("...>", res); setTncs(res)}
        )
      }
  }, [])

  useEffect(() => {
    setDisabledButton(
      formName === "" ||
      formSurname === "" ||
      formEmail === "" ||
      formCheckEmail === "" ||
      formPassword === "" ||
      formCheckPassword === ""
    )
  }, [
    formName,
    formSurname,
    formEmail,
    formCheckEmail,
    formPassword,
    formCheckPassword
  ])

  const showError = (message = translate('errors.general')) => {
    alert.error(message, {
      title: translate('errors.title'),
      closeCopy: translate('general.ok')
    })
  }

  const showInfo = (type) => {
    let message;

    if (type === 'password'){
      message = translate('errors.password').join('\n');
    }

    if (type === 'phone'){
      message = translate('errors.phone')
    }

    return <Tooltip>{message}</Tooltip>
  }

  const registerUser = () => {
    loading.set(true);

    const body = {
      'email': formEmail.toLowerCase(),
      'password': formPassword,
      'language': language.substring(0,2),
      'customData': {
        'name': formName,
        'surname': formSurname,
        'cellphone': formPhone.replace(/\s/g, '')
      }
    }

    if(formEmail == null || formEmail == '' || formEmail !== formCheckEmail || formPhone !== formCheckPhone || formPassword !== formCheckPassword) {
      loading.set(false);

      alert.error(translate('errors.signup.data'), {
        title: translate('errors.title'),
        closeCopy: translate('general.ok')
      })

      return
    }

    if(settingsConfig?.user?.phone != null && !(new RegExp(settingsConfig?.user?.phone)).test(formPhone.replace(/\s/g, ''))){
      loading.set(false);

      alert.error(translate('errors.phone'), {
        title: translate('errors.title'),
        closeCopy: translate('general.ok')
      })

      return
    }

    const acceptedTncs = [];
    let allBlockedChecked = true;

    for(const {tncFk, blocked, selected} of tncs){
      if(blocked === true && selected === false){
        allBlockedChecked = false;
      }
      if(selected === true){
        acceptedTncs.push(tncFk);
      }
    }

    if(!allBlockedChecked){
      loading.set(false);

      alert.success(translate('tncs.check.message'), {
        title: translate('tncs.title'),
        closeCopy: translate('general.ok')
      })

      return
    }

    body.tncsAccepted = acceptedTncs;

    Api.fetch(
      Api.clean(Api?.urls?.drt?.users?.signup.url),
      {
        'method': 'POST',
        body
      }
      , res => {
        console.log(res);

        loading.set(false);
        if (res?.err) {
          const {statusCode, message} = res.errMsg;
          if(statusCode === 409){
            if(message && message?.tncs){
              //tncs not checked
              alert.success(translate('tncs.check.message'), {
                title: translate('tncs.title'),
                closeCopy: translate('general.ok')
              })
            }
            else {
              showError();
            }
          }
          else if(statusCode === 412){
            //password weak
            alert.error(translate('errors.password').join('\n'), {
              title: translate('errors.title'),
              closeCopy: translate('general.ok')
            })
          }
          else {
            //generic
            showError();
          }
        }
        else {
          alert.success(translate('login.confirmEmail'), {
            title: translate('general.confirm'),
            closeCopy: translate('general.ok'),
            onClose: () => {
              // history.push('/login')
              onComplete()
            }
          })
        }
      })
  }

  return (
    <Container className="is-small">
      <h4>{translate('login.register.subtitle')}</h4>
      <Form>
        <p className="text-primary">{translate('profile.data.userData')}</p>
        <Form.Group controlId="formGroupName">
          <Form.Control
            type="text"
            placeholder={translate('login.general.name')}
            value={formName}
            autoFocus={true}
            onChange={e => setFormName(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupSurname">
          <Form.Control
            type="text"
            placeholder={translate('login.general.surname')}
            value={formSurname}
            onChange={e => setFormSurname(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupEmail">
          <Form.Control
            placeholder={translate('login.general.email')}
            type="email"
            value={formEmail}
            onChange={e => setFormEmail(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupConfirmEmail">
          <Form.Control
            placeholder={translate('login.register.confirmEmail')}
            type="email"
            value={formCheckEmail}
            onChange={e => setFormCheckEmail(e.target.value)}
          />
        </Form.Group>
        <p className="text-primary">
          {translate('login.general.password')}
          {' '}
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={showInfo('password')}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </OverlayTrigger>
        </p>
        <Form.Group controlId="formGroupPassword">
          <Form.Control
            placeholder={translate('login.general.password')}
            type="password"
            value={formPassword}
            onChange={e => setFormPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupConfirmPassword">
          <Form.Control
            placeholder={translate('login.register.confirmPassword')}
            type="password"
            value={formCheckPassword}
            onChange={e => setFormCheckPassword(e.target.value)}
          />
        </Form.Group>
        <p className="text-primary">
          {translate('login.general.phone')}
          {' '}
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement="top"
            overlay={showInfo('phone')}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </OverlayTrigger>
        </p>
        <Form.Group controlId="formGroupPhone">
          <Form.Control
            placeholder={translate('login.general.phone')}
            type="text"
            value={formPhone}
            onChange={e => setFormPhone(e.target.value)}
          />
        </Form.Group>
        <Form.Group controlId="formGroupCheckPhone">
          <Form.Control
            placeholder={translate('login.register.confirmPhone')}
            type="text"
            value={formCheckPhone}
            onChange={e => setFormCheckPhone(e.target.value)}
          />
        </Form.Group>

        {
          tncs.length > 0 &&
            <>
              <p className="text-primary">{translate('tncs.title')}</p>
              <TermsConditions
                components={components}
                contexts={contexts}
                utils={utils}
                disableButton={true}
                tncs={tncs}
                setExternalTncs={setTncs}
              />
            </>
        }

        <div className="text-center">
          <Button
            onClick={registerUser}
            disabled={disabledButton}
            className="has-min-width"
          >
            {translate('login.register.title')}
          </Button>
        </div>

        {
          onBackLogin &&
            <div className="text-center mt-3">
              <Button
                variant="dark"
                onClick={onBackLogin}
                className="has-min-width"
              >
                Torna al login
              </Button>
            </div>
        }
      </Form>
    </Container>
  )
}

export default FormRegister
