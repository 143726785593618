/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import {useTranslation} from 'react-i18next'
import {
  BrowserRouter,
  HashRouter,
  Switch,
  Route,
  Redirect,
  useHistory
} from "react-router-dom"

import { observer } from 'mobx-react-lite'

import {
  ThemeManagerController,
  useTheme
} from './theme'

import Navbar from './components/Navbar'
import Sidebar from './components/Sidebar'
import Loading from './components/Loading'

import {Home} from './pages/Home'
import {Login} from './pages/Login'
import {ForgotPassword} from './pages/ForgotPassword'
import {Register} from './pages/Register'
import {ProfileUserData} from './pages/ProfileUserData'
import {Travel} from './pages/Travel'
import {RouteDetails} from './pages/RouteDetails'
import {RouteAlternatives} from './pages/RouteAlternatives'
import {Cart} from './pages/Cart'
import {Payment} from './pages/Payment'
import {Tickets} from './pages/Tickets'
import {TicketDetails} from './pages/TicketDetails'
import {Rating} from './pages/Rating'
import {Error404} from './pages/Error404'
import {CheckTncs} from './pages/CheckTncs'

import "react-datepicker/dist/react-datepicker.css";

const tenant = process.env.REACT_APP_TENANT

if (tenant === 'asgard') {
  require('./styles/theme-asgard.scss')
} else if (tenant === 'moeves') {
  require('./styles/theme-moeves.scss')
} else {
  require('./styles/theme-default.scss')
}

const channel = process.env.REACT_APP_CHANNEL
const environment = process.env.REACT_APP_ENVIRONMENT

const {auth, device, AuthContext, DeviceContext} = require('./context')
const {'Library': libraryComponent} = require('./lib')
const {'Api': apiComponent} = require('@openmove-toolbelt/api')
const {staticTheme} = require('./theme')
const {mainColor} = staticTheme

const Config = {
  channel: process.env.REACT_APP_CHANNEL,
  environment: process.env.REACT_APP_ENVIRONMENT,
  api_drt: process.env.REACT_APP_API_DRT,
  mqtt: process.env.REACT_APP_MQTT,
  theme: process.env.REACT_APP_THEME,
  tenant: process.env.REACT_APP_TENANT,
}

const Pages = [
  {'name': 'Home', 'component': Home},
  {'name': 'Login', 'component': Login},
  {'name': 'ForgotPassword', 'component': ForgotPassword},
  {'name': 'Register', 'component': Register},
  {'name': 'ProfileUserData', 'component': ProfileUserData},
  {'name': 'Travel', 'component': Travel},
  {'name': 'RouteDetails', 'component': RouteDetails},
  {'name': 'RouteAlternatives', 'component': RouteAlternatives},
  {'name': 'Cart', 'component': Cart},
  {'name': 'Payment', 'component': Payment},
  {'name': 'Tickets', 'component': Tickets},
  {'name': 'TicketDetails', 'component': TicketDetails},
  {'name': 'Rating', 'component': Rating},
  {'name': 'Error404', 'component': Error404},
  {'name': 'CheckTncs', 'component': CheckTncs}
]

const registerPages = (async () => {
  const apiEnvUrls = {
    api_drt: process.env.REACT_APP_API_DRT
  }

  const apiUrls = Object.entries(apiEnvUrls)
    .map(([key, value]) => ({
      'moduleName': key.split('_')[1],
      'url': `${value}/json`
    }))

  const params = {'contexts': {auth, device}, tenant, channel}
	const Api = apiComponent({...params, apiUrls})
  const theApi = {
    'fetch': async(...args) => {
      const response = await Api.fetch(...args);

      if(response.err === true && response.errMsg && response.errMsg?.message === 'tncs.blocked'){
        // TODO: take a loot to mobile app index.js row 126
        alert('error with api')
      }

      return response;
    },
    'urls': await Api.urls(),
    'clean': url => url
  }

  const library = libraryComponent({...params, 'Api': theApi});

  theApi.deleteTravel = async({translate, travelId}) => {
    // TODO: take a look to mobile app index.js row 145
    const response = await theApi.fetch(
      theApi.clean(theApi?.urls?.drt?.travel?.delete?.url),
      {
        'method': 'POST',
        'body': {
          travelId
        }
      })

    if (response.err != null){
      window.alert(translate('general.advise'), translate('errors.general'), [
        {'text': translate('general.ok')},
      ]);
      return;
    }

    // auth.setCurrentTravelResponse({})

    window.location.replace('/')
  }

  const pageComponents = {}
  for(const {name, component} of Pages){
    pageComponents[name] = registerComponent(name, component, {theApi, library })
  }

  return {
    'Api': theApi,
    pageComponents
  }
})

const registerComponent = (name, TheComponent, {theApi, library},  customProps = {}) => {
  return observer((props) => {
    const {'t': translate} = useTranslation()
    const [loading, setLoading] = useState(false)
    const {tenant, setTenant} = React.useContext(DeviceContext)
    const {currentTravelResponse = null} = React.useContext(AuthContext)

    const deps = {
      'contexts': {
        AuthContext,
        DeviceContext
      },
      'utils': {
        'libs': library.utilities,
        'navigation': library.navigation,
        'Api': theApi,
        translate
      },
      'components': {
        'loading': {
          'isEnable': loading,
          'set': setLoading
        }
      },
      'config': {
        environment,
        Config,
        useTheme,
      }
    }

    useEffect(() => {
      if(!tenant) {
        setTenant(theApi, Config)
      }
      // TODO: take a look to mobile app index.js row 243
    }, [])

    return (
      <ThemeManagerController name={name}>
				<TheComponent {...props} {...deps} {...customProps}/>
        {
          loading && <Loading />
        }
			</ThemeManagerController>
    )
  })
}

// Create a store hydration function.
async function hydrateStores() {
	return {auth}
}

function PrivateRoute({ page, ...rest }) {
  const {isLoggedIn, needTncsCheck} = React.useContext(AuthContext)
  const Component = page

  return (
    <Route
      {...rest}
      render={() =>
        isLoggedIn ? (
          <Component />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );

  // return (
  //   <Route
  //     {...rest}
  //     render={() =>
  //       isLoggedIn ? (
  //         needTncsCheck ? <Redirect to="/check-tncs" /> : <Component />
  //       ) : (
  //         <Redirect to="/login" />
  //       )
  //     }
  //   />
  // );
}

function PublicRoute({ page, ...rest }) {
  const {isLoggedIn} = React.useContext(AuthContext)
  const Component = page

  return (
    <Route
      {...rest}
      render={() =>
        !isLoggedIn ? (
          <Component />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

function App() {
  const {'t': translate} = useTranslation()
  const [pageComponents, setPageComponents] = useState(null)
  const [menuBarToggle, setMenuBarToggle] = useState(false)
  const [api, setApi] = useState(null)
  const {needTncsCheck} = React.useContext(AuthContext)

  useEffect(() => {
    Promise.all([
      hydrateStores(),
      registerPages()
    ]).then(([
      {'auth': authHydrated},
      {Api, pageComponents},
    ]) => {
      setPageComponents(pageComponents)
      setApi(Api)
      console.log("mqttlog ----->", authHydrated)
      const { mqttInit } = authHydrated

      //always call mqttInit, if user non legged -> anonymous login
      mqttInit(Api);
    })
  }, [])

  if (!api || !pageComponents) {
    return <></>
  }

  const ModalCheckTncs = pageComponents['CheckTncs']

  return (
    <BrowserRouter getUserConfirmation={(message, callback) => {
      const messageObj = JSON.parse(message)
      const {text, travelId } = messageObj

      const allowTransition = window.confirm(text)

      if (allowTransition) {
        api.deleteTravel({
          translate,
          travelId,
          goTo: () => callback(allowTransition)
        })
      }
    }}>
      <div className={`App l-app t-${tenant}`}>
        <ModalCheckTncs />

        <Navbar
          toggle={menuBarToggle}
          onToggle={toggle => setMenuBarToggle(toggle)}
        />

        <Sidebar api={api} />

        <main className={`l-main ${menuBarToggle ? 'is-toggle' : ''}`}>
          <Switch>
            <Route exact path="/" render={() => {
              const Page = pageComponents['Home']
              return <Page />
            }} />

            <Route exact path="/area/:areaId" render={() => {
              const Page = pageComponents['Travel']
              return <Page />
            }} />

            <Route exact path="/page-404" render={() => {
              const Page = pageComponents['Error404']
              return <Page />
            }} />

            <PublicRoute exact path="/login" page={pageComponents['Login']} />
            <PublicRoute exact path="/forgot-password" page={pageComponents['ForgotPassword']} />
            <PublicRoute exact path="/register" page={pageComponents['Register']} />
            <PrivateRoute exact path="/route-alternatives" page={pageComponents['RouteAlternatives']} />
            <PrivateRoute exact path="/route-details" page={pageComponents['RouteDetails']} />
            <PrivateRoute exact path="/cart" page={pageComponents['Cart']} />
            <PrivateRoute exact path="/payment" page={pageComponents['Payment']} />
            <PrivateRoute exact path="/my-tickets" page={pageComponents['Tickets']} />
            <PrivateRoute exact path="/my-tickets/:id" page={pageComponents['TicketDetails']} />
            <PrivateRoute exact path="/rating/:id" page={pageComponents['Rating']} />
            <PrivateRoute exact path="/profile" page={pageComponents['ProfileUserData']} />

            <Route path="*">
              <Redirect to="/page-404" />
            </Route>
          </Switch>
        </main>
      </div>
    </BrowserRouter>
  );
}

export default App;
