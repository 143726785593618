import React, {useState, useEffect, useContext} from 'react'
import { observer } from 'mobx-react-lite'
import { AlertContext } from '../context/alert'
import {
  useParams
} from 'react-router-dom'
import {
  Container,
  Button,
  Form
} from 'react-bootstrap'
import ReactStars from "react-rating-stars-component";

const RATING_TYPE = 'tickets'

export const Rating = observer(({
  contexts,
  utils,
  components
}) => {
  const alert = useContext(AlertContext)
  const params = useParams()

  const {AuthContext, DeviceContext} = contexts
  const {loading} = components
  const {Api, navigation, translate} = utils


  const {isLoggedIn, language, currentTravelResponse = {}, token} = useContext(AuthContext)
  const {tenant} = useContext(DeviceContext)

  const [rating, setRating] = useState(null)
  const [comment, setComment] = useState('')

  useEffect(() => {
    if (tenant && tenant.rating) {
      const {[RATING_TYPE]: ratingFromType = {}} = tenant?.rating
      const ratingTranslations = ratingFromType?.translations ?? {}
      const ratingValues = ratingTranslations[language] ?? ratingTranslations.it ?? []

      setRating(ratingValues)
    }
  }, [tenant])

  const ratingCompleted = ratingValue => {
    setRating(ratingValue)
  }

  const sendRating = async() => {
    const response = await Api.fetch(
      Api.clean(Api.urls?.drt?.rating?.url),
      {
        'method': 'PUT',
        'body': {
          ticketFk: params.id,
          type: RATING_TYPE,
          'ratingCode': rating,
          comment
        }
      });

    if(response.err){
      alert.error(translate('errors.general'), {
        title: translate('general.advise'),
        closeCopy: translate('general.ok')
      });
      return;
    }

    alert.success(translate('rating.ok'), {
      title: translate('general.advise'),
      closeCopy: translate('general.ok')
    });
  }

  return (
    <div className="p-rating">
      <Container className="is-small">
        <div className="text-center">
          <h4>Grazie per aver viaggiato con noi!</h4>
          <h5>Come valuti la corsa?</h5>
        </div>

        <div className="d-flex align-items-center justify-content-center mt-4 mb-4">
          <ReactStars
            count={5}
            // reviews={ratingValues.map(r => r.description)}
            value={rating || 0}
            onChange={ratingCompleted}
            isHalf={false}
            size={36}
          />
        </div>


        <p className="text-center">Dicci come possiamo migliorare:</p>
        <Form.Group>
          <Form.Control
            multiline
            as="textarea"
            rows={3}
            onChange={ e => setComment(e.target.value)}
          />
        </Form.Group>

        <div className="text-center">
          <Button
            className="has-min-width"
            variant="primary"
            onClick={sendRating}
          >
            Invia
          </Button>
        </div>
      </Container>
    </div>
  )
})
