import axios from 'axios';
import { languageCultures } from './languageCultures';

const { v4: uuidv4 } = require('uuid');
const querySerialize = (obj) => {
    let str = '?' + Object.keys(obj).reduce(function(a, k){
        a.push(k + '=' + encodeURIComponent(obj[k]));
        return a;
    }, []).join('&');
    return str;
  }

export const tomtom = ({contexts, Api, createPlaceObj, manageACallback}) => {

  return {
    'parsePlace': item => createPlaceObj(item.id, item.title, item.position[0], item.position[1], item),
    'reverseGeocode': async(lat, lng, geocoderConfig, callback) => {
      const {auth} = contexts
        , {user} = auth
        , {language = 'it'} = user
        , {urls, config} = geocoderConfig

        try {
          const params = {
                'language': language,
                'key': config.apiKey,
                'returnMatchType': true
              }
            , response = await axios.get(`${urls.reverse}${lat}%2C${lng}.json${querySerialize(params)}`, {
                'method': 'GET',
                'headers': {
                  'Accept': '*/*',
                  'Content-Type': 'application/json'
                }
              });

          const [item] = response?.data?.addresses;

          if (item) {
            let found = false
              , myPlace = undefined;

            // result.forEach((item) => {
              const {address} = item;
              if (!found) {
              found = true;

              const {freeformAddress} = address;

              myPlace = {
                'placeId': uuidv4(),
                'address': freeformAddress,
                'details': '',
                'latitude': lat,
                'longitude': lng,
                'fullAddress': freeformAddress
              };
            }
          // });
          return manageACallback(myPlace, callback);
        }
        }
        catch (e){
          console.log(e)
        }

    },
    'search': async({text, bbox, center, geocoderConfig, /*getUserLocation*/}, callback) => {

      console.log(JSON.parse(JSON.stringify(geocoderConfig)));

      const {auth} = contexts
        , {user} = auth
        , {language = 'it'} = user
        , {urls, config} = geocoderConfig
        , headers = {
          'Accept': '*/*',
          'Content-Type': 'application/json'
        }

      const params = {
        typeahead: true,
        idxSet: 'Addr,Str',
        key: config.apiKey,
        language: languageCultures[language] || 'it-IT',
        limit: 1
      }

      if(center?.coordinates?.length > 0){
        const [centerLongitude, centerLatitude] = center.coordinates;
        params.lat = centerLatitude;
        params.lon = centerLongitude;
      }


      if(bbox != null && bbox.length === 4){
        params.in = `${bbox.join(',')}`

        const bboxNO = [bbox[0],bbox[3]]
        , bboxSE = [bbox[2],bbox[1]];
        params.topLeft = `${bboxNO.join(',')}`;
        params.btmRight = `${bboxSE.join(',')}`;
      } 


      const response = await fetch(`${urls.autocomplete}${text}.json${querySerialize(params)}`, {
          'method': 'GET',
          headers
        })

      const json = await response.json()
      , results = json?.results?.map(item => {
          const {id, address, position} = item;

          return {
            'placeId': id,
            'address': address.freeformAddress,
            'details': '',
            'fullAddress': address.freeformAddress,
            'latitude': position.lat,
            'longitude': position.lon
          };
      })

      return manageACallback(results, callback);

    },
    'parseCategory': (category) => {
      var icon = 'store';
      if (!category || category.indexOf('shop') !== -1) {
        return icon;
      }

      if (category.indexOf('sports') !== -1) {
        return 'basketball-ball';
      }

      if (category.indexOf('education') !== -1) {
        return 'school';
      }

      if (category.indexOf('hospital') !== -1) {
        return 'hospital';
      }

      switch (category) {
        case 'landmark-attraction': icon = 'chess-rook'; break;
        case 'bar-pub': icon = 'gas-pump'; break;
        case 'theatre-music-culture': icon = 'theater-masks'; break;
        case 'coffee-tea': icon = 'coffee'; break;
        case 'hotel':
        case 'accommodation': icon = 'hotel'; break;
        case 'wine-and-liquor': icon = 'wine-glass'; break;
        case 'food-drink':
        case 'restaurant': icon = 'utensils'; break;
        case 'public-transport': icon = 'bus'; break;
        case 'railway-station': icon = 'train'; break;
        case 'street-square': icon = 'road'; break;
        case 'building': icon = 'building'; break;
        case 'government-community-facility':
        case 'museum':
        case 'religious-place':
        case 'sights-museums': icon = 'university'; break;
        case 'city-town-village': icon = 'city'; break;
        case 'airport': icon = 'plane-arrival'; break;
        default: icon = 'map-marker';
      }

      return icon;
    },
  }
}
