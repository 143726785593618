import React, {useContext, useState, useEffect} from 'react'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import {
  useHistory,
  useLocation,
  Prompt
} from "react-router-dom"
import { AlertContext } from '../context/alert'

import RouteDetailsBox from '../components/RouteDetailsBox'
import RouteMap from '../components/RouteMap'

export const RouteDetails = observer(({
  componentId,
  contexts,
  utils,
  components,
  config,
  journey,
  purchased = false
}) => {
  const location = useLocation()
  const history = useHistory()
  const alert = useContext(AlertContext)

  const {AuthContext} = contexts
  const {screen} = config
  const {navigation, Api, translate, libs} = utils
  const {icons} = libs
  const {loading} = components
  const {isLoggedIn, currentTravelResponse, setCurrentTravelResponse} = useContext(AuthContext)
  const {'travel': travelResponse, cart} = currentTravelResponse;

  const [theArea, setTheArea] = useState(null)
  const [journeyId, setJourneyId] = useState(null)
  const [route, setRoute] = useState(null)
  const [expiresDate, setExpiresDate] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [shape, setShape] = useState([])
  const [markers, setMarkers] = useState([])
  const [deleteCurrentTravel, setDeleteCurrentTravel] = useState(false)

  useEffect(() => {
    if (location.state) {
      setTheArea(JSON.parse(location.state.theArea))
      setJourneyId(location.state.journeyId)
    } else {
      history.replace('/')
    }
  },[location])

  useEffect(() => {
    if (deleteCurrentTravel) {
      history.replace('/')
      setDeleteCurrentTravel(false)
    }
  }, [deleteCurrentTravel])

  useEffect(() => {
    if (travelResponse && journeyId) {
      if (travelResponse.journeys) {
        setRoute(travelResponse.journeys.find(j => j.id = journeyId))
      } else {
        history.replace('/')
      }
    }
  }, [travelResponse, journeyId])

  useEffect(() => {
    if (route) {

      const {expiresDate, startDate} = route.meta
      const shape = JSON.parse(route.geometry)
      const [departureLon, departureLat] = JSON.parse(route.departure ?? route.userDeparture).coordinates
      const [arrivalLon, arrivalLat] = JSON.parse(route.arrival ?? route.userArrival).coordinates
        , [userDepartureLon, userDepartureLat] = JSON.parse(route.userDeparture ?? route.departure).coordinates
        , [userArrivalLon, userArrivalLat] = JSON.parse(route.userArrival ?? route.arrival).coordinates

        , markers = [
            {'latitude': userDepartureLat, 'longitude': userDepartureLon},
            {'latitude': departureLat, 'longitude': departureLon},
            {'latitude': arrivalLat, 'longitude': arrivalLon},
            {'latitude': userArrivalLat, 'longitude': userArrivalLon}
          ]

      setExpiresDate(expiresDate)
      setStartDate(startDate)
      setShape(shape)
      setMarkers(markers)
    }
  },[route])

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment()
      const endMoment = moment(expiresDate)

      if(now.isAfter(endMoment)){
        clearInterval(interval)

        alert.error(translate(`errors.timeExpired`), {
          title: translate('general.advise'),
          closeCopy: translate('general.ok'),
          onClose: () => history.replace('/')
        })
      }
    }, 1000)

    return () => clearInterval(interval);
  }, [expiresDate]);

  const bookingRequest = async() => {
    if(isLoggedIn){
      const response = await Api.fetch(
        Api.clean(Api?.urls?.drt?.cart?.add?.url),
        {
          'method': 'POST',
          'body': {
            'journeyId': route.id,
            startDate
          }
        })

      if(response.err){
        alert.error(translate('errors.general'), {
          title: translate('general.advise'),
          closeCopy: translate('general.ok')
        });
        return
      }

      if(currentTravelResponse?.cart?.cartId !== response.cartId){
        setCurrentTravelResponse({
          ...currentTravelResponse,
          'cart': response
        })
      }

      history.push('/cart', {
        journeyId,
        'travelId': travelResponse.id,
        'area': JSON.stringify(theArea)
      })
    }
  }

  const onCancelClick = () => {
    setDeleteCurrentTravel(true)
  }


  const timeClearance = theArea?.properties?.confs?.travelsSearchConfiguration?.timeClearance

  return (
    <div className="p-route">
      {
        route &&
          <>
            <div className="p-route__sidebar">
              <RouteDetailsBox
                isMapVisible={true}
                screen={screen}
                route={route}
                onClick={bookingRequest}
                translate={translate}
                icons={icons}
                hideAction={purchased}
                discount={cart?.discount}
                onCancelClick={onCancelClick}
                timeClearance={timeClearance}
              />
            </div>
            <div className="p-route__map">
              <RouteMap
                theArea={theArea}
                shape={shape.geometries}
                markers={markers}
              />
            </div>

          </>
      }
      <Prompt
        when={deleteCurrentTravel}
        message={JSON.stringify({
          text: translate('travel.journey.delete.title') + '\n' + translate('travel.journey.delete.message'),
          travelId: travelResponse.id
        })}
      />
    </div>
  )
})
