const { detect } = require('detect-browser');
const browser = detect() || {};

const getBrowserInfo = () => {
  return {
    name: browser.name,
    version: browser.version,
    os: browser.os,
  }
}

export const log = ({tenant, channel}) => {
  return {
    'log': (category, value, info = '') => {

        const data = {
          tenant,
          channel,
          'date': new Date(),
          'browser': getBrowserInfo(),
          'log': {
            category,
            value,
            info,
          },
        };

      console.log(data);

      //TODO send log to server
    }
  }
}
