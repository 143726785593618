// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'home': {
        'title': 'Ciao {{username}}!',
        'subtitle': 'Scegli dove viaggiare:',
        'find': 'Cerca di nuovo',

        'card': {
            'select': 'Scegli',
            'info': 'Info sui servizi'
        }
    },
    'data': {
        'title': 'Stai cercando a {{place}}',
        'leaveNow': 'Parti il prima possibile',
        'select': {
            'date': 'Seleziona la data',
            'time': 'Seleziona orario',
            'departure': 'Scegli origine',
            'arrival': 'Scegli destinazione',
            'noResults': 'Nessun risultato'
        },
        'passengers': {
            'title': 'Passeggeri',
            'childs': 'Ogni viaggiatore ha la possibilità di far viaggiare con sé gratuitamente un bambino di altezza inferiore al metro.',
            'disabled': 'Indica il numero di passeggeri con difficoltà motorie che necessitano di un mezzo con pedana.',
            'total': 'Il numero massimo è di {{limit}} viaggiatori per un\'unica soluzione'
        }
    },
    'journey': {
        'duration': 'Durata',
        'code': {
            'title': 'Codice Prenotazione',
            'message': 'Mostra il QR code all\'autista quando sali '
        },
        'delete': {
            'title': 'Cancella Proposta',
            'message': 'Sicuro di voler annullare la prenotazione?',
            'error': 'Il viaggio selezionato non può essere cancellato. Controlla la politica di annullamento di un viaggio, nella pagina di dettaglio.',
            'info': 'Un viaggio può essere cancellato fino a {{time}} prima della partenza.',
            'info2': 'In caso di pagamento anticipato, verrai rimborsato solo se cancelli un viaggio prima di {{time}} prima della partenza.'
        },

    },
    'result': {
        'ops': 'Ops...',
        'noresult': 'Attenzione - Non abbiamo trovato una soluzione puntuale come da tua richiesta',
        'alternatives': 'Le nostre alternative:'
    },
    'cart': {
        'voucher': {
            'add': 'Aggiungi Codice Promozionale',
            'info': 'Se hai un abbonamento al trasporto pubblico inserisci il codice promo dedicato',
            'ok': 'Voucher applicato con successo',
            'error': 'Voucher non valido oppure già applicato a questo carrello',
            'notset': 'E\' stato modificato il campo codice promozionale? Sicuro di voler procedere senza aggiungerlo?'
        },
        'data': {
            'reference': {
                'empty': 'completa i dati del passeggero di riferimento',
                'add': {
                    'name': 'Inserisci il nome del passeggero di riferimento',
                    'phone': 'Inserisci il telefono del passeggero di riferimento'
                }
            },
            'passengers': {
                'empty': 'completa i dati degli altri passeggeri',
                'title': 'Altri Passeggeri'

            }
        }
    },
    'payment': {
        'continue': 'Procedi con il pagamento',
        'cancel': 'Hai annullato il pagamento',
        'onboard': {
            'default': 'Paga a bordo',
            'surcharge': 'Paga a bordo (+ {{surcharge}} {{currency}})',
            'conditions': '(Verifica condizioni su '
        },
        'pay': 'Procedi con il pagamento',
        'purchaseOk': {
            'title': 'Congratulazioni',
            'message': 'Hai acquistato il tuo titolo di viaggio.'
        }
    },
    'booking': {
        'details': 'Dettaglio prenotazione',
        'notPaid': 'Da pagare',
        'paynow': {
            'action': 'Paga ora',
            'message': 'Il titolo di viaggio non è ancora stato pagato. Puoi pagarlo ora, oppure pagarlo a bordo.'
        }
    }
}
