import React, {useState, useEffect, useContext} from 'react';
import { observer } from 'mobx-react-lite'
import { Modal } from 'react-bootstrap'

import TermsConditions from '../components/TermsConditions'

export const CheckTncs = observer((deps) => {
  const {
    components,
    contexts,
    utils
  } = deps

  const [tncs, setTncs] = useState([])
  const {Api} = utils
  const {AuthContext} = contexts
  const {needTncsCheck, isLoggedIn} = useContext(AuthContext)

  useEffect(() => {
    if(isLoggedIn && needTncsCheck && tncs.length === 0){
      Api.fetch(
        Api.clean(Api?.urls?.drt?.tncs?.url),
        {
          'method': 'GET'
        },
        res => {console.log("...>", res); setTncs(res)}
        )
      }
  }, [needTncsCheck, isLoggedIn])

  return (
    <Modal
      show={isLoggedIn && needTncsCheck}
      size="lg"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        <div className="p-checkTncs">
          <TermsConditions
            components={components}
            contexts={contexts}
            utils={utils}
            tncs={tncs}
            setExternalTncs={setTncs}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
})
