import React, {useState, useEffect, useContext} from 'react'
import { observer } from 'mobx-react-lite'
import {
  useHistory,
  useLocation,
  Prompt
} from "react-router-dom"
import {
  Container,
  Button
} from 'react-bootstrap'

import RouteBox from '../components/RouteBox'

const { v4: uuidv4 } = require('uuid')

export const RouteAlternatives = observer(({
  contexts,
  components,
  utils
}) => {
  const history = useHistory()
  const location = useLocation()

  const {AuthContext} = contexts
  const {Api, translate} = utils
  const {currentTravelResponse} = useContext(AuthContext)
  const {'travel': travelResponse} = currentTravelResponse
  const {journeys = []} = travelResponse

  const [theArea, setTheArea] = useState(null)
  const [travelId, setTravelId] = useState(null)
  const [deleteCurrentTravel, setDeleteCurrentTravel] = useState(false)

  useEffect(() => {
    if (location.state) {
      setTheArea(JSON.parse(location.state.theArea))
      setTravelId(location.state.travelId)
    } else {
      history.replace('/')
    }
  },[location])

  useEffect(() => {
    if (deleteCurrentTravel) {
      history.replace('/')
      setDeleteCurrentTravel(false)
    }
  }, [deleteCurrentTravel])

  const selectRoute = theJourney => history.replace('/route-details', {
    'theArea': JSON.stringify({...theArea}),
    journeyId: theJourney.id,
    travelId
  })

  const onCancelClick = () => {
    setDeleteCurrentTravel(true)
  }

  return (
    <div className="p-route">
      <Container className="is-small">
        <div className="text-center">
          <h3 className="text-muted">{translate('travel.result.ops')}</h3>
          <h5>{translate('travel.result.noresult')}</h5>
          <br/>
          <h6>{translate('travel.result.alternatives')}</h6>

          {
            journeys?.map(r =>
              <RouteBox
                key={uuidv4()}
                translate={translate}
                route={r}
                onClick={() => selectRoute(r)}
              />
            )
          }

          <Button
            variant="secondary"
            className="has-min-width"
            onClick={onCancelClick}
          >
            {translate('general.cancel')}
          </Button>
        </div>
      </Container>

      <Prompt
        when={deleteCurrentTravel}
        message={JSON.stringify({
          text: translate('travel.journey.delete.title') + '\n' + translate('travel.journey.delete.message'),
          travelId: travelResponse.id
        })}
      />
    </div>
  );
})
