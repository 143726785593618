// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'title': 'Error',
    'general': 'An error occurred during the procedure',
    'signin': {
        'password': 'Incorrect username and / or password'
    },
    'signup': {
        'data': 'Check the fields entered'
    },
    'payment': 'An error occurred during the payment',
    'forgotPassword': 'Processing not performed. Verify the correctness of the data entered',
    'noRides': 'No runs with the selected parameters were found',
    'password': [
        'The password must contain at least 6 characters.',
        'The password cannot contain sequences of three or more repeated characters.',
        'The password must contain at least one capital letter.',
        'The password must contain at least one number.',
        'The password must contain at least one special character.'
    ],
    'noAreas': 'Something went wrong, we couldn\'t find any active service areas',
    'noTravels': 'No travels found.Change the parameters and try again.',
    'timeExpired': 'The time limit for purchasing the ticket has expired. Do a new search.',
    'areaPointsOutbounds': 'The chosen point of origin and / or destination is not within the selected area. Please check the fields and try again.',
    'notReachableByRoad': 'The chosen point of origin and / or destination is too far from a road. Please check the fields and try again.',
    'travelPassengersInvalid': 'The number of passengers selected is above the limit.',
    'phone': "The telephone number must be in the format:\n +391234567890",
    'pageNotFound': "Oops! Page not found"
}
