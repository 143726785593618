import React, { useState, useContext} from 'react';
import {
  Button,
  Container,
  Form
} from 'react-bootstrap'
import { AlertContext } from '../context/alert'

const FormForgotPassword = ({
	utils,
  onComplete,
  onBackLogin
}) => {
  const alert = useContext(AlertContext)

	const {translate, Api} = utils
	const [email, setEmail] = useState('')

  const forgotPasswordHandler = () => {
    Api.fetch(
      Api?.urls?.drt?.users['forgot-password'].url,
      {
        'method': 'POST',
        'body': {
          'email': email.toLowerCase()
        }
      }, (res) => {
      if (res.err){
        alert.error(translate('errors.forgotPassword'), {
          title: translate('errors.title'),
          closeCopy: translate('general.ok')
        })
      } else {
        alert.success(translate('login.signin.forgotPassword.recovery'), {
          title: translate('login.signin.forgotPassword.title'),
          closeCopy: translate('general.ok'),
          onClose: onComplete
        })
      }
    });
  }

	return (
    <Container className="is-small">
      <h4>{translate('login.signin.forgotPassword.title')}</h4>

      <Form.Group controlId="formGroupEmail">
        <Form.Label>{translate('login.general.email')}</Form.Label>
        <Form.Control
          type="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Group>

      <div className="text-center">
        <Button onClick={forgotPasswordHandler} className="has-min-width">
          {translate('general.continue')}
        </Button>
      </div>

      {
        onBackLogin &&
          <div className="text-center mt-3">
            <Button
              variant="dark"
              onClick={onBackLogin}
              className="has-min-width"
            >
              Torna al login
            </Button>
          </div>
      }
    </Container>
	)
}

export default FormForgotPassword
