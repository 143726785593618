import React from 'react';
import ReactDOM from 'react-dom';
import { AlertProvider } from './context/alert'

// eslint-disable-next-line no-unused-vars
import {i18n} from './i18n'
import App from './App';

ReactDOM.render(
  <AlertProvider>
    <App />
  </AlertProvider>,
  document.getElementById('root')
);
