import React, {useState, useContext, useEffect} from 'react'
import moment from "moment";
import { observer } from 'mobx-react-lite'
import { AlertContext } from '../context/alert'
import {useTranslation} from 'react-i18next'
import {
  useParams,
  useHistory
} from "react-router-dom"
import {
  Button,
  ButtonGroup,
  Col,
  Form,
  InputGroup,
  Row,
  OverlayTrigger,
  Tooltip
} from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import DatePicker from "react-datepicker"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCrosshairs,
  faInfoCircle,
  faRandom,
  faSpinner
} from '@fortawesome/free-solid-svg-icons'
import {
  MapContainer,
  TileLayer,
  Polygon,
  useMapEvents,
  Circle,
  GeoJSON
} from 'react-leaflet'

import QuantityPicker from '../components/QuantityPicker'
import DraggableMarker from '../components/DraggableMarker'
import ModalAuth from '../components/ModalAuth'

const convertPlaceIntoGeoJSON = ({latitude,longitude}) => ({
  "type": "Point",
  "coordinates": [longitude, latitude]
})

export const Travel = observer(({
  componentId,
  componentName,
  contexts,
  utils,
  components,
  config,
}) => {

  console.log('render travel')

  const { areaId } = useParams()

  const {AuthContext, DeviceContext} = contexts
  const {loading, Alert} = components
  const {Api, navigation, translate, libs} = utils
  const {icons, geolocation} = libs

  const {isLoggedIn, language, currentTravelResponse = {}, token} = useContext(AuthContext)
  const {origin, destination, tenant, setOrigin, setDestination} = useContext(DeviceContext)

  const [maxSearchableDays, setMaxSearchableDays] = useState(0)

  const [responseTravelId, setResponseTravelId] = useState()
  const [leaveNow, setLeaveNow] = useState(false)
  const [adults, setAdults] = useState(1)
  const [childs, setChilds] = useState(0)
  const [disabled, setDisabled] = useState(0)

  const [travelDate, setTravelDate] = useState(moment())
  const travelDateTypeOptions = ['departure', 'arrival']

  const [travelDateTypeSelected, setTravelDateTypeSelected] = useState(0)

  const [theArea, setTheArea] = useState(null)
  const [translatedData, setTranslatedData] = useState(null)
  const [travelResponse, setTravelResponse] = useState(null)
  const [limits, setLimits] = useState(null)
  const [mapConf, setMapConf] = useState(null)
  const [originPlace, setOriginPlace] = useState(null)
  const [originPlaceTextInput, setOriginPlaceTextInput] = useState([])
  const [destinationPlace, setDestinationPlace] = useState(null)
  const [destinationPlaceTextInput, setDestinationPlaceTextInput] = useState([])
  const [refreshDestinationForm, setRefreshDestinationForm] = useState(false)
  const [showAuthModal, setShowAuthModal] = useState(false)
  const [geolocationOriginLoader, setGeolocationOriginLoader] = useState(false)
  const [geolocationDestinationLoader, setGeolocationDestinationLoader] = useState(false)
  const history = useHistory()
  const alert = useContext(AlertContext)
  const {i18n} = useTranslation()



  function MapsEvents() {
    const map = useMapEvents({
      click: e => {
        const coords = e.latlng
        const theRegion = {
          latitude: coords.lat,
          longitude: coords.lng,
        }

        if (!originPlace) {
          reverseGeocode(theRegion, 'origin')
        } else if (!destinationPlace) {
          reverseGeocode(theRegion, 'destination')
        }
      }
    })
    return null
  }

  useEffect(() => {
    if (tenant && tenant.areas) {
      const theArea = tenant?.areas.features.find(a => a?.properties?.id === areaId)
      const {translations, 'confs': areaConfs} = theArea?.properties
      const translatedData = translations[language] ?? translations.it

      const limits = areaConfs?.travelsSearchConfiguration?.passengerLimits || {
        'adults': 99,
        'childs': 99,
        'disabled': 99,
        'total': 99
      }

      setMaxSearchableDays(areaConfs?.travelsSearchConfiguration?.maxSearchableDays || 0)
      setTheArea(theArea)
      setTranslatedData(translatedData)
      setLimits(limits)
    }
  }, [areaId, language, tenant])

  useEffect(() => {
    if(currentTravelResponse != null){
      const {'travel': travelResponse} = currentTravelResponse
      setTravelResponse(travelResponse)
    }
  }, [currentTravelResponse])

  useEffect(() => {
    if (theArea) {
      const {'centerPoint': areaCenterPoint, 'geometry': areaGeometry, 'bbox': areaBbox} = theArea
      const [areaCenterLongitude, areaCenterLatitude] = areaCenterPoint?.coordinates

      setMapConf({
        center: [areaCenterLatitude, areaCenterLongitude],
        centerPoint: areaCenterPoint,
        geometry: areaGeometry,
        box: areaBbox
      })
    }
  }, [theArea])

  useEffect(() => {
    if (travelResponse && travelResponse.id === responseTravelId){
      setResponseTravelId(undefined);
      loading.set(false);
      const {journeys = [], meta, responseStatus} = travelResponse;

      if(responseStatus === false || journeys.length === 0){
        alert.error(translate(`errors.noTravels`), {
          title: translate('general.advise'),
          closeCopy: translate('general.ok')
        })
        return;
      }

      const {parametersRespected} = meta;

      if(parametersRespected === true){
        history.push('/route-details', {
          'theArea': JSON.stringify({...theArea}),
          'journeyId': journeys[0].id,
          'travelId': travelResponse.id
        })
      }
      else {
        history.push('/route-alternatives', {
          'theArea': JSON.stringify({...theArea}),
          'travelId': travelResponse.id
        })
      }
    }
  }, [travelResponse, responseTravelId, history]);

  const changeTravelDate = value => {
    const newDate = moment(value);
    newDate.set('hour', travelDate.get('hour'))
    newDate.set('minute', travelDate.get('minute'))
    setTravelDate(newDate);
  }

  const changeTravelTime = value => {
    const newDate = moment(value);
    const theDate = moment(travelDate);
    theDate.set('hour', newDate.get('hour'))
    theDate.set('minute', newDate.get('minute'))
    setTravelDate(theDate);
  }

  const checkForm = () => {
    if(adults === 0 && disabled === 0){
      return false;
    }

    if(!origin || !destination){
      return false;
    }

    const totPass = adults + childs + disabled;

    if(limits && limits.total && totPass > limits.total){
      return false;
    }

    return true;
  }

  const showInfo = type => {
    let message = translate(`travel.data.passengers.${type}`, {'limit': 0});

    if(type === 'total'){
      message = translate(`travel.data.passengers.${type}`, {'limit': `${limits?.total ?? 0}`});
    }

    return <Tooltip>{message}</Tooltip>
  }

  const getTravels = async() => {
    setResponseTravelId(undefined);

    if(isLoggedIn){
        loading.set(true);

        if (currentTravelResponse?.travel?.id) {
          const response = await Api.fetch(
            Api.clean(Api?.urls?.drt?.travel?.delete?.url),
            {
              'method': 'POST',
              'body': {
                'travelId': currentTravelResponse.travel.id
              }
            }
          );

          if(response.err){
            loading.set(false);

            let message = translate('errors.general');

            if(response?.errMsg?.message === 'ticket.cannot.refund'){
              message = translate('travel.journey.delete.error')
            }

            alert.error(message, {
              title: translate('general.advise'),
              closeCopy: translate('general.ok')
            });

            return;
          }
        }

        const response = await Api.fetch(
          Api.clean(Api?.urls?.drt?.travel?.journeys?.url),
          {
            'method': 'POST',
            'headers': {
              'platform': 'web'
            },
            'body': {
              areaId,
              leaveNow,
              'travelDate': travelDate.toDate(),
              'travelDateMode': travelDateTypeOptions[travelDateTypeSelected],
              'origin': convertPlaceIntoGeoJSON(origin),
              'destination': convertPlaceIntoGeoJSON(destination),
              'passengers': {
                adults,
                childs,
                disabled
              }
            }
          }
        );

        if(response.err || !response.travelId){
          console.log(response);
          loading.set(false);

          let message = '';

          switch(response.errMsg?.message){
            case 'area.points.outbounds':
              message = translate('errors.areaPointsOutbounds');
              break;
            case 'not-reachable-by-road':
              message = translate('errors.notReachableByRoad');
              break;
            case 'travel.passengers.invalid':
              message = translate('errors.travelPassengersInvalid');
              break;
            default:
              message = translate('errors.noTravels')
          }

          alert.error(message, {
            title: translate('general.advise'),
            closeCopy: translate('general.ok')
          })

          return
        }

        console.log(response.travelId)
        // TODO: change route?
        setResponseTravelId(response.travelId);
    }
    else {
      // history.push('/login')
      setShowAuthModal(true)
    }
  }

  const revertOriginAndDestination = () => {
    const prevOriginPlace = originPlace ? {...originPlace} : null
    const prevDestinationPlace = destinationPlace ? {...destinationPlace} : null

    setRefreshDestinationForm(true)
    setOrigin(prevDestinationPlace)
    setOriginPlace(prevDestinationPlace)
    setOriginPlaceTextInput([prevDestinationPlace.fullAddress])
    setDestination(prevOriginPlace)
    setDestinationPlace(prevOriginPlace)
    setDestinationPlaceTextInput([prevOriginPlace.fullAddress])

    setTimeout(() => {
      setRefreshDestinationForm(false)
    },1)
  }

  const searchText = (value, type) => {
    geolocation.search(areaId, {'text': value, 'bbox': mapConf.box, 'center': mapConf.centerPoint}, ([thePlace]) => {
      const {latitude, longitude} = thePlace || {}

      const mapCenterLocation = {
        latitude: mapConf.center[0],
        longitude: mapConf.center[1],
        latitudeDelta: 0.15,
        longitudeDelta: 0.0421,
      }

      const newRegion = {
        ...mapCenterLocation,
        latitude,
        longitude
      }

      if (type === 'origin') {
        setOriginPlace({
          ...thePlace,
          'region': newRegion
        });
      } else if (type === 'destination') {
        setDestinationPlace({
          ...thePlace,
          'region': newRegion
        });
      }
    })
  }

  const reverseGeocode = (theRegion, type) => {
    geolocation.reverseGeocode(areaId, theRegion.latitude, theRegion.longitude, thePlace => {
      // setMapRegionLoading(false);
      const { fullAddress } = thePlace

      if (type === 'origin') {
        setOriginPlace({
          ...thePlace,
          'region': theRegion
        });

        setOrigin({
          ...thePlace,
          'region': theRegion
        })

        setOriginPlaceTextInput([fullAddress])
      } else if (type === 'destination') {
        setDestinationPlace({
          ...thePlace,
          'region': theRegion
        });

        setDestination({
          ...thePlace,
          'region': theRegion
        })

        setDestinationPlaceTextInput([fullAddress])
      }
    })
  }

  const getUserPosition = type => {
    if (type === 'origin') {
      setGeolocationOriginLoader(true)
    } else if (type === 'destination') {
      setGeolocationDestinationLoader(true)
    }

    try {
      geolocation.get(position => {
        if (position) {
          const {latitude, longitude, fullAddress} = position

          const mapCenterLocation = {
            latitude: mapConf.center[0],
            longitude: mapConf.center[1],
            latitudeDelta: 0.15,
            longitudeDelta: 0.0421,
          }

          const newRegion = {
            ...mapCenterLocation,
            latitude,
            longitude
          }

          if (type === 'origin') {
            setGeolocationOriginLoader(false)
            setOriginPlace({
              ...position,
              'region': newRegion
            });
            setOrigin({
              ...position,
              'region': newRegion
            });
            setOriginPlaceTextInput([fullAddress])
          } else if (type === 'destination') {
            setGeolocationDestinationLoader(false)
            setDestinationPlace({
              ...position,
              'region': newRegion
            });
            setDestination({
              ...position,
              'region': newRegion
            });
            setDestinationPlaceTextInput([fullAddress])
          }
        }
      })
    } catch (e) {
      if (type === 'origin') {
        setGeolocationOriginLoader(false)
      } else if (type === 'destination') {
        setGeolocationDestinationLoader(false)
      }
    }
  }

  //calcolate Minumum date based on datepicker
  const now = moment()
  const travelDateIsToday = moment(travelDate).isSame(now, "day")
  const maxDateInDatePicker = moment().add(Number(maxSearchableDays), 'days').toDate();

  let minimumDateInDatePicker = now.toDate();
  let minimumTimeInDatePicker = now.toDate();
  let maxTimeInDatePicker = now.subtract(1,'days').endOf('day').toDate();

  return (
    <>
      <div className="p-travel">
        <div className="p-travel__sidebar">
          <h4 className="text-primary">{translate('travel.data.title', {'place': translatedData?.name})}</h4>

          <Form.Group>
            <Form.Check
              type="switch"
              id="leave-now-switch"
              label={translate('travel.data.leaveNow')}
              checked={leaveNow}
              onChange={() => setLeaveNow(!leaveNow)}
            />
          </Form.Group>

          {
            !leaveNow &&
              <>
                <Form.Group>
                  <ButtonGroup size="sm">
                  {
                    travelDateTypeOptions.map((o, index) => {
                      return (
                        <Button
                          disabled={leaveNow}
                          active={index === travelDateTypeSelected}
                          onClick={() => setTravelDateTypeSelected(index)}
                        >
                          {translate(`general.${o}`)}
                        </Button>
                      )
                    })
                  }
                  </ButtonGroup>
                </Form.Group>

                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>{translate('general.date')}</Form.Label>
                      <DatePicker
                        locale={i18n.language}
                        selected={travelDate.toDate()}
                        disabled={leaveNow}
                        dateFormat="dd MMMM yyyy"
                        onChange={date => changeTravelDate(date)}
                        minDate={minimumDateInDatePicker}
                        maxDate={maxDateInDatePicker}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>{translate('general.time.time')}</Form.Label>
                      <DatePicker
                        locale={i18n.language}
                        selected={travelDate.toDate()}
                        disabled={leaveNow}
                        dateFormat="HH:mm"
                        onChange={date => changeTravelTime(date)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={1}
                        minTime={travelDateIsToday ? minimumTimeInDatePicker : false}
                        maxTime={travelDateIsToday ? maxTimeInDatePicker : false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
          }

          <div className="c-destinationForm">
            <div className="c-destinationForm__inputs">
              {
                !refreshDestinationForm &&
                  <>
                    <Form.Group>
                      <Form.Label>{translate('general.departure')}</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <Button
                            variant="outline-dark"
                            onClick={() => getUserPosition('origin')}
                          >
                            {
                              geolocationOriginLoader
                                ? <FontAwesomeIcon icon={faSpinner} spin={true} />
                                : <FontAwesomeIcon icon={faCrosshairs} />
                            }
                          </Button>
                        </InputGroup.Prepend>
                        <AsyncTypeahead
                          id="origin-textInput"
                          isLoading={false}
                          selectHintOnEnter={true}
                          filterBy={() => true}
                          minLength={1}
                          emptyLabel={translate('travel.data.select.noResults')}
                          selected={originPlaceTextInput}
                          onSearch={value => { searchText(value, 'origin') }}
                          onChange={value => {
                            setOriginPlaceTextInput(value)
                            setOrigin(originPlace)
                          }}
                          placeholder={translate('travel.data.select.departure')}
                          options={originPlace && originPlace.fullAddress ? [originPlace.fullAddress] : []}
                        />
                      </InputGroup>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{translate('general.arrival')}</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <Button
                            variant="outline-dark"
                            onClick={() => getUserPosition('destination')}
                          >
                            {
                              geolocationDestinationLoader
                                ? <FontAwesomeIcon icon={faSpinner} spin={true} />
                                : <FontAwesomeIcon icon={faCrosshairs} />
                            }
                          </Button>
                        </InputGroup.Prepend>
                        <AsyncTypeahead
                          id="destination-textInput"
                          isLoading={false}
                          selectHintOnEnter={true}
                          filterBy={() => true}
                          minLength={1}
                          selected={destinationPlaceTextInput}
                          emptyLabel={translate('travel.data.select.noResults')}
                          onSearch={value => { searchText(value, 'destination') }}
                          onChange={value => {
                            setDestinationPlaceTextInput(value)
                            setDestination(destinationPlace)
                          }}
                          placeholder={translate('travel.data.select.arrival')}
                          options={destinationPlace && destinationPlace.fullAddress ? [destinationPlace.fullAddress] : []}
                        />
                      </InputGroup>
                    </Form.Group>
                  </>
              }
            </div>
            <div className="c-destinationForm__switch">
              <Button
                variant="outline-dark"
                onClick={revertOriginAndDestination}
                disabled={!origin && !destination}
              >
                <FontAwesomeIcon icon={faRandom} />
              </Button>
            </div>
          </div>

          <p className="text-primary">
            {translate('travel.data.passengers.title')}
            {' '}
            <OverlayTrigger
              trigger={['hover', 'focus']}
              placement="top"
              overlay={showInfo('total')}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
            </OverlayTrigger>
          </p>

          <QuantityPicker
            label={translate('general.passengers.adults')}
            currentValue={adults}
            maxValue={limits?.adults}
            onChange={value => setAdults(value)}
          />

          <QuantityPicker
            label={translate('general.passengers.childs')}
            currentValue={childs}
            maxValue={limits?.childs}
            onChange={value => setChilds(value)}
          />

          <QuantityPicker
            label={translate('general.passengers.disabled')}
            currentValue={disabled}
            maxValue={limits?.disabled}
            onChange={value => setDisabled(value)}
            showInfo={() => showInfo('disabled')}
          />

          <div className="text-center mt-2">
            <Button
              className="has-min-width"
              disabled={!checkForm()}
              onClick={getTravels}
            >
              {translate('general.search')}
            </Button>
          </div>
        </div>

        <div className="p-travel__map">
          {

            mapConf &&
              <MapContainer
                center={mapConf.center}
                zoom={12}
                scrollWheelZoom={true}
              >
                <MapsEvents/>

                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  //url="https://{s}.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day.grey/{z}/{x}/{y}/512/png8?apiKey=NB4mqpiwnodCU6gbc6GdgSQihrpnjxY_nk-b5o2J9y4&ppi=320"
                  url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
                  subdomains='abc'
                />
                { 
                theArea.properties['fill-opacity'] = 0.15}
                <GeoJSON key='my-area' className="secondary-color" data={theArea} />

                {
                  theArea?.properties?.stops?.features?.length > 0 ?
                    theArea?.properties?.stops?.features.map(({geometry}) => <>
                      <Circle
                        color="#ff5e00"
                        radius={10}
                        center={[
                          geometry.coordinates[1],
                          geometry.coordinates[0]
                        ]}
                      />
                    </>)
                  : <></>
                }

                {
                  originPlace
                  && originPlace.region.latitude
                  && originPlace.region.longitude &&
                    <DraggableMarker
                      color="primary"
                      position={[
                        originPlace.region.latitude,
                        originPlace.region.longitude
                      ]}
                      onDragEnd={theRegion => reverseGeocode(theRegion, 'origin')}
                    />
                }

                {
                  destinationPlace
                  && destinationPlace.region.latitude
                  && destinationPlace.region.longitude &&
                    <DraggableMarker
                      color="secondary"
                      position={[
                        destinationPlace.region.latitude,
                        destinationPlace.region.longitude
                      ]}
                      onDragEnd={theRegion => reverseGeocode(theRegion, 'destination')}
                    />
                }
              </MapContainer>
          }
        </div>
      </div>

      <ModalAuth
        contexts={contexts}
        components={components}
        utils={utils}
        show={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        onLogin={() => setShowAuthModal(false)}
      />
    </>
  )
})
