// eslint-disable-next-line import/no-anonymous-default-export
export default {
    'menu': {
        'profile': {
            'title': "Profilo",
            'data': "Email e Dati Personali",
            'security': "Password e Accesso Rapido",
            'preferences': "Preferenze"
        },
        'payments': {
            'title': "Pagamenti",
            'history': "Storico acquisti",
            'credit': "Credito",
            'cards': "Abbonamenti e carte vantaggi"
        },
        'general': {
            'title': "OpenMove",
            'advise': "Avvisi",
            'gift': "Regala",
            'info': "Info e Supporto",
            'stats': "Statistiche",
            'logout': "Esci"
        }
    },

    'preferences': {
        'validationMethod': {
            'title': 'Metodo di validazione',
            'message': 'Non hai impostato nessun metodo di validazione preferito. Potrai farlo alla prossima validazione di un titolo di viaggio.',
            'select': 'Seleziona un metodo di validazione predefinito. Renderai più veloce la fase di validazione del tuo titolo di viaggio.',
            'info': [
                'Se hai un metodo di validazione predefinito premendo il pulsante "Valida" all\'interno del titolo di viaggio verrà proposto direttamente.',
                'Premendo a lungo, sempre sul pulsante "Valida" all\'interno del titolo di viaggio, comparirà la scelta per selezionare un alternativo metodo di validazione.'
            ]
        },
        'theme': {
            'title': 'Tema',
            'message': 'Seleziona il tema con cui vuoi utilizzare OpenMove',
            'select': 'Seleziona il tema con cui vuoi utilizzare OpenMove',
            'info': [
                'In modalità AUTO OpenMove utilizza il tema del tuo dispositivo.',
                'Altrimenti puoi cambiarlo manualmente selezionando la modalità LIGHT oppure DARK.'
            ],
            'auto': 'Auto',
            'light': 'Light',
            'dark': 'Dark'
        }
    },

    'credit': {
        'title': 'Credito',
        'info': [
            'In questa sezione dell\'app puoi gestire il tuo credito e le carte associate al tuo account.',
            'Il nostro provider di pagamento è Banca Intesa ..., usiamo la migliore tecnologia perchè i tuoi dati siano al sicuro. ....',
            'Metodi di pagamento accettati:'
        ],
        'bonus': {
            'title': 'Credito bonus',
            'info': [
                'OpenMove per effettuare i pagamenti utilizza, se disponibile, il credito bonus.',
                'In caso di credito non sufficiente, la differenza verrà pagata attraverso la carta associata all\'account.'
            ]
        },
        'card': {
            'title': 'Carta',
            'add': 'Aggiungi carta',
            'delete': {
                'title': 'Scollega carta',
                'message': 'Vuoi eliminare la carta associata al tuo account?'
            },
            'info': [
                'Usiamo la migliore tecnologia per assicurarti che i dati della tua carta di credito siano al sicuro.',
                'Metodi di pagamento accettati:'
            ]
        },
        'transactions': {
            'title': 'Transazioni'
        }
    },
    'benefits': {
        'title': 'Abbonamenti e carte vantaggi',
        'info': [
            'In questa sezione puoi gestire le tue carte vantaggi personali, per esempio un abbonamento, per poter ottenere sconti e vantaggi nell\'utilizzo di OpenMove'
        ]
    },
    'historical': {
        'title': 'Storico acquisti',
        'info': [
            'In questa sezione troverai tutti i biglietti scaduti. In caso servisse potrai utilizzare questa lista come ricevuta.'
        ]
    },
    'about': {
        'info': 'Informazioni App',
        'guide': 'Guida all\'uso',
        'support': {
            'title': 'Contatti',
            'phone': {
                'title': 'Contatta assistenza',
                'info': [
                    "L'assistenza clienti è attiva in orario d'ufficio dalle 9:00 alle 17:00 dal Lunedì al Venerdì al numero {{phone}}"
                ],
                'error': "Si è verificato un errore nell'avvio della chiamata. Contatta manualmente l'assistenza al numero {{phone}}"
            },
            'mail': {
                'title': 'Richiedi assistenza via mail',
                'subject': 'Richiesta assistenza: ',
                'error': "Si è verificato un errore nell'invio della mail. Contatta manualmente l'assistenza all'indirizzo {{mail}}, specificando le caratteristiche del tuo dispositivo, compreso il numero di Build che trovi in questa pagina"
            }
        },
        'feedback': {
            'title': 'Invia Feedback',
            'mail': {
                'subject': 'Invio feedback: ',
                'error': "Si è verificato un errore nell'invio della mail. Contattaci manualmente all'indirizzo {{mail}}"
            }
        },
        'build': {
            'title': 'Build',
            'info': [
                'In caso di assistenza, comunicaci il numero di Build in modo da poterti aiutare al meglio'
            ]
        },
        'tnc': {
            'title': 'Termini OpenMove',
            'privacy': 'Privacy Policy',
            'terms': 'Termini del servizio'
        }
    },
    'password': {
        'change': {
            'title': 'Modifica Password',
            'message': 'La nuova password deve contenere almeno una lettera maiuscola, una minuscola, un numero e un carattere speciale',
            'ok': 'Password modificata con successo!'
        },
        'biometric': {
            'auth': 'Autenticazione',
            'buy': 'Acquisto',
            'validate': 'Validazione',
            'gift': 'Regalo',
            'info': [
                'Abilita la verifica biometrica per rendere più sicuro il tuo account.',
                'Puoi abilitarlo per le funzioni di Autenticazione, ovvero all\'avvio di OpenMove, oppure nei momenti di Acquisto, Validazione o Regalo di un titolo di viaggio'
            ]
        }
    },
    'data': {
        'email': 'Email',
        'userData': 'Dati personali',
        'name': 'Nome',
        'surname': 'Cognome',
        'language': {
            'title': 'Lingua',
            'message': 'Seleziona la lingua con cui utilizzare l\applicazione'
        },
        'birthday': 'Data di nascita',
        'nationality': {
            'title': 'Nazionalità',
            'message': 'Seleziona nazionalità'
        },
        'gender': {
            'title': 'Sesso',
            'message': 'Selezione il sesso',
            'male': 'Uomo',
            'female': 'Donna',
            'other': 'Altro',
        },
        'phone': 'Telefono',
        'cf': 'Codice fiscale',
        'address': 'Indirizzo',
        'number': 'Numero',
        'city': 'Città',
        'provice': 'Provincia',
        'country': 'Nazione',
        'zipcode': 'Codice Postale'
    },
    'mytickets': {
        'tabs': {
            'active': 'Attive',
            'passed': 'Passate',
            'saved': 'Salvate',
            'canceled': 'Annullate',
        },
        'empty': 'Nessuna corsa da visualizzare'
    },
    'phone': {
        'title': 'Verifica numero di telefono',
        'sendagain': 'Invia di nuovo',
        'code': 'Codice',
        'overview': 'Abbiamo inviato un sms con il codice di verifica al numero {{phone}}.\n Inseriscilo nella casella di testo sottostante e verifica il tuo numero di cellulare.',
        'error': 'Il codice inserito non è valido.',
        'edit': 'Modifica il numero di telefono'
    }
}
