
import React from 'react';

const originalSizes = {
  'applePay': {'width': 56, 'height': 23},
  'paypal': {'width': 124, 'height': 33},
}

export const Payments = ({name}) => {
  const {'width': originalWidth, 'height': originalHeight} = originalSizes[name] || {};

  return (
    <svg viewBox={`0 0 ${originalWidth} ${originalHeight}`} preserveAspectRatio="xMinYMin meet">
      {
        name === 'applePay' &&
          <>
            <path d="M 10.237 2.968 C 9.581 3.746 8.531 4.359 7.481 4.271 C 7.35 3.22 7.864 2.103 8.466 1.413 C 9.122 0.613 10.27 0.044 11.2 -0 C 11.309 1.095 10.883 2.169 10.238 2.968 M 11.189 4.48 C 9.669 4.392 8.367 5.345 7.645 5.345 C 6.913 5.345 5.808 4.523 4.605 4.545 C 3.041 4.567 1.586 5.454 0.788 6.867 C -0.853 9.693 0.361 13.877 1.947 16.177 C 2.723 17.316 3.653 18.564 4.878 18.52 C 6.038 18.477 6.497 17.765 7.897 17.765 C 9.308 17.765 9.713 18.52 10.938 18.499 C 12.206 18.477 13.005 17.36 13.781 16.22 C 14.667 14.928 15.028 13.669 15.05 13.603 C 15.028 13.581 12.6 12.65 12.578 9.846 C 12.556 7.502 14.492 6.385 14.58 6.32 C 13.486 4.699 11.78 4.523 11.189 4.48 M 19.972 1.303 L 19.972 18.378 L 22.619 18.378 L 22.619 12.54 L 26.283 12.54 C 29.63 12.54 31.981 10.24 31.981 6.911 C 31.981 3.581 29.673 1.303 26.37 1.303 Z M 22.619 3.538 L 25.67 3.538 C 27.967 3.538 29.28 4.764 29.28 6.922 C 29.28 9.08 27.967 10.317 25.659 10.317 L 22.619 10.317 Z M 36.816 18.51 C 38.478 18.51 40.02 17.666 40.72 16.33 L 40.775 16.33 L 40.775 18.378 L 43.225 18.378 L 43.225 9.879 C 43.225 7.415 41.256 5.827 38.227 5.827 C 35.416 5.827 33.337 7.437 33.261 9.649 L 35.645 9.649 C 35.842 8.598 36.816 7.908 38.15 7.908 C 39.769 7.908 40.677 8.663 40.677 10.054 L 40.677 10.996 L 37.373 11.193 C 34.3 11.38 32.638 12.639 32.638 14.83 C 32.638 17.042 34.355 18.51 36.816 18.51 Z M 37.527 16.483 C 36.116 16.483 35.219 15.804 35.219 14.764 C 35.219 13.69 36.083 13.066 37.734 12.968 L 40.677 12.781 L 40.677 13.745 C 40.677 15.344 39.32 16.483 37.527 16.483 Z M 46.495 23.022 C 49.077 23.022 50.291 22.036 51.352 19.046 L 56 5.991 L 53.309 5.991 L 50.192 16.078 L 50.137 16.078 L 47.02 5.991 L 44.253 5.991 L 48.737 18.422 L 48.497 19.178 C 48.092 20.459 47.436 20.952 46.266 20.952 C 46.058 20.952 45.653 20.93 45.489 20.908 L 45.489 22.956 C 45.642 23 46.298 23.022 46.495 23.022 Z" fill="rgb(0,0,0)" />
          </>
      }

      {
        name === 'paypal' &&
          <>
            <path fill="#253B80" d="M46.211,6.749h-6.839c-0.468,0-0.866,0.34-0.939,0.802l-2.766,17.537c-0.055,0.346,0.213,0.658,0.564,0.658
              h3.265c0.468,0,0.866-0.34,0.939-0.803l0.746-4.73c0.072-0.463,0.471-0.803,0.938-0.803h2.165c4.505,0,7.105-2.18,7.784-6.5
              c0.306-1.89,0.013-3.375-0.872-4.415C50.224,7.353,48.5,6.749,46.211,6.749z M47,13.154c-0.374,2.454-2.249,2.454-4.062,2.454
              h-1.032l0.724-4.583c0.043-0.277,0.283-0.481,0.563-0.481h0.473c1.235,0,2.4,0,3.002,0.704C47.027,11.668,47.137,12.292,47,13.154z"
              />
            <path fill="#253B80" d="M66.654,13.075h-3.275c-0.279,0-0.52,0.204-0.563,0.481l-0.145,0.916l-0.229-0.332
              c-0.709-1.029-2.29-1.373-3.868-1.373c-3.619,0-6.71,2.741-7.312,6.586c-0.313,1.918,0.132,3.752,1.22,5.031
              c0.998,1.176,2.426,1.666,4.125,1.666c2.916,0,4.533-1.875,4.533-1.875l-0.146,0.91c-0.055,0.348,0.213,0.66,0.562,0.66h2.95
              c0.469,0,0.865-0.34,0.939-0.803l1.77-11.209C67.271,13.388,67.004,13.075,66.654,13.075z M62.089,19.449
              c-0.316,1.871-1.801,3.127-3.695,3.127c-0.951,0-1.711-0.305-2.199-0.883c-0.484-0.574-0.668-1.391-0.514-2.301
              c0.295-1.855,1.805-3.152,3.67-3.152c0.93,0,1.686,0.309,2.184,0.892C62.034,17.721,62.232,18.543,62.089,19.449z"/>
            <path fill="#253B80" d="M84.096,13.075h-3.291c-0.314,0-0.609,0.156-0.787,0.417l-4.539,6.686l-1.924-6.425
              c-0.121-0.402-0.492-0.678-0.912-0.678h-3.234c-0.393,0-0.666,0.384-0.541,0.754l3.625,10.638l-3.408,4.811
              c-0.268,0.379,0.002,0.9,0.465,0.9h3.287c0.312,0,0.604-0.152,0.781-0.408L84.564,13.97C84.826,13.592,84.557,13.075,84.096,13.075z
              "/>
            <path fill="#179BD7" d="M94.992,6.749h-6.84c-0.467,0-0.865,0.34-0.938,0.802l-2.766,17.537c-0.055,0.346,0.213,0.658,0.562,0.658
              h3.51c0.326,0,0.605-0.238,0.656-0.562l0.785-4.971c0.072-0.463,0.471-0.803,0.938-0.803h2.164c4.506,0,7.105-2.18,7.785-6.5
              c0.307-1.89,0.012-3.375-0.873-4.415C99.004,7.353,97.281,6.749,94.992,6.749z M95.781,13.154c-0.373,2.454-2.248,2.454-4.062,2.454
              h-1.031l0.725-4.583c0.043-0.277,0.281-0.481,0.562-0.481h0.473c1.234,0,2.4,0,3.002,0.704
              C95.809,11.668,95.918,12.292,95.781,13.154z"/>
            <path fill="#179BD7" d="M115.434,13.075h-3.273c-0.281,0-0.52,0.204-0.562,0.481l-0.145,0.916l-0.23-0.332
              c-0.709-1.029-2.289-1.373-3.867-1.373c-3.619,0-6.709,2.741-7.311,6.586c-0.312,1.918,0.131,3.752,1.219,5.031
              c1,1.176,2.426,1.666,4.125,1.666c2.916,0,4.533-1.875,4.533-1.875l-0.146,0.91c-0.055,0.348,0.213,0.66,0.564,0.66h2.949
              c0.467,0,0.865-0.34,0.938-0.803l1.771-11.209C116.053,13.388,115.785,13.075,115.434,13.075z M110.869,19.449
              c-0.314,1.871-1.801,3.127-3.695,3.127c-0.949,0-1.711-0.305-2.199-0.883c-0.484-0.574-0.666-1.391-0.514-2.301
              c0.297-1.855,1.805-3.152,3.67-3.152c0.93,0,1.686,0.309,2.184,0.892C110.816,17.721,111.014,18.543,110.869,19.449z"/>
            <path fill="#179BD7" d="M119.295,7.23l-2.807,17.858c-0.055,0.346,0.213,0.658,0.562,0.658h2.822c0.469,0,0.867-0.34,0.939-0.803
              l2.768-17.536c0.055-0.346-0.213-0.659-0.562-0.659h-3.16C119.578,6.749,119.338,6.953,119.295,7.23z"/>
            <path fill="#253B80" d="M7.266,29.154l0.523-3.322l-1.165-0.027H1.061L4.927,1.292C4.939,1.218,4.978,1.149,5.035,1.1
              c0.057-0.049,0.13-0.076,0.206-0.076h9.38c3.114,0,5.263,0.648,6.385,1.927c0.526,0.6,0.861,1.227,1.023,1.917
              c0.17,0.724,0.173,1.589,0.007,2.644l-0.012,0.077v0.676l0.526,0.298c0.443,0.235,0.795,0.504,1.065,0.812
              c0.45,0.513,0.741,1.165,0.864,1.938c0.127,0.795,0.085,1.741-0.123,2.812c-0.24,1.232-0.628,2.305-1.152,3.183
              c-0.482,0.809-1.096,1.48-1.825,2c-0.696,0.494-1.523,0.869-2.458,1.109c-0.906,0.236-1.939,0.355-3.072,0.355h-0.73
              c-0.522,0-1.029,0.188-1.427,0.525c-0.399,0.344-0.663,0.814-0.744,1.328l-0.055,0.299l-0.924,5.855l-0.042,0.215
              c-0.011,0.068-0.03,0.102-0.058,0.125c-0.025,0.021-0.061,0.035-0.096,0.035H7.266z"/>
            <path fill="#179BD7" d="M23.048,7.667L23.048,7.667L23.048,7.667c-0.028,0.179-0.06,0.362-0.096,0.55
              c-1.237,6.351-5.469,8.545-10.874,8.545H9.326c-0.661,0-1.218,0.48-1.321,1.132l0,0l0,0L6.596,26.83l-0.399,2.533
              c-0.067,0.428,0.263,0.814,0.695,0.814h4.881c0.578,0,1.069-0.42,1.16-0.99l0.048-0.248l0.919-5.832l0.059-0.32
              c0.09-0.572,0.582-0.992,1.16-0.992h0.73c4.729,0,8.431-1.92,9.513-7.476c0.452-2.321,0.218-4.259-0.978-5.622
              C24.022,8.286,23.573,7.945,23.048,7.667z"/>
            <path fill="#222D65" d="M21.754,7.151c-0.189-0.055-0.384-0.105-0.584-0.15c-0.201-0.044-0.407-0.083-0.619-0.117
              c-0.742-0.12-1.555-0.177-2.426-0.177h-7.352c-0.181,0-0.353,0.041-0.507,0.115C9.927,6.985,9.675,7.306,9.614,7.699L8.05,17.605
              l-0.045,0.289c0.103-0.652,0.66-1.132,1.321-1.132h2.752c5.405,0,9.637-2.195,10.874-8.545c0.037-0.188,0.068-0.371,0.096-0.55
              c-0.313-0.166-0.652-0.308-1.017-0.429C21.941,7.208,21.848,7.179,21.754,7.151z"/>
            <path fill="#253B80" d="M9.614,7.699c0.061-0.393,0.313-0.714,0.652-0.876c0.155-0.074,0.326-0.115,0.507-0.115h7.352
              c0.871,0,1.684,0.057,2.426,0.177c0.212,0.034,0.418,0.073,0.619,0.117c0.2,0.045,0.395,0.095,0.584,0.15
              c0.094,0.028,0.187,0.057,0.278,0.086c0.365,0.121,0.704,0.264,1.017,0.429c0.368-2.347-0.003-3.945-1.272-5.392
              C20.378,0.682,17.853,0,14.622,0h-9.38c-0.66,0-1.223,0.48-1.325,1.133L0.01,25.898c-0.077,0.49,0.301,0.932,0.795,0.932h5.791
              l1.454-9.225L9.614,7.699z"/>
          </>
      }
    </svg>
  )
}
