import React, {useState, useEffect, useMemo} from 'react'
import {
  MapContainer,
  TileLayer,
  Polyline
} from 'react-leaflet'

import {latLngBounds} from 'leaflet'

import DraggableMarker from './DraggableMarker'

const RouteMap = ({
  theArea,
  markers = [],
  shape,
  isTicketDetail = false,
}) => {
  const [coordinates, setCoordinates] = useState([])
  const [initialRegion, setInitialRegion] = useState(null)

  useEffect(() => {
    if(shape && shape[0]){
      const coords = []

      for(const [longitude, latitude] of JSON.parse(JSON.stringify(shape[0])).coordinates){
        coords.push({
          latitude,
          longitude
        });
      }

      setCoordinates(coords)
    }
  }, [shape])

  useEffect(() => {
    if (theArea) {
      const {'centerPoint': areaCenterPoint} = theArea
      const [areaCenterLongitude, areaCenterLatitude] = areaCenterPoint?.coordinates

      setInitialRegion({
        latitude: areaCenterLatitude,
        longitude: areaCenterLongitude,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
      })
    }
  },[theArea])

  const getControlPointWithPoint1 = (point1, point2, length, clockwise) => {
    let angle = getAngleWithPoint1(point1, point2)
    let direction = clockwise ? 1 : -1
    let perpendicularAngle = angle + ((direction) * ((Math.PI / 2)))
    let midPoint = getMidPointWithPoint1(point1, point2)
    return {
      'longitude': midPoint.longitude + (Math.cos(perpendicularAngle) * length),
      'latitude': midPoint.latitude + (Math.sin(perpendicularAngle) * length)
    }
  }

  const getAngleWithPoint1 = (point1, point2) => {
    return Math.atan2((point2.latitude - point1.latitude), (point2.longitude - point1.longitude))
  }

  const getMidPointWithPoint1 = (point1, point2) => {
    return {
      'longitude': (point1.longitude + point2.longitude) / 2,
      'latitude': (point1.latitude + point2.latitude) / 2
    }
  }

  const plotCurve = (start, end) => {
    const distance = Math.min(Math.abs(start.longitude - end.longitude), Math.abs(start.latitude - end.latitude))
      , {'latitude': bezierY, 'longitude': bezierX} = getControlPointWithPoint1(start, end, distance, true)
      , shapePoints = [start]

    let cx, cy, t;

    for(t=0.0; t<=1; t+=0.01){
      cx = ((1-t)*(1-t)*start.longitude + 2*(1-t) * t * bezierX + t * t * end.longitude);
      cy = ((1-t)*(1-t)*start.latitude + 2*(1-t) * t * bezierY + t * t * end.latitude);
      shapePoints.push({'latitude': cy, 'longitude': cx});
    }

    shapePoints.push(end);
    return shapePoints;
  }

  const bounds = useMemo(() => {
    const b = latLngBounds() // seemed to work without having to pass init arg

    markers.forEach((coords, index) => {
      if(index === 0 || index === 3){
        b.extend([
          coords.latitude,
          coords.longitude
        ])
      }
    })
    return b
  }, [markers]);

  return (
    <div className="c-routeMap">
      {
        initialRegion && markers && bounds &&
          <MapContainer
            bounds={bounds}
            center={[
              initialRegion.latitude,
              initialRegion.longitude
            ]}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              //url="https://{s}.base.maps.ls.hereapi.com/maptile/2.1/maptile/newest/normal.day.grey/{z}/{x}/{y}/512/png8?apiKey=NB4mqpiwnodCU6gbc6GdgSQihrpnjxY_nk-b5o2J9y4&ppi=320"
              url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png"
              subdomains='abc'
            />

          {
            markers.map((m, index) => {
              if(index === 0 || index === 3){
                return <DraggableMarker
                  key={`route-map-marker-${index}`}
                  draggable={false}
                  color={index === 0 ? 'primary' : 'secondary'}
                  position={[
                    m.latitude,
                    m.longitude
                  ]}
                />
              }
            })
          }

          {/* {
            shape && markers.length > 0 &&
              <Polyline
                pathOptions={{
                  color: '#333',
                  width: 4,
                  fillOpacity: .15
                }}
                positions={plotCurve(markers[0], markers[1]).map(coords => [
                  coords.latitude,
                  coords.longitude
                ])}
              />
          } */}

          {
            markers?.length > 0 ? <>
              <Polyline
                pathOptions={{
                  color: '#aaa',
                  width: 4,
                  fillOpacity: .15,
                  dashArray: '10, 10'
                }}
                positions={plotCurve(markers[0], markers[1]).map(coords => [
                  coords.latitude,
                  coords.longitude
                ])}
              />

              <Polyline
                pathOptions={{
                  color: '#333',
                  width: 4,
                  fillOpacity: .15
                }}
                positions={plotCurve(markers[1], markers[2]).map(coords => [
                  coords.latitude,
                  coords.longitude
                ])}
              />

              <Polyline
                pathOptions={{
                  color: '#aaa',
                  width: 4,
                  fillOpacity: .15,
                  dashArray: '10, 10'
                }}
                positions={plotCurve(markers[2], markers[3]).map(coords => [
                  coords.latitude,
                  coords.longitude
                ])}
              />
            </> : <></>
          }


          </MapContainer>
      }
    </div>
  )
}

export default RouteMap
